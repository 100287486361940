import moment from 'moment'
import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {useIntl} from 'react-intl'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  fetchAsyncDropdown,
  getLoginWarehouse,
  measurementLabel,
  numberFormat,
  numberFormatWithCode,
  retrieveFilter,
  saveFilter,
} from '../../../../_eaFruitsDms/helpers'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import {EaStatisticsWidget} from '../../../../_eaFruitsDms/partials/widgets'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import tenantConfiguration from '../../../../TenantVariables'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {AsyncPaginate} from 'react-select-async-paginate'
import {categoryStyle} from '../../../../_eaFruitsDms/helpers/components/categoryStyle'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import { filters } from '../../../../_eaFruitsDms/utils/FilterConstants'
import ResetButton from '../../../../_eaFruitsDms/layout/components/button/resetButton'

type Props = {
  className: string
}
const ProductSkuReports: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = [{
        title: "Reports"
    }]
    const baseUrl = apiBaseURL()
    const currentWarehouseId: any = localStorage.getItem('w_id')

    let orderFeedbackFiltered = retrieveFilter(filters.sa_product_wise_sales_report)
    let initialWarehouse : any = getLoginWarehouse().value !== null ? getLoginWarehouse() : ''
    let initialStartDate = moment().startOf('month').format('YYYY-MM-DD')
    let initialEndDate = moment().endOf('month').format('YYYY-MM-DD')
    let initialListDate =  { startDate: orderFeedbackFiltered?.startDate  || initialStartDate, endDate: orderFeedbackFiltered?.endDate || initialEndDate }
   
    const initialState = {
        warehouseValueReport: orderFeedbackFiltered?.warehouseValueReport || initialWarehouse,
        brands: orderFeedbackFiltered?.brands || '',
        categories: orderFeedbackFiltered?.categories || '',
        productGroups: orderFeedbackFiltered?.productGroups || '',
        startDate: orderFeedbackFiltered?.startDate || initialStartDate,
        endDate: orderFeedbackFiltered?.endDate || initialEndDate,
        search: orderFeedbackFiltered?.search || '',
        selected: orderFeedbackFiltered?.selected || '',
        currentOffset: orderFeedbackFiltered?.currentOffset || '',
        buttonDisable: true,
      }
      
    const [search, setSearch] = useState(orderFeedbackFiltered?.search || '')
    const [selected, setSelected] = useState<number>(orderFeedbackFiltered?.selected || 0)
    const [currentOffset, setCurrentOffset] = useState<number>(orderFeedbackFiltered?.currentOffset || 0)
    const [limit, setLimit] = useState(tenantConfiguration.limit)
    const [count, setCount] = useState<string>('1')
    const [warehouseValueReport, setWarehouseValueReport] = useState<any>(orderFeedbackFiltered?.warehouseValueReport || (getLoginWarehouse().value !== null ? getLoginWarehouse() : ''))
    const [productReport, setProductReport] = useState([])
    const [widget, setWidget] = useState({
        total_product_sku: '0',
        total_brands: '0',
        total_parent_categories: '0',
        total_product_groups: '0',
    })
    const [brandPieChart, setBrandPieChart] = useState({
        data: [],
        title: "",
        bottom: '30%'
    })
    const [productPieChart, setProductPieChart] = useState({
        data: [],
        title: "",
        bottom: '30%'
    })
    const [isReportLoading, setIsReportLoading] = useState(false)
    const [searchSelect, setSearchSelect] = useState('')
    const [categoryOffset, setCategoryOffset] = useState<any>('')
    const [categoryOption, setCategoryOption] = useState<any>([])
    const [brandOffset, setBrandOffset] = useState<any>('')
    const [brandOption, setBrandOption] = useState<any>([])
    const [groupOffSet, setGroupOffSet] = useState<any>('')
    const [groupOption, setGroupOption] = useState<any>([])
    const [apiDate, setApiDate] = useState<any>(initialListDate)
    const [isListLoading, setIsListLoading] = useState(true)
    const [offset, setOffset] = useState(0)
    const [list, setList] = useState([])
    const [dropDownSearch, setDropDownSearch] = useState('')
    const [brands, setBrands] = useState<any>(orderFeedbackFiltered?.brands || '')
    const [categories, setCategories] = useState<any>(orderFeedbackFiltered?.categories || '')
    const [productGroups, setProductGroups] = useState<any>(orderFeedbackFiltered?.productGroups || '')

    const debounceSearch = useDebounce(search, 500);
    const userType = localStorage.getItem('user_type') || ""
    const [filterStates, setFilterStates] = useState<any>(initialState)
    const [buttonDisable, setButtonDisable] = useState<boolean>(
      orderFeedbackFiltered?.buttonDisable || false
    )

    const checkUserType = () => {
        if (userType !== "") {
            if (userType === "SA" || userType === "TS") {
                return true
            } else {
                return false
            }
        }
    }

    // NOTE : Chart CODE
    // const fetchBrandPieChart = async () => {
    //     setIsReportLoading(true)

    //     const param = 'warehouse_id=' + currentWarehouseId
    //     const url = baseUrl + 'reports/product-sku-sales/brand?' + param
    //     const response = await getRequest(url, true)
    //     let brandChart: any = { ...brandPieChart }
    //     let mergeBrandChart: object[] = []

    //     if (response && response.results) {
    //         response.results.forEach((item: any) => {
    //             if (item.sales_amount !== 0) {
    //                 mergeBrandChart.push({ name: item.name, value: item.sales_amount })
    //             }
    //         })

    //         brandChart.data = mergeBrandChart
    //         setBrandPieChart(brandChart)
    //     }
    // }

    // const fetchProductPieChart = async () => {
    //     const param = 'warehouse_id=' + currentWarehouseId
    //     const url = baseUrl + 'reports/product-sku-sales?' + param
    //     const response = await getRequest(url, true)
    //     let productChart: any = { ...brandPieChart }
    //     let mergeProductChart: object[] = []

    //     if (response && response.results) {
    //         response.results.forEach((item: any) => {
    //             if (item.sales_amount !== 0) {
    //                 mergeProductChart.push({ name: item.name, value: item.sales_amount })
    //             }
    //         })

    //         productChart.data = mergeProductChart
    //         setProductPieChart(productChart)
    //     }
    //     setIsReportLoading(false)
    // }

    const fetchProductList = async () => {
        setIsListLoading(true)
        const filter = `&brand_id=${brands !== '' ? brands.value : ''}&category_id=${categories !== '' ? categories.value : ''}&group_id=${productGroups !== '' ? productGroups.value : ''}`
        let warehouseFilter = ''

        if (checkUserType() === true) {
            if (warehouseValueReport !== "" && warehouseValueReport.value) {
                warehouseFilter = `&warehouse_id=${warehouseValueReport.value}`
            } else {
                warehouseFilter = `&warehouse_id=`
            }
        }

        if (checkUserType() === false) {
            warehouseFilter = `&warehouse_id=${warehouseValueReport !== '' && warehouseValueReport.value !== '' ? warehouseValueReport.value : currentWarehouseId}`
        }

        const param = `limit=${limit}&offset=${currentOffset}&search=${debounceSearch}&delivery_date_duration_after=${apiDate.startDate}&delivery_date_duration_before=${apiDate.endDate}` + warehouseFilter + filter
        const url = baseUrl + 'reports/product-sku-sales?' + param

        const response = await getRequest(url, true)
        if (response && response.results) {
            setWidget(response.meta)
            setProductReport(response.results)
            setCount(response.count)
        }
        setIsListLoading(false)

        let updatedState: any = { ...filterStates }
        updatedState['selected'] = selected
        updatedState['currentOffset'] = currentOffset
        updatedState['buttonDisable'] = buttonDisable
        setFilterStates(updatedState)

        saveFilter(filters.sa_product_wise_sales_report, filterStates)
        
        if (
            warehouseValueReport.value == '' &&
            brands.value == '' &&  
            categories.value == '' &&  
            productGroups.value == '' &&  
            search == '' && 
            apiDate.startDate === initialStartDate &&
            apiDate.endDate === initialEndDate
        ) {
          setButtonDisable(false)
        }

    }

    const fetchCategory = async (search?: any) => {
        if (search != '') {
            setSearchSelect(search)
            setCategoryOffset('')
        }

        let options: any = []
        const categoriesResponse = await getRequest(
            `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffset}`,
            true
        )

        let hasMore: boolean = false

        if (categoriesResponse.next !== null) {
            const queryParams = new URLSearchParams(categoriesResponse.next)
            let newOffset: any = queryParams.get('offset')
            setCategoryOffset(newOffset)
            hasMore = true
        }

        if (categoryOption.length == 0) {
            options.push({
                label: 'All Categories',
                value: '',
            })
        }

        if (categoriesResponse.results.length > 0) {
            categoriesResponse.results.map((option: any) => {
                options.push({
                    label: option.name,
                    value: option.id,
                    color: '#6d6e6f',
                    bg: '#f7f7f7',
                    transform: 'uppercase',
                    boxSizing: 'borderBox',
                    weight: '500',
                    cursor: 'default',
                    size: '.75rem !important',
                })
                if (option.child_category.length > 0) {
                    option.child_category.map((child: any) => {
                        options.push({
                            label: child.name,
                            value: child.id,
                        })
                    })
                }
            })
        }

        if (categoryOption.length > 0) {
            setCategoryOption(categoryOption.concat(options))
        } else {
            setCategoryOption(options)
        }

        return {
            options: options,
            hasMore: hasMore,
        }
    }

    const fetchBrands = async (search?: any) => {
        return await fetchAsyncDropdown(
            brandOffset,
            setBrandOffset,
            brandOption,
            setBrandOption,
            `master/product/brand?ordering=name`,
            search,
            setSearchSelect,
            '',
            true,
            'All Brands'
        )
    }
    const fetchGroups = async (search?: any) => {
        return await fetchAsyncDropdown(
            groupOffSet,
            setGroupOffSet,
            groupOption,
            setGroupOption,
            `master/product/group?ordering=name`,
            search,
            setSearchSelect,
            '',
            true,
            'All Product Groups'
        )
    }

    const selectStyles = {
        menuList: (base: any) => ({
            ...base,
            maxHeight: 200,
        }),
    }

    // fetch chart Warehouse Dropdown
    const fetchWarehouse = async (search: any) => {
        return fetchAsyncDropdown(offset, setOffset, list, setList, `warehouse/list?${checkUserType() === true ? '' : `parent_warehouse__id=${currentWarehouseId}`}&ordering=name`, search, setDropDownSearch, "name", checkUserType() === true ? true : false, "All Warehouses")
    }

    // useEffect(() => {
    //     fetchWidget()
    //     fetchBrandPieChart()
    //     fetchProductPieChart()
    // }, [])

    useEffect(() => {
        // getFirstWh()
    }, [])

    useEffect(() => {
        fetchProductList()
    }, [debounceSearch, currentOffset, apiDate, categories, brands, productGroups, warehouseValueReport])

    const CustomDatePickerComponent = (props: any) => {
        const { onCallback, startDate, endDate, value } = props
        return (
            <>
                <DateRangePicker
                    onCallback={(start, end, label) => {
                        const callBakeDate = {
                            startDate: dateFormat(start._d).apiDate,
                            endDate: dateFormat(end._d).apiDate,
                        }
                        const callBakeDateDisplay = {
                            startDate: dateFormat(start._d).displayDate,
                            endDate: dateFormat(end._d).displayDate,
                        }
                        onCallback(callBakeDate, callBakeDateDisplay)
                    }}
                    initialSettings={{
                        startDate: moment(startDate).format('DD MMM YYYY'),
                        endDate: moment(endDate).format('DD MMM YYYY'),
                        autoUpdateInput: true,
                        alwaysShowCalendars: true,
                        cancelClass: 'btn-secondary',
                        drops: 'auto',
                        locale: {
                            format: 'DD MMM YYYY',
                        },
                        ranges: {
                            Today: [moment(), moment()],
                            Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [
                                moment().subtract(1, 'month').startOf('month'),
                                moment().subtract(1, 'month').endOf('month'),
                            ],
                        },
                    }}
                >
                    <input
                        type='text'
                        className='form-control calendar my-1 me-1 w-auto'
                        defaultValue={value}
                        disabled={isListLoading}
                    />
                </DateRangePicker>
            </>
        )
    }

    const handleChangeReset = () => {
        setSearch('')
        setBrands('')
        setWarehouseValueReport(initialWarehouse)
        setCategories('')
        setProductGroups('')
        setCurrentOffset(0)
        setSelected(0)
    
        let updatedDateState: any = { ...apiDate }
        updatedDateState['startDate'] = initialStartDate
        updatedDateState['endDate'] = initialEndDate
        setApiDate(updatedDateState)
    
        let updatedState: any = { ...filterStates }
        updatedState['warehouseValueReport'] = ''
        updatedState['brands'] = ''
        updatedState['categories'] = ''
        updatedState['productGroups'] = ''
        updatedState['search'] = ''
        updatedState['currentOffset'] = ''
        updatedState['selected'] = ''
        updatedState['startDate'] = ''
        updatedState['endDate'] = ''
        setFilterStates(updatedState)
        setButtonDisable(false)
      }

    return (
        <>
            <EATitle title='Product wise Sales -' />
            <PageTitle breadcrumbs={reportHeaderTitle} >{intl.formatMessage({ id: 'Product wise Sales' })}</PageTitle>
            {/* begin::Row */}
            <div className='row g-3 g-xl-5'>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='success'
                        title={widget.total_brands ? numberFormat(widget.total_brands).displayFormat : '0'}
                        path='/'
                        description='Total Brands'
                    />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='primary'
                        title={widget.total_parent_categories ? numberFormat(widget.total_parent_categories).displayFormat : '0'}
                        description='Total Parent Categories'
                        path='/'
                    />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='warning'
                        title={widget.total_product_groups ? numberFormat(widget.total_product_groups).displayFormat : '0'}
                        description='Total Product Groups'
                        path='/'
                    />
                </div>
                <div className='col-sm-6 col-md-6 col-lg col-xl'>
                    <EaStatisticsWidget
                        className='card-xl-stretch mb-2 mb-xl-5 rounded-2'
                        color='danger'
                        title={widget.total_product_sku ? numberFormat(widget.total_product_sku).displayFormat : '0'}
                        description='Total Product SKUs'
                        path='/'
                    />
                </div>
            </div>

            {/* <div className='row mt-3 mt-md-0'>
                <div className='col-12 mt-md-0'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                            <div><h3 className="card-title align-items-start flex-column my-0">
                                <span className="card-label fw-bold font-17">Products Overview</span>
                            </h3>
                                <p className="font-13 text-muted mb-0">Based on Sales Amount
                                </p>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="row">
                                {isReportLoading && <IsLoader />}
                                <>
                                    <div className="col-md-6">
                                        {brandPieChart.data.length === 0 ? <NoRecords /> :
                                            <EaChartPie item={brandPieChart} />}
                                    </div>
                                    <div className="col-md-6">
                                        {productPieChart.data.length === 0 ? <NoRecords /> :
                                            <EaChartPie item={productPieChart} />} </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className={`card ${className} mb-5`}>
                <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>

                    <h3 className='card-title align-items-start flex-column my-0'>
                        <span className='card-label fw-bold font-17'>Products Overview</span>
                    </h3>


                    <div className='card-toolbar d-flex g-3 my-0'>
                        <ul className='nav'>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary active me-1'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_1'
                                >
                                    Brands
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary me-1'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_2'
                                >
                                    Parent Categories
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_3'
                                >
                                    Product Groups
                                </a>
                            </li>
                        </ul>
                        <span className="v-seperator mx-3"></span>
                        <ul className='nav'>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary active me-1'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_1'
                                >
                                    Month
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a
                                    className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary me-1'
                                    data-bs-toggle='tab'
                                    href='#kt_table_widget_8_tab_2'
                                >
                                    Year
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-body">
                    Chart Area
                </div>
            </div > */}

            <div  >
                <div className='card'>
                    {
                        isListLoading && <IsLoader />
                    }
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar
                            onChange={(e: any) => {
                                setButtonDisable(true)
                                setFilterStates({ ...filterStates, ['search']: e.target.value })
                                setSearch(e.target.value)
                                setCurrentOffset(0)
                                setSelected(0)
                            }}
                            value={search} isDisable={isListLoading}
                        />
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                <CustomDatePickerComponent
                                    onCallback={(callBakeDate: any, callBakeDateDisplay: any) => {
                                        setButtonDisable(true)
                                        let updatedState: any = { ...filterStates }
                                        updatedState['startDate'] = callBakeDate.startDate
                                        updatedState['endDate'] = callBakeDate.endDate
                                        setFilterStates(updatedState)

                                        setApiDate({
                                            startDate: callBakeDate.startDate,
                                            endDate: callBakeDate.endDate
                                        })
                                        setCurrentOffset(0)
                                        setSelected(0)
                                    }}
                                    value={apiDate}
                                    startDate={apiDate.startDate}
                                    endDate={apiDate.endDate}
                                />
                                <AsyncPaginate
                                    loadOptions={fetchWarehouse}
                                    isSearchable
                                    placeholder="All Warehouses"
                                    className='react-select-container my-1 me-1 w-150px'
                                    classNamePrefix="react-select"
                                    name='select_team'
                                    onChange={(e) => {
                                        setButtonDisable(true)
                                        setFilterStates({ ...filterStates, ['warehouseValueReport']: e })
                                        setWarehouseValueReport(e)
                                        setCurrentOffset(0)
                                        setSelected(0)
                                    }}
                                    value={warehouseValueReport}
                                    isDisabled={isListLoading}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <AsyncPaginate
                                    loadOptions={fetchBrands}
                                    isSearchable
                                    styles={selectStyles}
                                    className='react-select-container my-1 me-1 w-120px'
                                    classNamePrefix='react-select'
                                    placeholder='All Brands'
                                    name='brand'
                                    value={brands}
                                    isDisabled={isListLoading}
                                    onChange={(e: any) => {
                                        setButtonDisable(true)
                                        setFilterStates({ ...filterStates, ['brands']: e })
                                        setBrands(e)
                                        setCurrentOffset(0)
                                        setSelected(0)
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        height: 200,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <AsyncPaginate
                                    loadOptions={fetchCategory}
                                    isSearchable
                                    className='react-select-container my-1 me-1 w-150px'
                                    classNamePrefix='react-select'
                                    styles={{
                                        ...categoryStyle,
                                        menuList: (base: any) => ({
                                            ...base,
                                            maxHeight: 200,
                                        }),
                                    }}
                                    placeholder='All Categories'
                                    name='category'
                                    value={categories}
                                    isDisabled={isListLoading}
                                    onChange={(e: any) => {
                                        setButtonDisable(true)
                                        setFilterStates({ ...filterStates, ['categories']: e })
                                        setCategories(e)
                                        setCurrentOffset(0)
                                        setSelected(0)
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <AsyncPaginate
                                    loadOptions={fetchGroups}
                                    isSearchable
                                    styles={selectStyles}
                                    className='react-select-container my-1 me-3 w-170px'
                                    classNamePrefix='react-select'
                                    placeholder='All Product Groups'
                                    value={productGroups}
                                    isDisabled={isListLoading}
                                    onChange={(e: any) => {
                                        setButtonDisable(true)
                                        setFilterStates({ ...filterStates, ['productGroups']: e })
                                        setProductGroups(e)
                                        setCurrentOffset(0)
                                        setSelected(0)
                                    }}
                                    name='group'
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0c78a4',
                                        },
                                    })}
                                />
                                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
                                {/* <label className="form-label mb-0">
                                    <button type="button" className="btn btn-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1 d-flex align-items-center">
                                        <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted ic" />
                                        Export</button>
                                </label> */}
                            </div>
                        </div>
                    </div>
                    <div className='card-body py-3'>
                        <div className='table-responsive'>
                            {
                                productReport.length === 0 ? <NoRecords /> :
                                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                                        {/* begin::Table head */}
                                        <thead>
                                            <tr className='text-muted fw-500'>
                                                <th className='min-w-150px'>Product SKU</th>
                                                <th className='min-w-140px'>Brand</th>
                                                <th className='min-w-120px'>Category</th>
                                                <th className='min-w-120px'>Product Group</th>
                                                <th className='min-w-110px'>Variant</th>
                                                {/* <th className='min-w-80px'>Size</th> */}
                                                <th className='min-w-100px'>Sales Qty.</th>
                                                <th className='min-w-80px py-2'>Sales Amt.</th>
                                            </tr>
                                        </thead>
                                        {/* end::Table head */}
                                        {/* begin::Table body */}
                                        <tbody>
                                            {
                                                productReport.length > 0 && productReport.map((item: any) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {
                                                                    item.product_sku_name && item.product_sku_name !== '' ? item.product_sku_name : '-'
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.brand_name && item.brand_name !== '' ? item.brand_name : '-'
                                                                }
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div className='text-muted font-13 mb-1 font-medium'>
                                                                        {
                                                                            item.parent_category_name && item.parent_category_name !== '' ? item.parent_category_name : '-'
                                                                        }
                                                                    </div>
                                                                    {
                                                                        item.category_name && item.category_name !== '' ? item.category_name : '-'
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.group_name && item.group_name !== '' ? item.group_name : '-'
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.variant_name && item.variant_name !== '' ? item.variant_name : '-'
                                                                }
                                                            </td>
                                                            {/* <td>
                                                                {
                                                                    item.size && item.size !== '' ? `${item.size} Kgs` : '-'
                                                                }
                                                            </td> */}
                                                            <td>
                                                                {
                                                                    item.sales_qty && item.sales_qty !== '' ? `${numberFormat(item.sales_qty).displayFormat} ${checkEmpty(measurementLabel(item.fix_size
                                                                      ? item.material_name
                                                                      : item.approx_weight > 0
                                                                        ? item.material_name
                                                                        : item.measurement_unit), "")}` : '-'
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.sales_amount && item.sales_amount !== '' ? numberFormatWithCode(item.sales_amount).displayFormat : '-'
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        {/* end::Table body */}
                                    </table>
                            }
                        </div>
                    </div>
                </div>
                <CustomPaginate
                    data={productReport}
                    selected={selected}
                    setSelected={setSelected}
                    setCurrentOffset={setCurrentOffset}
                    totalRecords={count}
                    limit={limit}
                    isDisable={isListLoading}
                    saveFilterName={filters.sa_product_wise_sales_report}
                    filterStates={filterStates}
                />
            </div >
        </>
    )
}

export default ProductSkuReports
