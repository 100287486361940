import clsx from 'clsx'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {Helmet} from 'react-helmet'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation} from 'react-router'
import {Props} from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  dateFormat,
  dateFormatWithoutTimeZoneCovert,
  dateIsToday,
  isEmpty,
  KTSVG,
  measurementLabel,
  numberFormat,
} from '../../../../_eaFruitsDms/helpers'
import {getRequest, patchRequest, postRequest} from '../../../../_eaFruitsDms/https/apiCall'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import {PageLink, PageTitle} from '../../../../_eaFruitsDms/layout/core'
import tenantConfiguration from '../../../../TenantVariables'
import {Modal} from 'react-bootstrap'
import ErrorHandler from '../../../../_eaFruitsDms/layout/components/errorHandler/errorHandler'

const fetchCreditNote = async (key?: any, search?: any) => {
  let options: any = [
    {value: 'select', label: 'Select'},
    {value: 'Option1', label: 'Option 1'},
    {value: 'Option2', label: 'Option 2'},
  ]
  let hasMore: boolean = false

  return {
    options: options,
    hasMore: hasMore,
  }
}

const userType = localStorage.getItem('user_type') || ''
const currentWarehouseId = userType.toLowerCase() === 'wm' ? localStorage.getItem('w_id') : ''
let tableRowIndex: number = 0

const ManagePreOrders: React.FC<Props> = ({className}: any) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const preOrders: Array<PageLink> = [
    {
      title: 'Orders',
      path: '/orders',
      isSeparator: false,
      isActive: false,
    },
  ]
  const initialOrderRow = {
    index: 0,
    sku_id: 0,
    product_id: '',
    product_price: 0,
    discounted_price: 0,
    product_packaging: '',
    quantity: 0,
    quantity_disabled: true,
    total_qty: 0,
    total: 0,
    is_not_valid: false,
  }
  const orderId = localStorage.getItem('oId')

  const [customers, setCustomers] = useState<any>([])
  const [customerOffSet, setCustomerOffSet] = useState<string>('')
  const [searchSelect, setSearchSelect] = useState<string>('')
  const [customer, setCustomer] = useState<any>('')
  const [customerOption, setCustomerOption] = useState<any>('')
  const [warehouse, setWarehouse] = useState<any>('')
  const [limit, setLimit] = useState(tenantConfiguration.limit)
  const [orderRows, setOrderRows] = useState<any>([initialOrderRow])
  const [selectedProducts, setSelectedProducts] = useState<any>([])
  const [deletedOrderRows, setDeletedOrderRows] = useState<any>([initialOrderRow])
  const [productSkuData, setProductSkuData] = useState<any>([])
  const [productOffSet, setProductOffSet] = useState<any>('')
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true)
  const [total, setTotal] = useState<any>(0)
  const [totalDiscont, setTotalDiscount] = useState<any>(0)
  const [creditNote, setCreditNote] = useState<any>(0)
  const [totalTax, setTotalTax] = useState<any>(0)
  const [date, setDate] = useState<any>(dateFormat(moment()))
  const [submitButtonText, setSubmitButtonText] = useState<any>('Submit')
  const [isDisplayLoader, setIsDisplayLoader] = useState<boolean>(false)
  const [order, setOrder] = useState<any>(null)
  const [productReset, setProductReset] = useState<any>(moment())
  const [showPreOrderModal, setShowPreOrderModal] = useState(false)
  const [ordersId, setOrdersId] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedSku, setSelectedSku] = useState<any>({})

  const location: any = useLocation()

  useEffect(() => {
    if (location?.state?.customer && location?.state?.warehouse && location?.state?.orderCustomer) {
      setWarehouse(location?.state?.warehouse ?? '')
      setCustomerOption(location?.state?.customer ?? '')
      setCustomer(location?.state?.orderCustomer ?? '')

      if (location?.state?.customer?.value) {
        checkPreOrderStatus(location?.state?.customer?.value ?? '', 'customer')
      }
    }
  }, [location])

  useEffect(() => {
    if (orderId != null) {
      fetchOrderDetails().then()
    }
  }, [])

  useEffect(() => {
    setCustomerOffSet('')
  }, [searchSelect])

  useEffect(() => {
    handleErrorMessageForHoreca()
  }, [customerOption, date])

  const handleErrorMessageForHoreca = () => {
    if (!isEmpty(customerOption)) {
      if (customerOption.category_having_contract) {
        if (customerOption.is_active_contract) {
          if (moment(date.apiDate).isAfter(customerOption.active_contract_end_date)) {
            setIsSubmitDisabled(true)
            setErrorMessage(
              'Sales for the selected date cannot be processed as the contract will expire before the selected date. Please review and renew the contract before proceeding with the sale.'
            )
          } else {
            setErrorMessage('')
          }
        } else {
          setIsSubmitDisabled(true)
          setErrorMessage(
            'You cannot sell to this customer as there is no active contract or the existing contract has expired.'
          )
        }
      } else {
        setErrorMessage('')
      }
    }
  }

  const fetchOrderDetails = async () => {
    setIsDisplayLoader(true)
    const orderDetails = await getRequest(
      `${tenantConfiguration.apiBaseUrl}preorder/pre_order/${orderId}/`,
      true
    )
    setIsDisplayLoader(false)
    if (orderDetails) {
      let updatedOrderDetails: any = []

      setCustomerOption({
        label: orderDetails.customer.business_name + ', ' + orderDetails.customer.street.name,
        value: orderDetails.customer.id,
      })

      setCustomer(orderDetails.customer)
      setProductReset(moment())
      setWarehouse(orderDetails.warehouse[0])
      setDate(dateFormat(orderDetails.pre_order_date))
      setProductReset(moment())
      setTotal(orderDetails.total_amount)
      setTotalDiscount(orderDetails.discount)

      const selectedProductList = [...selectedProducts]

      orderDetails.pre_order_sku.map((product: any, index: number) => {
        selectedProductList.push(product.product_sku_id.id)

        updatedOrderDetails.push({
          index: index,
          sku_id: product.id,
          product_id: {
            label: product.product_sku_id.name,
            value: product.product_sku_id.id,
          },
          product_price: product.product_price,
          discounted_price: product.discounted_price,
          product_packaging: product.product_sku_id.material,
          quantity: product.qty,
          quantity_disabled: false,
          total: product.total_price,
          total_qty: product.available_qty,
        })

        return updatedOrderDetails
      })

      setSelectedProducts(selectedProductList)
      setOrder(orderDetails)
      setOrderRows(updatedOrderDetails)
      setProductReset(moment())
    }
  }

  const fetchCustomers = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }

    const customerResponse = await getRequest(
      `${apiBaseURL()}customers/drop-down?ordering=business_name&warehouse_id=${currentWarehouseId}&search=${search}&limit=${limit}&offset=${customerOffSet}`,
      true
    )

    let options: any = []
    let optionData: any = []

    let hasMore: boolean = false

    if (customerResponse.next !== null) {
      const queryParams = new URLSearchParams(customerResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCustomerOffSet(newOffset)
      hasMore = true
    }

    if (customerResponse.results.length > 0) {
      customerResponse.results.map((option: any) => {
        options.push({
          label: option.business_name + ', ' + option.street,
          value: option.id,
          category_having_contract: option.category_having_contract,
          is_active_contract: option.is_active_contract,
          active_contract_end_date: option.active_contract_end_date,
        })
        optionData.push(option)
      })
    }

    if (customers.length > 0) {
      setCustomers(customers.concat(optionData))
    } else {
      setCustomers(optionData)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchProductSkus = async (search?: any) => {
    if (search !== '') {
      setSearchSelect(search)
    }

    let productAPIURL: string = `${apiBaseURL()}inventory/product-SKUs/?warehouse_id=${
      warehouse.id
    }&customer_id=${customerOption.value}&order_date=${
      date.apiDate
    }&ordering=name&search=${search}&limit=${limit}&offset=${productOffSet}&trade_only=true&zero_stock=True&only_set_priced=True&exclude_pre=True`

    if (order && order.brand) {
      productAPIURL += `&brand_id__id=${order.brand.id}`
    }

    const productSKUResponse = await getRequest(productAPIURL, true)

    let options: any = []
    let optionData: any = []

    let hasMore: boolean = false

    if (productSKUResponse.next !== null) {
      const queryParams = new URLSearchParams(productSKUResponse.next)
      let newOffset: any = queryParams.get('offset')
      setProductOffSet(newOffset)
      hasMore = true
    }

    if (productSKUResponse.results.length > 0) {
      const rows: any = [...orderRows]

      productSKUResponse.results.map((option: any) => {
        const product = rows.filter((row: any) => option.id === row['product_id'].value)

        if (product.length === 0) {
          optionData.push(option)
          options.push({
            label: option.name,
            value: option.id,
          })
        }
      })
    }

    if (productSkuData.length > 0) {
      setProductSkuData(productSkuData.concat(optionData))
    } else {
      setProductSkuData(optionData)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const handleChange = (e: any, name?: string) => {
    const inputName: string = e.target ? e.target.name : name
    const inputValue: any = e.target ? e.target.value : e

    switch (inputName) {
      case 'customer':
        setCustomerOption(e)
        checkPreOrderStatus(e.value, 'customer')

        let filteredCustomer = customers.filter((customer: any) => customer.id == inputValue.value)
        if (filteredCustomer) {
          filteredCustomer = filteredCustomer[0]
          setCustomer(filteredCustomer)
          setProductReset(moment())
          setIsSubmitDisabled(true)

          if (filteredCustomer.warehouse_id) {
            setProductReset(moment())
            setOrderRows([initialOrderRow])
            setProductOffSet('')
            setProductSkuData([])
            setWarehouse({
              id: filteredCustomer.warehouse_id,
              name: filteredCustomer.warehouse_name,
            })
          } else {
            setWarehouse('')
          }
        }
        setTotal(0)
        setTotalTax(0)
    }
  }

  // Add New Table Row
  const addNewRow = () => {
    const updatedRows: any = [...orderRows]

    let valid: boolean = true

    if (updatedRows[tableRowIndex]) {
      if (updatedRows[tableRowIndex].product_id === '') {
        valid = false
        updatedRows[tableRowIndex].validation_for_product = 'is-invalid'
      }

      if (
        !updatedRows[tableRowIndex].quantity_disabled &&
        updatedRows[tableRowIndex].quantity <= 0
      ) {
        valid = false
        updatedRows[tableRowIndex].validation_for_quantity = 'is-invalid'
      }

      if (dateIsToday(date.apiDate)) {
        if (updatedRows[tableRowIndex].quantity > updatedRows[tableRowIndex].total_qty) {
          valid = false
          updatedRows[tableRowIndex].validation_for_quantity = 'is-invalid'
          updatedRows[tableRowIndex].validation_for_stock = 'is-invalid'
          alert(
            `There is not enough stock for ${updatedRows[tableRowIndex].product_id.label}. Available stock is only ${updatedRows[tableRowIndex].total_qty} ${updatedRows[tableRowIndex].product_packaging}`
          )
        }
      }
    }

    if (valid) {
      tableRowIndex++
      setProductOffSet('')

      initialOrderRow.index = tableRowIndex
      updatedRows.push(initialOrderRow)
      setIsSubmitDisabled(true)
      setOrderRows(updatedRows)
      setProductReset(moment())
      setCustomerOffSet('')
    }
  }

  // Remove Table row if rows are count is more than 1
  const deleteRow = (index: number): any => {
    if (orderRows.length > 1) {
      setCustomerOffSet('')
      let indexToRemove = orderRows.findIndex((x: any) => x.index == index)

      const deletedRows: any = [...deletedOrderRows]

      let updatedRows: any = []

      if (indexToRemove > -1) {
        let totalPrice = 0
        let discountTotal = 0

        orderRows.forEach((row: any, index: any) => {
          if (index !== indexToRemove) {
            if (index > indexToRemove) {
              row.index = row.index - 1
            }
            if (row.quantity > 0) {
              totalPrice += row.quantity * row.product_price
              discountTotal += (row.product_price - row.discounted_price) * row.quantity
            }
            updatedRows.push(row)
          } else {
            deletedRows.push(row)
          }
        })

        setTotalDiscount(discountTotal)
        setTotal(totalPrice)
        setOrderRows(updatedRows)
        setProductReset(moment())
        setDeletedOrderRows(deletedRows)
        tableRowIndex--
      }

      if (orderRows.length > 0) {
        const isDateTodayOrTomorrow = dateIsToday(date.apiDate)

        let updatedOrderRows: any = [...updatedRows]
        let valid: boolean = true
        updatedOrderRows.map((orderRow: any, index: number) => {
          if (orderRow.product_id != '') {
            if (isDateTodayOrTomorrow) {
              if (orderRow.quantity == 0) {
                valid = false
                setIsSubmitDisabled(true)
                updatedOrderRows[index].validation_for_quantity = 'is-invalid'
              } else {
                if (orderRow.total_qty < orderRow.quantity) {
                  setIsSubmitDisabled(true)
                  valid = false
                  updatedOrderRows[index].validation_for_quantity = 'is-invalid'
                  updatedOrderRows[index].validation_for_stock = 'is-invalid'
                }
              }
            }
          } else {
            valid = false
          }
        })

        setIsSubmitDisabled(true)

        if (valid) {
          setIsSubmitDisabled(false)
        }
      }
    } else {
      alert(
        `You can not remove this row because you must add at least one item to ${
          orderId ? 'update' : 'place'
        } the order.!`
      )
    }
  }

  const checkPreOrderStatus = async (e: any, type: any) => {
    let customerId = type === 'customer' ? (e ? e : customerOption.value) : customerOption.value
    let selectedDate = type === 'date' ? (e ? e : date.apiDate) : date.apiDate

    if (!isEmpty(customerId)) {
      const url = `${apiBaseURL()}preorder/previous-pre-orders-status/list?customer_id=${customerId}&order_date=${selectedDate}`
      const response = await getRequest(url, true)

      if (response && response.result) {
        if (response.is_pre_order && response.result.length > 0) {
          setShowPreOrderModal(true)

          setOrdersId(response.result)
        }
      }
    }
  }

  const handleBlurRow = () => {
    const updatedOrderRow: any = [...orderRows]
    if (updatedOrderRow.length > 0) {
      setIsSubmitDisabled(false)

      updatedOrderRow.map((order: any, index: number) => {
        if (dateIsToday(date.apiDate)) {
          if (order.quantity > 0 && order.quantity <= updatedOrderRow[index].total_qty) {
            updatedOrderRow[index].validation_for_quantity = ''
            updatedOrderRow[index].validation_for_stock = ''
          } else {
            setIsSubmitDisabled(true)
            updatedOrderRow[index].validation_for_quantity = 'is-invalid'
            if (order.quantity > 0) {
              updatedOrderRow[index].validation_for_stock = 'is-invalid'
            }
          }
        } else {
          if ([0, ''].includes(order.quantity)) {
            setIsSubmitDisabled(true)
            updatedOrderRow[index].validation_for_quantity = 'is-invalid'
          }
        }
      })
      setProductReset(moment())
      setOrderRows(updatedOrderRow)
    }
  }

  const handleChangeRow = (e: any, index: any, name?: string) => {
    const inputName: string = e.target ? e.target.name : name
    const inputValue: any = e.target ? e.target.value : e.value
    const updatedOrderRow: any = [...orderRows]

    let product: any = null

    switch (inputName) {
      case 'product':
        updatedOrderRow[index].product_id = e
        product = productSkuData.filter((productSku: any) => productSku.id === inputValue)
        setProductOffSet('')
        updatedOrderRow[index]['validation_for_product'] = ''
        if (product.length > 0) {
          product = product[0]

          setSelectedSku(product)

          const updatedSelectedProducts = [...selectedProducts]

          updatedSelectedProducts.push(product.id)

          setSelectedProducts(updatedSelectedProducts)
          updatedOrderRow[index].sku = product.id
          updatedOrderRow[index].total_qty = product.total_qty
          updatedOrderRow[index].product_price = product.product_price
          updatedOrderRow[index].discounted_price = product.discounted_price
          updatedOrderRow[index].quantity = 0
          updatedOrderRow[index].total = 0
          updatedOrderRow[index].quantity_disabled = false

          updatedOrderRow[index]['product_packaging'] = measurementLabel(
            product.fix_size
              ? product.material.name
              : product.variants && product.variants.approx_weight > 0
              ? product.material.name
              : product.measurement_unit
          )

          recalculateTotal()
        }
        break
      case 'quantity':
        if (inputValue === 0 || inputValue === '') {
          setIsSubmitDisabled(true)
        } else {
          setIsSubmitDisabled(false)
          updatedOrderRow[index].validation_for_quantity = ''
          updatedOrderRow[index].validation_for_stock = ''
        }

        if (dateIsToday(date.apiDate)) {
          if (inputValue <= updatedOrderRow[index].total_qty) {
            updatedOrderRow[index].validation_for_quantity = ''
            updatedOrderRow[index].validation_for_stock = ''
            setIsSubmitDisabled(false)
          } else {
            setIsSubmitDisabled(true)
            updatedOrderRow[index].validation_for_quantity = 'is-invalid'
            updatedOrderRow[index].validation_for_stock = 'is-invalid'
          }
        }
        updatedOrderRow[index].quantity = inputValue
        let selectedProductSku = productSkuData.filter(
          (option: any) => option.id == updatedOrderRow[index].product_id.value
        )
        selectedProductSku = selectedProductSku[0]

        let price = updatedOrderRow[index].discounted_price
          ? updatedOrderRow[index].discounted_price
          : updatedOrderRow[index].product_price

        if (
          !customer.category_having_contract &&
          selectedProductSku &&
          selectedProductSku.is_qty_set
        ) {
          if (inputValue >= selectedProductSku.product_setting_qty) {
            if (selectedProductSku.price && selectedProductSku.price.length > 0) {
              updatedOrderRow[index].product_price = selectedProductSku.price[0].wholesale_price
              if (
                isEmpty(selectedProductSku.wholesaler_discounted_price) ||
                selectedProductSku.wholesaler_discounted_price == 0
              ) {
                price = selectedProductSku.price[0].wholesale_price
              } else {
                price = selectedProductSku.wholesaler_discounted_price
                updatedOrderRow[index].discounted_price =
                  selectedProductSku.wholesaler_discounted_price
              }
            }
          } else {
            if (selectedProductSku.price && selectedProductSku.price.length > 0) {
              price =
                isEmpty(selectedProductSku.discounted_price) ||
                selectedProductSku.discounted_price == 0
                  ? selectedProductSku.price[0].retail_price
                  : selectedProductSku.discounted_price
              updatedOrderRow[index].discounted_price = selectedProductSku.discounted_price
              updatedOrderRow[index].product_price = selectedProductSku.price[0].retail_price
            }
          }
        }

        const productTotalPrice = price * inputValue

        updatedOrderRow[index].total = productTotalPrice

        recalculateTotal()
        break
    }
    setProductReset(moment())
    setOrderRows(updatedOrderRow)
  }

  const recalculateTotal = () => {
    let totalPrice = 0
    let discountTotal = 0

    const updatedOrderRow: any = [...orderRows]

    updatedOrderRow.map((row: any) => {
      if (row.quantity > 0) {
        totalPrice += row.quantity * row.product_price
      }

      if (row.discounted_price) {
        discountTotal += (row.product_price - row.discounted_price) * row.quantity
      }
      return row
    })
    setTotalDiscount(discountTotal)
    setTotal(totalPrice)
  }

  const updatePreOrder = async () => {
    let valid = true

    orderRows.map((i: any) => {
      if (i.is_not_valid) {
        valid = false
      }
    })

    if (valid) {
      let orderData: any = {
        pre_order_sku: [],
        pre_order_date: date.apiDate,
      }

      setSubmitButtonText('Please Wait...')

      orderRows.map((orderRow: any, index: number) => {
        orderData.pre_order_sku.push({
          qty: orderRow.quantity,
          product_sku_id: orderRow.product_id.value,
        })

        if (orderRow.sku_id > 0) {
          orderData.pre_order_sku[index]['id'] = orderRow.sku_id
        }

        return orderData
      })

      if (deletedOrderRows.length > 0) {
        deletedOrderRows.map((orderRow: any, index: number) => {
          if (orderRow.product_id) {
            orderData.pre_order_sku.push({
              qty: 0,
              product_sku_id: orderRow.product_id.value,
            })

            if (orderRow.sku_id > 0) {
              orderData.pre_order_sku[index]['id'] = orderRow.sku_id
            }
          }
          return orderData
        })
      }

      await patchRequest(`${apiBaseURL()}preorder/pre_order/update/${orderId}/`, orderData, true)

      setSubmitButtonText('Submit')

      navigate('/orders', {
        state: {
          customerName: customer.business_name + ', ' + customer.street.name,
          type: 'edit',
          date: date,
          success: true,
        },
      })
    }
  }

  const generatePreOrder = async () => {
    let valid = true

    orderRows.map((i: any) => {
      if (i.is_not_valid) {
        valid = false
      }
    })

    if (valid) {
      let createBasketData: any = {
        basket: [],
        pre_order_date: date.apiDate,
        customer: customer.id,
        pre_order_generated: false,
      }
      setSubmitButtonText('Please Wait...')
      const response = await postRequest(
        `${apiBaseURL()}preorder/basket/create`,
        createBasketData,
        true
      )

      if (response.data && response.data.id > 0) {
        let isValidEntries: boolean = true
        let updatedRows: any = [...orderRows]

        orderRows.map((orderRow: any, index: number) => {
          if (orderRow.quantity === 0) {
            isValidEntries = false
            updatedRows[index].validation_for_qty = 'is-invalid'
          }
          if (orderRow.product_id === 0 || orderRow.product_id === '') {
            isValidEntries = false
            updatedRows[index].validation_for_product = 'is-invalid'
          }
          createBasketData.basket.push({
            qty: orderRow.quantity,
            product_sku: orderRow.product_id.value,
          })
        })

        if (isValidEntries) {
          createBasketData.pre_order_generated = true

          await patchRequest(
            `${apiBaseURL()}preorder/basket/update/${response.data.id}/`,
            createBasketData,
            true
          )

          setSubmitButtonText('Submit')

          navigate('/orders', {
            state: {
              customerName: customer.business_name + ', ' + customer.street,
              type: 'add',
              date: date,
              warehouse: {
                label: warehouse.name,
                value: warehouse.id,
              },
              success: true,
            },
          })
        } else {
          setIsSubmitDisabled(true)
          setProductReset(moment())
          setOrderRows(updatedRows)
        }
      }
    }
  }

  const handleDateChange = async (date: any) => {
    const selectedDate: any = dateFormatWithoutTimeZoneCovert(date)
    setProductReset(moment())
    setDate(selectedDate)

    const isDateTodayOrTomorrow = dateIsToday(selectedDate.apiDate)

    if (customer) {
      if (orderRows.length > 0 && selectedProducts.length > 0) {
        const preOrderDetailResponse: any = await getRequest(
          `${apiBaseURL()}preorder/pre_order/product_details?product_ids=${selectedProducts.toString()}&customer_id=${
            customer.id
          }&order_date=${selectedDate.apiDate}`,
          true
        )

        let updatedOrderRows: any = [...orderRows]
        setIsSubmitDisabled(false)
        updatedOrderRows.map((orderRow: any, index: number) => {
          const productObj = preOrderDetailResponse.find(
            (obj: any) => obj.id === orderRow.product_id.value
          )

          if (productObj) {
            orderRow.total_qty = productObj.total_units
            orderRow.discounted_price = productObj.discounted_price
            orderRow.product_price = productObj.product_price
            orderRow.is_not_valid = productObj.is_not_valid
            orderRow.total =
              (productObj.discounted_price > 0
                ? productObj.discounted_price
                : productObj.product_price) * orderRow.quantity
          }

          if (isDateTodayOrTomorrow) {
            if (orderRow.quantity === 0) {
              setIsSubmitDisabled(true)
              updatedOrderRows[index].validation_for_quantity = 'is-invalid'
            } else {
              if (orderRow.total_qty < orderRow.quantity) {
                setIsSubmitDisabled(true)
                updatedOrderRows[index].validation_for_quantity = 'is-invalid'
                updatedOrderRows[index].validation_for_stock = 'is-invalid'
              }
            }
          } else {
            updatedOrderRows[index].validation_for_quantity = ''
            updatedOrderRows[index].validation_for_stock = ''
          }
        })
        setProductReset(moment())
        setOrderRows(updatedOrderRows)

        recalculateTotal()
      }
    }

    checkPreOrderStatus(selectedDate.apiDate, 'date')
  }

  const CustomDateRangePicker = () => {
    return (
      <>
        <DateRangePicker
          initialSettings={{
            cancelClass: 'btn-secondary',
            drops: 'auto',
            minDate: moment(),
            singleDatePicker: true,
            locale: {
              format: tenantConfiguration.baseDateFormat,
            },
          }}
          onCallback={(start, end, label) => {
            handleDateChange(start._d)
          }}
          onCancel={(event: any, picker: any) => {
            setProductReset(moment())
            setDate((preValue: any) => ({
              ...preValue,
              startDateView: 'Select',
            }))
          }}
        >
          <input type='text' className='form-control calendar' value={date.displayDate} />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <Helmet title={orderId != null ? 'Edit Order' : 'Add Order'} />
      <PageTitle breadcrumbs={preOrders}>
        {intl.formatMessage({id: orderId != null ? 'Edit Order' : 'Add Order'})}
      </PageTitle>
      {isDisplayLoader && <IsLoader />}
      {order != null && (
        <>
          {/* just for edit mode */}
          <div className='card mb-4 mb-xl-5'>
            <div className='card-header border-0 px-7 pt-5 pb-0'>
              <div className='d-flex flex-column mb-2 mb-sm-0'>
                <div className='d-flex align-items-center mb-1'>
                  <span className='text-dark font-20 fw-bold me-1'>
                    Order #{order ? order.pre_order_brand_group_id : '-'}
                  </span>
                  {order && order.status === 'Scheduled' && (
                    <span className='py-1 ms-4 btn btn-sm bg-light-primary'>{order.status}</span>
                  )}
                  {order && order.status === 'Delivered' && (
                    <span className='py-1 ms-4 btn btn-sm bg-light'>{order.status}</span>
                  )}
                  {order && order.status === 'Pending' && (
                    <span className='py-1 ms-4 btn btn-sm bg-light-success'>{order.status}</span>
                  )}
                </div>
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='row'>
                <div className='col-lg-9 col-xl-9 col-xxl-8'>
                  <div className='row'>
                    <div className='col-sm-6 col-xl-4 mb-6'>
                      <label className='font-14 text-muted mb-2'>Order Created Date</label>
                      <div>
                        <span className='font-14 text-dark'>
                          {order ? dateFormat(order.created_date).displayDate : '-'}
                        </span>
                      </div>
                    </div>
                    <div className='col-sm-6 col-xl-4 mb-6'>
                      <label className='font-14 text-muted mb-2'>Delivery Date</label>
                      <div>
                        <span className='font-14 text-dark'>
                          {order ? dateFormat(order.pre_order_date).displayDate : '-'}
                        </span>
                      </div>
                    </div>
                    <div className='col-sm-6 col-xl-4 mb-6'>
                      <label className='font-14 text-muted mb-2'>Created By</label>
                      <div>
                        <span className='font-14 text-dark'>
                          {order
                            ? order.created_by.first_name + ' ' + order.created_by.last_name
                            : '-'}
                        </span>
                      </div>
                    </div>
                    <div className='col-sm-6 col-xl-4 mb-6'>
                      <label className='font-14 text-muted mb-2'>Customer Name</label>
                      <div>
                        <span className='font-14 text-dark'>
                          {order
                            ? order.customer.business_name + ', ' + order.customer.street.name
                            : '-'}
                        </span>
                      </div>
                    </div>
                    <div className='col-sm-6 col-xl-4 mb-6'>
                      <label className='font-14 text-muted mb-2'>Warehouse Name</label>
                      <div>
                        <span className='font-14 text-dark'>
                          {order ? order.warehouse[0].name : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end */}
        </>
      )}

      <div className={`card ${className}`}>
        {/* {displayLoader && <IsLoader />} */}
        {/* begin::Header */}
        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
          {/* begin::Title */}
          <h3 className='card-title align-items-start flex-column my-0'>
            <span className='card-label fw-bold font-17'>
              {orderId != null ? 'Edit Order' : 'Add Order'}
            </span>
          </h3>
          {/* end::Title */}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body pt-3 pb-3'>
          <div className='row'>
            <div className='col-md-3'>
              <label className='form-label'>
                Customer name<span className='text-danger'>*</span>
              </label>
              <AsyncPaginate
                loadOptions={fetchCustomers}
                placeholder='Select'
                isDisabled={orderId !== null}
                isSearchable
                className='react-select-container w-100'
                classNamePrefix='react-select'
                menuPosition='fixed'
                name='product'
                onChange={(e: any) => handleChange(e, 'customer')}
                value={customerOption}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0c78a4',
                  },
                })}
              />
            </div>
            <div className='col-md-3'>
              <label className='form-label'>Mapped Warehouse</label>
              <input
                type='text'
                className='form-control'
                name='mappedwarehouse'
                defaultValue='Warehouse Name'
                value={warehouse ? warehouse.name : '-'}
                disabled
              />
            </div>
            <div className='col-md-3'>
              <label className='form-label'>Order Delivery Date</label>
              <CustomDateRangePicker />
            </div>
          </div>
          <div className='my-4'>
            <ErrorHandler errorMessage={errorMessage} />
          </div>
          <hr className='mt-4 opacity-100 bg-light' />
          {/* begin::Table container */}
          {customer && isEmpty(errorMessage) && (
            <div className='row'>
              <div className='col-xl-10 col-10'>
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-gray-100 align-top gs-0 gy-2 mb-0'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='text-muted fw-500'>
                        <th className='min-w-200px'>
                          Product SKU<span className='text-danger'>*</span>
                        </th>
                        <th className='min-w-120px w-200px'>Price/Unit</th>
                        <th className='min-w-190px w-190px'>
                          Quantity<span className='text-danger'>*</span>
                        </th>
                        <th className='w-120px min-w-130px'>Total</th>
                        <th></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {orderRows.map((orderRow: any, index: number) => {
                        return (
                          <>
                            <tr>
                              <td>
                                <AsyncPaginate
                                  key={productReset}
                                  loadOptions={fetchProductSkus}
                                  isSearchable
                                  className={clsx(
                                    `react-select-container w-100 ${orderRow.validation_for_product}`
                                  )}
                                  classNamePrefix='react-select'
                                  menuPosition='fixed'
                                  placeholder='Select'
                                  onChange={(e: any) => handleChangeRow(e, index, 'product')}
                                  name='product'
                                  value={orderRows[index].product_id}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary: '#0c78a4',
                                    },
                                  })}
                                />
                              </td>
                              <td>
                                <div
                                  className={clsx(
                                    `input-group disabled ${
                                      orderRow.discounted_price > 0 &&
                                      'text-decoration-line-through'
                                    }`
                                  )}
                                >
                                  <span className='input-group-text bg-light2 border-end-0 px-3 ms-0 w-100'>
                                    {tenantConfiguration.currencyCode}{' '}
                                    {orderRow.product_price &&
                                      numberFormat(orderRow.product_price).displayFormat +
                                        '/' +
                                        orderRow.product_packaging}
                                  </span>
                                </div>
                                {orderRow.discounted_price > 0 && (
                                  <div className='text-primary font-13 mt-1'>
                                    Offer Price {tenantConfiguration.currencyCode}{' '}
                                    {orderRow.discounted_price}/{orderRow.product_packaging}{' '}
                                  </div>
                                )}
                              </td>
                              <td>
                                <div
                                  className='input-group'
                                  style={{
                                    borderColor:
                                      orderRow.validation_for_quantity &&
                                      orderRow.validation_for_quantity != ''
                                        ? 'Red'
                                        : '',
                                  }}
                                >
                                  <input
                                    type='text'
                                    placeholder='0'
                                    name='quantity'
                                    disabled={orderRow.quantity_disabled}
                                    onChange={(e: any) => handleChangeRow(e, index)}
                                    onBlur={handleBlurRow}
                                    value={orderRow.quantity > 0 ? orderRow.quantity : ''}
                                    className={`form-control ${orderRow.validation_for_quantity}`}
                                  />
                                  <span className='input-group-text bg-light2 border-end-0 px-3 border-start border-light ms-0 w-90px'>
                                    {orderRow.product_packaging}
                                  </span>
                                </div>
                                {orderRow.validation_for_stock &&
                                  orderRow.validation_for_stock != '' && (
                                    <div className='text-danger font-13 mt-1'>
                                      Available Stock {orderRow.total_qty}{' '}
                                      {orderRow.product_packaging}
                                    </div>
                                  )}
                              </td>
                              <td>
                                <div className='input-group min-w-130px disabled'>
                                  <span className='input-group-text bg-light2 border-end-0 px-3 ms-0 w-50px disabled'>
                                    {tenantConfiguration.currencyCode}
                                  </span>
                                  <input
                                    type='text'
                                    className='form-control'
                                    name='price'
                                    placeholder='0'
                                    value={numberFormat(orderRow.total).displayFormat}
                                    disabled
                                  />
                                </div>
                              </td>
                              <td>
                                <button
                                  type='button'
                                  className='position-relative btn ms-auto p-0'
                                  onClick={() => deleteRow(index)}
                                >
                                  <span className='svg-icon-dark'>
                                    <KTSVG
                                      path='/media/icons/duotune/abstract/abs012.svg'
                                      className='ic me-0 mt-1'
                                    />
                                  </span>
                                </button>
                              </td>
                            </tr>
                            <div className='mb-3'>
                              {orderRow?.is_not_valid && (
                                <ErrorHandler errorMessage={'SKU price does not found'} />
                              )}
                            </div>
                          </>
                        )
                      })}
                      <button
                        type='button'
                        className='position-relative btn ms-auto p-0'
                        onClick={addNewRow}
                      >
                        <span className='svg-icon-dark'>
                          <KTSVG
                            path='/media/icons/duotune/abstract/abs011.svg'
                            className='ic me-0 mt-1'
                          />
                        </span>
                      </button>
                      {/* append action row */}

                      <tr>
                        <td colSpan={5}>
                          <hr className='opacity-100 bg-light' />
                        </td>
                      </tr>
                      <tr>
                        {/* <td>
                          <div className='card-body py-0 px-0'>
                            <div className='row align-items-center'>
                              <div className='col-12'>
                                <label htmlFor='' className='form-label'>
                                  Redeem Credit Note
                                </label>
                              </div>
                              <div className='col'>
                                <AsyncPaginate
                                  loadOptions={fetchCreditNote}
                                  isSearchable
                                  isDisabled
                                  className='react-select-container w-100'
                                  menuPosition='fixed'
                                  classNamePrefix='react-select'
                                  name='product'
                                  placeholder='Select'
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary: '#0c78a4',
                                    },
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </td> */}
                        <td colSpan={3}>
                          <table className='table'>
                            <tr>
                              <td className='text-end min-h-20px h-20px'>
                                <span className='invisible'>hiddentext</span>
                              </td>
                              <td className='text-end min-h-20px h-20px' colSpan={2}>
                                Total Items
                              </td>
                              <td className='text-end min-h-20px h-20px'>
                                {orderRows.length} Items
                              </td>
                            </tr>
                            <tr>
                              <td className='text-end min-h-20px h-20px'>
                                <span className='invisible'>hiddentext</span>
                              </td>
                              <td className='text-end min-h-20px h-20px' colSpan={2}>
                                Total Amount (Without Discount)
                              </td>
                              <td className='text-end min-h-20px h-20px'>
                                {tenantConfiguration.currencyCode}{' '}
                                {numberFormat(total).displayFormat}
                              </td>
                              <td className='text-end min-h-20px h-20px'></td>
                            </tr>
                            <tr>
                              <td className='text-end min-h-20px h-20px'>
                                <span className='invisible'>hiddentext</span>
                              </td>
                              <td className='text-end min-h-20px h-20px' colSpan={2}>
                                Total Discount
                              </td>
                              <td className='text-end min-h-20px h-20px'>
                                - {tenantConfiguration.currencyCode}{' '}
                                {numberFormat(totalDiscont).displayFormat}
                              </td>
                              <td className='text-end min-h-20px h-20px'></td>
                            </tr>
                            {/* <tr>
                              <td className='text-end min-h-20px h-20px'>
                                <span className='invisible'>hiddentext</span>
                              </td>
                              <td className='text-end min-h-20px h-20px' colSpan={2}>
                                Credit Note
                              </td>
                              <td className='text-end min-h-20px h-20px'>
                                - {tenantConfiguration.currencyCode} {creditNote}
                              </td>
                              <td className='text-end min-h-20px h-20px'></td>
                            </tr> */}
                            {/* <tr>
                              <td className='text-end min-h-20px h-20px'>
                                <span className='invisible'>hiddentext</span>
                              </td>
                              <td className='text-end min-h-20px h-20px' colSpan={2}>
                                Taxes
                              </td>
                              <td className='text-end min-h-20px h-20px'>
                                {tenantConfiguration.currencyCode} {totalTax}
                              </td>
                              <td className='text-end min-h-20px h-20px'></td>
                            </tr> */}
                            <tr>
                              <td className='text-end min-h-20px h-20px'>
                                <span className='invisible'>hiddentext</span>
                              </td>
                              <td className='text-end min-h-20px h-20px' colSpan={2}>
                                <span className='fw-500 p-0'>Total Payable</span>
                              </td>
                              <td className='text-end min-h-20px h-20px'>
                                {/*TODO: need to change calculation when tax and discounted price */}
                                <span className='fw-500 p-0'>
                                  {tenantConfiguration.currencyCode}{' '}
                                  {numberFormat(total + totalTax - totalDiscont).displayFormat}
                                </span>
                              </td>
                              <td className='text-end min-h-20px h-20px'></td>
                            </tr>
                          </table>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
            </div>
          )}
        </div>

        {/* begin::Body */}
        {isEmpty(errorMessage) && (
          <div className='card-footer py-2 border-top text-center justify-content-center'>
            <button
              type='button'
              className='btn btn-secondary me-3'
              disabled={submitButtonText !== 'Submit'}
              onClick={() => {
                localStorage.removeItem('oId')
                navigate('/orders')
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary'
              disabled={isSubmitDisabled || submitButtonText !== 'Submit'}
              onClick={() => {
                if (orderId !== null) {
                  updatePreOrder().then()
                } else {
                  generatePreOrder().then()
                }
              }}
            >
              {submitButtonText}
            </button>
          </div>
        )}
      </div>

      <>
        <Modal
          show={showPreOrderModal}
          onHide={() => {
            setShowPreOrderModal(false)
          }}
          centered
          backdrop='static'
          dialogClassName='h-auto mw-570px'
          className='call-notes-modal'
        >
          <Modal.Header>
            <Modal.Title>
              <h2 className='modal-title fw-bold'>Pre Order</h2>
            </Modal.Title>
            <div
              className='btn p-1 mh-24 btn-active-light-primary ms-2'
              onClick={() => {
                setShowPreOrderModal(false)
              }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className='p-5'>
              A pre-order has already been created for this customer. Would you like to proceed with
              creating another one, or review the existing order{' '}
              {ordersId.length > 0 &&
                ordersId.map((item: any, index) => {
                  return (
                    <>
                      <span>
                        {index ? ',' : ''} {`#${item.pre_order_brand_group_id}`}{' '}
                      </span>
                    </>
                  )
                })}
              ?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-center'>
              <button
                className='btn btn-secondary me-3'
                onClick={() => {
                  setShowPreOrderModal(false)
                  navigate('/orders')
                }}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary'
                onClick={() => {
                  setShowPreOrderModal(false)
                }}
              >
                Continue
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}

export default ManagePreOrders
