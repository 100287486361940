import {useEffect, useState} from 'react'
import {Link, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import {
  KTSVG,
  apiBaseURL,
  checkUserType,
  dateTimeFormat,
  isEmpty,
} from '../../../../_eaFruitsDms/helpers'
import {getRequest, patchRequest, postRequest} from '../../../../_eaFruitsDms/https/apiCall'
import FetchingRecords from '../../../../_eaFruitsDms/layout/components/fetchingReccords/fetchingRecords'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import Message from '../components/message'
import Overview from '../components/overview'
import {Modal} from 'react-bootstrap'
import {EaAlertSuccess} from '../../../../_eaFruitsDms/layout/components/alert/eaAlert'
import usePermission from '../../../../_eaFruitsDms/helpers/components/usePermission'

const ComplaintsDetails = () => {
  const location: any = useLocation()
  const navigate = useNavigate()

  const [complaintsId] = useState(localStorage.getItem('complaints-detail-id') || '')
  const [fetchingRecords, setFetchingRecords] = useState(true)
  const [records, setRecords] = useState<any>({})
  const [showAddComment, setShowAddComment] = useState(false)
  const [submit, setSubmit] = useState('Submit')
  const [reply, setReply] = useState('')
  const [replyValid, setReplyValid] = useState('')
  const [message, setMessage] = useState('')
  const [alert, setIsAlert] = useState(false)
  const [type, setType] = useState('')

  const permission = usePermission('complaint_request')
  const initialPermission = {read: true, write: true, delete: true}

  useEffect(() => {
    if (!isEmpty(complaintsId)) {
      fetchComplaintsDetails()
    }
  }, [complaintsId])

  const fetchComplaintsDetails = async () => {
    setFetchingRecords(true)
    const url = `${apiBaseURL()}complaint_request/detail-complaint/${complaintsId}`
    const response = await getRequest(url, true)

    if (response.result) {
      setRecords(response.result)
      setFetchingRecords(false)
    }
  }

  const clear = () => {
    setSubmit('Submit')
    setReply('')
    setReplyValid('')
    setShowAddComment(false)
    setType('')
  }

  const onSubmit = async () => {
    if (!isEmpty(reply.trim())) {
      if (type === '') {
        setSubmit('Please Wait')

        const url = `${apiBaseURL()}complaint_request/create-complaint-comment`
        const body = {
          complaint_request: complaintsId,
          message: reply,
        }

        const response = await postRequest(url, body, true)

        if (response.status === 201) {
          clear()
          setIsAlert(true)
          setTimeout(() => {
            setIsAlert(false)
          }, 2000)
          setMessage('Your complaint added successfully.')
          setSubmit('Submit')
          navigate('/all-complaints-details/comments')
        }
      } else {
        handleStatus(type)
      }
    } else {
      setReplyValid('is-invalid')
    }
  }

  const handleStatus = async (status: string, openModal?: boolean) => {
    if (openModal) {
      setShowAddComment(true)
      setType(status)
      return
    }

    setSubmit('Please Wait')

    const url = `${apiBaseURL()}complaint_request/update-complaint-status/${complaintsId}`
    const body = {
      status: status,
      note: isEmpty(reply) ? null : reply,
    }

    await patchRequest(url, body, true)
    fetchComplaintsDetails()
    clear()
  }

  return (
    <>
      <EATitle title='Complaints' />

      <PageTitle
        breadcrumbs={[
          {
            title: 'Complaints',
            subTitle: 'Complaints',
          },
        ]}
      >
        Complaints
      </PageTitle>

      <EaAlertSuccess
        onClick={() => {
          setIsAlert(false)
        }}
        show={alert}
        message={message}
      />

      {fetchingRecords ? (
        <FetchingRecords />
      ) : (
        <div className='card mb-4 mb-xl-5'>
          <div className='card-body pt-5 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-1'>
                      <span className='text-dark font-20 fw-bold me-3'>
                        {isEmpty(records.ref_no)
                          ? '-'
                          : `#${records.ref_no} ${isEmpty(records.title) ? '' : records.title}`}
                      </span>
                      {isEmpty(records.status) ? (
                        ''
                      ) : (
                        <>
                          {records.status === 'open' && (
                            <span className='badge badge-light-warning text-dark'>Open</span>
                          )}

                          {records.status === 'resolved' && (
                            <span className='badge badge-light-primary text-dark'>Resolved</span>
                          )}

                          {records.status === 'closed' && (
                            <span className='badge badge-light-success text-dark'>Closed</span>
                          )}

                          {records.status === 'in_process' && (
                            <span className='badge badge-light-danger text-dark'>In Progress</span>
                          )}
                        </>
                      )}
                    </div>

                    <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                      <span className='d-flex align-items-center me-6 mb-2'>
                        <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='me-1' />
                        {isEmpty(records.customer) || isEmpty(records.customer.business_name)
                          ? '-'
                          : records.customer.business_name}
                      </span>

                      <span className='d-flex align-items-center me-5 mb-2 '>
                        <KTSVG path='/media/icons/duotune/general/gen045.svg' className='me-1' />
                        {isEmpty(records.assign_at) ? '-' : dateTimeFormat(records.assign_at)}
                      </span>
                    </div>
                  </div>

                  {records.status !== 'closed' &&
                    (checkUserType(['wm']) ? permission.write : initialPermission.write) && (
                      <div>
                        {records.status === 'open' && !checkUserType(['ts', 'sa']) && (
                          <button
                            onClick={() => {
                              handleStatus('in_process')
                            }}
                            className='btn btn-light me-3'
                          >
                            Mark as In Progress
                          </button>
                        )}

                        {records.status === 'in_process' && !checkUserType(['ts', 'sa']) && (
                          <button
                            onClick={() => {
                              handleStatus('resolved', true)
                            }}
                            className='btn btn-light me-3'
                          >
                            Mark as Resolved
                          </button>
                        )}

                        {checkUserType(['ts', 'wm', 'sa']) ? (
                          <button
                            onClick={() => {
                              setShowAddComment(true)
                            }}
                            className='btn btn-primary me-2'
                            type='button'
                          >
                            Add Comment
                          </button>
                        ) : (
                          <>
                            {records.status !== 'open' ? (
                              <button
                                onClick={() => {
                                  setShowAddComment(true)
                                }}
                                className='btn btn-primary me-2'
                                type='button'
                              >
                                Add Comment
                              </button>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                        {checkUserType(['ts']) && (
                          <button
                            className='btn btn-danger'
                            type='button'
                            onClick={() => {
                              handleStatus('closed', true)
                            }}
                          >
                            Close Complaint
                          </button>
                        )}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className='d-flex overflow-auto h-40px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/all-complaints-details/overview' && 'active')
                    }
                    to='/all-complaints-details/overview'
                  >
                    Overview
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-0 ` +
                      (location.pathname === '/all-complaints-details/comments' && 'active')
                    }
                    to={`/all-complaints-details/comments`}
                  >
                    Comments
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <Routes>
        <Route
          path='overview'
          element={<Overview records={records} fetchingRecords={fetchingRecords} />}
        />
        <Route path='comments' element={<Message loading={fetchingRecords} alert={alert} />} />
      </Routes>

      <>
        <Modal
          show={showAddComment}
          onHide={clear}
          centered
          backdrop='static'
          dialogClassName='h-auto mw-570px'
          className='complaint-modal'
        >
          <Modal.Header>
            <Modal.Title>
              <h2 className='modal-title fw-bold'>
                {type === 'resolved' && 'Add Resolve Note'}
                {type === 'closed' && 'Add Close Note'}
                {type === '' && 'Add Comment'}
              </h2>
            </Modal.Title>
            <div className='btn p-1 mh-24 btn-active-light-primary ms-2' onClick={clear}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-sm-12'>
                <textarea
                  className={`form-control ${replyValid}`}
                  rows={4}
                  placeholder='Type here…'
                  name='reply'
                  value={reply}
                  onChange={(e: any) => {
                    setReply(e.target.value)
                    setReplyValid('')
                  }}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-center'>
              <button
                className='btn btn-secondary me-3'
                onClick={clear}
                disabled={submit !== 'Submit'}
              >
                Cancel
              </button>
              <button className='btn btn-primary' onClick={onSubmit} disabled={submit !== 'Submit'}>
                {submit}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}

export default ComplaintsDetails
