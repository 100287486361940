import $ from 'jquery'
import moment from 'moment-timezone'
import tenantConfiguration from '../../TenantVariables'
import {getDropdownRequest, getRequest, patchRequest, postRequest} from '../https/apiCall'
import {PageLink} from '../layout/core'
import {filters} from '../utils/FilterConstants'

export const apiBaseURL = () => tenantConfiguration.apiBaseUrl // API URL
export const GLOBAL_DATE_FORMAT = tenantConfiguration.baseDateFormat // GLOBAL DATE FORMATE
export const googleApi =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyBG6XTJTZSMMqakUOYk2qMHZfP64zj3T2I&libraries=geometry,drawing,places'
export const mapZoom = 15
export const customerMapZoom = 18

export const IMPERSONATE_FAILED =
  'Sorry, you can not impersonate this account, as this user is yet to verify their email account!'
export const IMPERSONATE_FOR_INACTIVE =
  'Sorry, you can not impersonate this account, as this user is inactive!'
export const defaultPermission = {
  read: false,
  write: false,
  delete: false,
}

// temporary implement of permissions management for warehouse user
export const wmDefaultPermission = {
  read: true,
  write: true,
  delete: true,
}

export const shortName = (fullName: any) => {
  // for concat first and short name
  fullName = fullName.split(' ')
  if (fullName.length > 1) {
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0)
    return initials.toUpperCase()
  }

  const initials = fullName.shift().charAt(0)
  return initials.toUpperCase()
}

// parent and child category option css
export const categoryOptions = {
  color: '#6d6e6f',
  bg: '#f7f7f7',
  transform: 'uppercase',
  boxSizing: 'borderBox',
  weight: '500',
  cursor: 'default',
  size: '.75rem !important',
}

// handling static search for dropdown
export const staticSearch = (array: any, search: any) => {
  let matchingStrings: any = []
  array.forEach((list: any) => {
    if (list.label.toLocaleLowerCase().search(search.toLocaleLowerCase()) > -1) {
      matchingStrings.push(list)
    }
  })
  return matchingStrings
}

// handling asyncDropdown functions
export const fetchAsyncDropdown = async (
  offset: any,
  setOffset: any,
  dropDownList: any,
  setDropDownList: any,
  url: any,
  search: any,
  setSearch: any,
  customLabel?: any,
  isDropdownTitle?: boolean,
  dropdownTitle?: string
) => {
  // for search in AsyncDropdown
  if (search != '') {
    setSearch(search)
    setOffset('')
  }

  let limit = 10 // default limit

  let options: any = []

  let concatUrl = `${apiBaseURL()}${url}&search=${search}&limit=${limit}&offset=${offset}`

  const response = await getRequest(concatUrl, true)

  let hasMore: boolean = false

  if (response.results) {
    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setOffset(newOffset)
      hasMore = true
    }

    if (dropDownList.length === 0) {
      if (isDropdownTitle) {
        // for drop-down title - EX : All Brands ::: NOTE : if you pass isDropdownTitle = true then don't forget to pass dropdownTitle param.
        options.push({
          label: dropdownTitle, // these
          value: '',
        })
      }
    }

    if (response.results.length > 0) {
      response.results.map((option: any) => {
        options.push({
          label: customLabel ? option[customLabel] : option.name,
          value: option.id,
        })
      })
    }

    if (dropDownList.length > 0) {
      setDropDownList(dropDownList.concat(options))
    } else {
      setDropDownList(options)
    }
  }

  return {
    options: options,
    hasMore: hasMore,
  }
}

// firstname + lastname
export const fetchAsyncDropdownNew = async (
  offset: any,
  setOffset: any,
  dropDownList: any,
  setDropDownList: any,
  url: any,
  search: any,
  setSearch: any,
  customFirstLabel?: any,
  customLastLabel?: any,
  isDropdownTitle?: boolean,
  dropdownTitle?: string
) => {
  // for search in AsyncDropdown
  if (search != '') {
    setSearch(search)
    setOffset('')
  }

  let limit = 10 // default limit

  let options: any = []

  let concatUrl = `${apiBaseURL()}${url}&search=${search}&limit=${limit}&offset=${offset}`

  const response = await getRequest(concatUrl, true)

  let hasMore: boolean = false

  if (response.results) {
    if (response.next !== null) {
      const queryParams = new URLSearchParams(response.next)
      let newOffset: any = queryParams.get('offset')
      setOffset(newOffset)
      hasMore = true
    }

    if (dropDownList.length === 0 && response.results.length > 0) {
      if (isDropdownTitle) {
        // for drop-down title - EX : All Brands ::: NOTE : if you pass isDropdownTitle = true then don't forget to pass dropdownTitle param.
        options.push({
          label: dropdownTitle, // these
          value: '',
        })
      }
    }

    if (customLastLabel) {
      if (response.results.length > 0) {
        response.results.map((option: any) => {
          options.push({
            label: option[customFirstLabel] + ' ' + option[customLastLabel],
            value: option.id,
          })
        })
      }
    } else {
      if (response.results.length > 0) {
        response.results.map((option: any) => {
          options.push({
            label: customFirstLabel ? option[customFirstLabel] : option.name,
            value: option.id,
          })
        })
      }
    }

    if (dropDownList.length > 0) {
      setDropDownList(dropDownList.concat(options))
    } else {
      setDropDownList(options)
    }
  }

  return {
    options: options,
    hasMore: hasMore,
  }
}

// handling fetchStaticAsyncDropdown functions
export const fetchStaticAsyncDropdown = async (array: any, search: any) => {
  let option: any = []

  const searchList = staticSearch(array, search)

  if (search !== '') {
    option = searchList
  } else {
    option = array
  }

  return {
    options: option,
    hasMore: false,
  }
}

// handling dateformat functions
export const dateFormat = (date: any, dateFormat?: any, withOutTimezoneConvert?: boolean) => {
  let convertableTimezone = localStorage.getItem('timeZone')

  if (isEmpty(convertableTimezone)) {
    convertableTimezone = tenantConfiguration.timezone
  }

  if (withOutTimezoneConvert) {
    convertableTimezone = null
  }

  let mainDate = {
    displayDate:
      convertableTimezone === null
        ? moment(date).format(GLOBAL_DATE_FORMAT)
        : moment.utc(date).tz(convertableTimezone).format(GLOBAL_DATE_FORMAT),
    apiDate: moment(date).format('YYYY-MM-DD'),
  }

  if (dateFormat) {
    mainDate.displayDate =
      convertableTimezone === null
        ? moment(date).format(dateFormat)
        : moment.utc(date).tz(convertableTimezone).format(dateFormat)
  }

  return mainDate
}

export const dateFormatWithoutTimeZoneCovert = (date: any, dateFormat?: any) => {
  let mainDate = {
    displayDate: moment(date).format(GLOBAL_DATE_FORMAT),
    apiDate: moment(date).format('YYYY-MM-DD'),
  }

  if (dateFormat) {
    mainDate.displayDate = moment(date).format(dateFormat)
  }

  return mainDate
}

export const dateTimeFormat = (date: any) => {
  return dateFormat(date).displayDate + ' ' + dateFormat(date, 'LT').displayDate
}

// handling file download
export const downloadFile = (url: string) => {
  const name = url.substring(url.lastIndexOf('/') + 1)

  fetch(url).then((response) => {
    response.blob().then((blob) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob)
      // Setting various property values
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = name
      alink.click()
    })
  })
}

// handling file download
export const downloadMultiPleFile = (urls: any) => {
  urls.map((url: string) => {
    const name = url.substring(url.lastIndexOf('/') + 1)

    fetch(url).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob)
        // Setting various property values
        let alink = document.createElement('a')
        alink.href = fileURL
        alink.download = name
        alink.click()
      })
    })
  })
}

export const goToTop = () => {
  window.scrollTo(0, 0)
}

export const removeBlur = () => {
  $('input').blur()
}

// handling validation
export const isModalValidation = (list: any) => {
  let validation: any = []
  for (var key in list) {
    if (list[key] === '' || list[key] === null) {
      validation.push(key)
    }
  }
  return validation
}

// handling formdata
export const formDataConverter = (list: any, removeKeyList?: any) => {
  let formData = new FormData()

  Object.entries(list).map((object: any) => {
    if (!removeKeyList.includes(object[0])) {
      return formData.append(object[0], object[1])
    }
  })

  return formData
}

export const eaCloseModal = (customId?: string) => {
  if (customId && customId !== '') {
    $(`#${customId}`).trigger('click')
  } else {
    $(`#defaultCloseModal`).trigger('click')
  }
}

export const measurementLabel = (label: string) => {
  switch (label) {
    case 'Kilogram':
    case 'kilogram':
      return 'Kgs'
    case 'Gram':
    case 'gram':
      return 'Gms'
    case 'Plastic Bag':
    case 'Plastic Bags':
      return 'Bags'
    default:
      return label
  }
}

export const generateUUID = (length: number = 10) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

// Developed By: Pinak Mehta to get subdomain from the URL
export const getSubDomain = () => {
  const full = window.location.host
  const parts = full.split('.')
  const sub = parts[0]

  return sub
}

//Developed By: Pinak Mehta -> Check how much verification pending for the customer
export const verificationPending = (customer: any) => {
  let verification: any = {
    pending: 0,
    type: [],
  }

  if (!customer.verification_statuses.contact_verified) {
    verification.pending = verification.pending + 1
    verification.type.push('Mobile')
  }

  if (!customer.verification_statuses.business_doc_verified) {
    verification.pending = verification.pending + 1
    verification.type.push('Business Details')
  }

  if (!customer.verification_statuses.business_address_verified) {
    verification.pending = verification.pending + 1
    verification.type.push('Business Address')
  }

  return verification
}

export const formatVerificationType = (verificationType: any) => {
  let displayString

  if (verificationType.length > 2) {
    displayString = verificationType.join(', ').replace(/,([^,]*)$/, ' & $1')
  } else if (verificationType.length === 2) {
    displayString = verificationType.join(' & ')
  } else {
    displayString = verificationType[0]
  }

  return displayString
}

export const formatTags = (tags: any, keyName?: any) => {
  let displayString

  if (isEmpty(keyName)) {
    keyName = 'tag_name'
  }
  console.log('keyName================', keyName)
  const filteredTags: any = []

  tags.map((tag: any, index: number) => {
    if (index > 0) {
      filteredTags.push(tag[keyName])
    }
  })
  console.log('tags================', tags)
  console.log('filteredTags================', filteredTags)
  if (filteredTags.length > 2) {
    displayString = filteredTags.join(', ').replace(/,([^,]*)$/, ' & $1')
  } else if (filteredTags.length === 2) {
    displayString = filteredTags.join(' & ')
  } else {
    displayString = filteredTags[0]
  }

  return displayString
}

// number format
export const numberFormat = (price: any, convertToFloat?: boolean) => {
  if (convertToFloat) {
    price = parseFloat(price)
      .toFixed(2)
      .replace(/\.?0+$/, '')
  }
  let format = tenantConfiguration.numberFormat
  const converted = new Intl.NumberFormat(format).format(price)

  return {
    displayFormat: price && converted,
    apiFormat: price,
  }
}

export const numberFormatWithCode = (price: any, convertToFloat?: boolean) => {
  let format = tenantConfiguration.numberFormat
  const converted = new Intl.NumberFormat(format).format(price)

  return {
    displayFormat:
      tenantConfiguration.currencyCode +
      ' ' +
      (convertToFloat
        ? parseFloat(converted)
            .toFixed(2)
            .replace(/\.?0+$/, '')
        : converted),
    apiFormat: price,
  }
}

// Developed By: Kriti -> Focus should navigate to field with error
export const focusVatidateField = (value: any) => {
  const element = document.getElementById(value)
  if (element) {
    element.focus()
    element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'})
  }
}

// Developed By: Pinak -> Check that given date is today or not
export const dateIsToday = (date: any) => {
  const today = dateFormat(moment().endOf('day')).apiDate

  return date === today
}

export const cmsInquiresTitle: Array<PageLink> = [
  {
    title: 'CMS',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Inquiries',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
]

export const roleAndPermission = async () => {
  localStorage.removeItem('permissions')
  const res: any = await getRequest(`${apiBaseURL()}auth/fetch-feature-permission`, true)

  if (res.length > 0) {
    localStorage.setItem('permissions', JSON.stringify(res))
    let savePermission: any = {}
    res &&
      res.length > 0 &&
      res.forEach((element: any) => {
        if (
          element.role_feature_permissions.length > 0 &&
          (element.role_feature_permissions.includes('read') ||
            element.role_feature_permissions.includes('write'))
        ) {
          savePermission[element.feature_name] = true
        }
      })
    localStorage.setItem('savePermissions', JSON.stringify(savePermission))

    return res
  }
  return []
}

export const checkPermissions = async (feature?: string) => {
  const localstoragePermission: any = localStorage.getItem('permissions')
  let res: any = JSON.parse(localstoragePermission)

  if (!localstoragePermission) {
    res = await roleAndPermission()
  }

  const permission: any = {
    read: false,
    write: false,
    delete: false,
  }
  const featureResponse =
    res && res.length > 0 ? res.find((option: any) => option.feature_name === feature) : null

  if (!isEmpty(featureResponse)) {
    featureResponse.role_feature_permissions.length > 0 &&
      featureResponse.role_feature_permissions.map((item: any) => {
        switch (item) {
          case 'read':
            permission.read = true
            break
          case 'write':
            permission.write = true
            break
          case 'delete':
            permission.delete = true
        }
      })
  }
  return permission
}

export const checkPermissionsWithLoading = async (feature?: string) => {
  const localstoragePermission: any = localStorage.getItem('permissions')
  let res: any = JSON.parse(localstoragePermission)

  if (!localstoragePermission) {
    // res = await roleAndPermission()
  }

  const permission: any = {
    read: false,
    write: false,
    delete: false,
  }
  const featureResponse =
    res && res.length > 0 ? res.find((option: any) => option.feature_name === feature) : null

  if (!isEmpty(featureResponse)) {
    featureResponse.role_feature_permissions.length > 0 &&
      featureResponse.role_feature_permissions.map((item: any) => {
        switch (item) {
          case 'read':
            permission.read = true
            break
          case 'write':
            permission.write = true
            break
          case 'delete':
            permission.delete = true
        }
      })
  }
  return permission
}
// re-sending of activation email :- Kriti
export const reSendEmail = async (checkEmail?: any) => {
  let body = {
    url: tenantConfiguration.forgotPasswordBaseUrl,
    email: checkEmail,
  }
  const res = await postRequest(`${apiBaseURL()}auth/reset-password`, body, true)

  return res
}

export const checkEmpty = (data: any, defaultReturnValue?: string, checkZero?: boolean) => {
  const checkValues: any = ['', null, 'null', undefined]

  if (checkZero) {
    checkValues.push(0)
    checkValues.push('0')
  }

  if (checkValues.includes(data)) {
    if (defaultReturnValue || defaultReturnValue === '') {
      return defaultReturnValue
    }

    return '-'
  }

  return data
}

export const isEmpty = (data: any, checkZero?: boolean) => {
  const checkValues: any = ['', null, 'null', undefined, '-']

  if (checkZero) {
    checkValues.push(0)
    checkValues.push('0')
  }

  return checkValues.includes(data)
}

//Develop By:  Pinak Mehta to check website access from the mobile or not
export const checkMobile = () => {
  return /iPhone|iPad|iPod|Android|Mobile|Tablet/i.test(navigator.userAgent)
}

export const displayAddedBy = (addedBy: any) => {
  if (addedBy == null) {
    return '-'
  }

  return (
    (addedBy.first_name + ' ' + addedBy.last_name).trim() + ' (' + addedBy.type.toUpperCase() + ')'
  )
}

export const salesInvoicePaymentInfo = (payment: any) => {
  let paymentMode = 'Cash'

  switch (payment.payment_method_type) {
    case 'bank_transfer':
      paymentMode = `Bank Transfer <br> ${payment.bank ? payment.bank.bank_name : '-'}`
      break
    case 'cheque':
      paymentMode = `Cheque<br> ${payment.bank_name !== null ? payment.bank_name : '-'}`
      break
    case 'credit_request':
      paymentMode = 'Credit Request'
      break
    case 'mobile_wallet':
      paymentMode = 'Mobile Wallet'
      break
  }

  return {
    payment_mode: paymentMode,
    ref_no: payment.ref_no
      ? '#' + payment.ref_no
      : payment.cheque_no
      ? '#' + payment.cheque_no
      : '-',
    status: payment.status,
    received_by:
      payment.received_by !== null
        ? payment.received_by.first_name +
          ' ' +
          payment.received_by.last_name +
          '(' +
          payment.received_by.type.toUpperCase() +
          ')'
        : '-',
  }
}

export const calculateWeight = (product: any, quantity: number) => {
  let total: number = 0

  if (product.fix_size) {
    if (product.measurement_unit.toLowerCase() === 'gram') {
      total = quantity * (product.size / 1000)
    } else {
      total = quantity * product.size
    }
  } else {
    if (product.variants && product.variants.approx_weight > 0) {
      total = (quantity * product.variants.approx_weight) / 1000
    } else {
      total = quantity
    }
  }

  return total
}

export const numberToWords = (number: any) => {
  const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine']
  const teens = [
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ]
  const tens = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ]
  const scales = ['', 'thousand', 'million', 'billion', 'trillion']

  if (number === 0) {
    return 'zero'
  }

  const getChunk = (number: any) => {
    const chunk = []

    if (number >= 100) {
      chunk.push(units[Math.floor(number / 100)])
      chunk.push('hundred')
      number %= 100
    }

    if (number >= 10 && number <= 19) {
      chunk.push(teens[number - 10])
      return chunk
    }

    if (number >= 20) {
      chunk.push(tens[Math.floor(number / 10)])
      number %= 10
    }

    if (number >= 1 && number <= 9) {
      chunk.push(units[number])
    }

    return chunk
  }

  const chunks = []

  let wholeNumber: any = Math.floor(number)
  const decimalPart = Math.round((number - wholeNumber) * 100) // Convert decimal to whole number (e.g., 0.5 -> 50)

  while (wholeNumber > 0) {
    chunks.push(getChunk(wholeNumber % 1000))
    wholeNumber = Math.floor(wholeNumber / 1000)
  }

  if (chunks.length === 0) {
    return ''
  }

  const words = []
  for (let i = chunks.length - 1; i >= 0; i--) {
    const chunk = chunks[i]
    if (chunk.length > 0) {
      words.push(...chunk)
      if (scales[i]) {
        words.push(scales[i])
      }
    }
  }

  if (decimalPart > 0) {
    words.push('point') // Add "point" before decimal part
    words.push(getChunk(decimalPart).join(' '))
  }

  return words.join(' ')
}

export const requestHeaders = (token?: boolean, multipart?: boolean) => {
  const accessToken = localStorage.getItem('token')
  const userId = localStorage.getItem('user_id')

  const header: any = {
    headers: {
      'DEVICE-TYPE': 'Web',
      'USER-ID': isEmpty(userId) ? null : userId,
    },
  }

  if (multipart) {
    header.headers['Content-Type'] = 'multipart/form-data'
  }

  if (token) {
    header.headers['Authorization'] = `Bearer ${accessToken}`
  }

  return header
}

export const requestLocationPermission = () => {
  if (navigator.permissions) {
    navigator.permissions
      .query({name: 'geolocation'})
      .then((permissionStatus) => {
        if (permissionStatus.state === 'granted') {
          console.log('Geolocation permission granted.')
        } else if (permissionStatus.state === 'prompt') {
          console.log('Geolocation permission prompt.')
        } else if (permissionStatus.state === 'denied') {
          console.log('Geolocation permission denied.')
        }
      })
      .catch((error) => {
        console.log('Error getting geolocation permission:', error)
      })
  } else {
    console.log('Geolocation API not supported.')
  }
}

export const fetchLocation = async (
  setCustomerCaptureLatLong: any,
  setCaptureLocation: any,
  setLocationDisable: any
) => {
  if (checkMobile()) {
    requestLocationPermission()
    if (navigator.geolocation) {
      setLocationDisable(true)
      const options = {
        enableHighAccuracy: true,
        maximumAge: 0,
      }
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position.coords.latitude != null && position.coords.longitude) {
            setCustomerCaptureLatLong({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
            setLocationDisable(false)
            setCaptureLocation(true)
            // patchRequest(`${apiBaseURL()}customers/${customerId}/capture-location`, customerLocation, true)
          }
        },
        (error) => {
          setLocationDisable(false)
          console.log(error)
        },
        options
      )
    } else {
      alert('Geolocation is not supported by this browser.')
    }
  } else {
    alert(
      "Sorry, we can not capture accurate location using the desktop/laptop browsers! Please try using this feature using the mobile browser while visiting the customer's location."
    )
  }
}

export const saveLocation = async (
  customerId: any,
  customerCaptureLatLong: any,
  setLocationButtonDisable: any,
  setCaptureLocation: any,
  fetchCustomerDetails: any
) => {
  const customerLocation: any = {
    captured_latitude: customerCaptureLatLong.latitude,
    captured_longitude: customerCaptureLatLong.longitude,
  }

  setLocationButtonDisable(true)
  await patchRequest(
    `${apiBaseURL()}customers/${customerId}/capture-location`,
    customerLocation,
    true
  )
  await fetchCustomerDetails()
  setLocationButtonDisable(false)
  setCaptureLocation(false)
}

export const calculateDays = (startDate: any, endDate: any) => {
  const duration = moment.duration(moment(endDate).diff(moment(startDate)))
  return duration.asDays() === 0 ? '0 Day' : duration.asDays() + ' Days'
}

// dropdown details for customer category : kriti
export const fetchCustomerCategories = async (
  setCategories: any,
  categories: any,
  search?: any
) => {
  let options: any = []
  const response = await getDropdownRequest(
    `${apiBaseURL()}master/customer-category-parent/list/`,
    true
  )

  if (response.status == 200) {
    const categoriesResponse = response.data

    if (categories.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    const categoryKeys = Object.keys(categoriesResponse)

    categoryKeys.forEach((value) => {
      let optionId: string = ''

      options.push({
        label: value,
        value: optionId,
        color: '#6d6e6f',
        bg: '#f7f7f7',
        transform: 'uppercase',
        boxSizing: 'borderBox',
        weight: '500',
        cursor: 'default',
        size: '.75rem !important',
      })

      categoriesResponse[value].map((option: any) => {
        if (optionId === '') {
          optionId += option.id
        } else {
          optionId += ',' + option.id
        }
        options.push({
          label: option.category,
          value: option.id,
        })
        return options
      })

      let index = options.findIndex((x: any) => x.label === value)
      options[index].value = optionId
    })

    if (categories.length > 0) {
      setCategories(categories.concat(options))
    } else {
      setCategories(options)
    }

    if (search !== '') {
      options = staticSearch(options, search)
    }
  }

  return {
    options: options,
    hasMore: false,
  }
}

export const isValidLatitude = (latitude: any) => {
  const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/
  return regex.test(latitude)
}

export const isValidLongitude = (longitude: any) => {
  const regex = /^[-+]?([1-9]?\d(\.\d+)?|1[0-7]\d(\.\d+)?|180(\.0+)?)$/
  return regex.test(longitude)
}

export const saveFilter = (key: any, state: any) => {
  localStorage.setItem(key, JSON.stringify(state))
}

export const retrieveFilter = (key: any) => {
  const backOptionFilters: string | null = localStorage.getItem(key)
  if (backOptionFilters !== null) {
    return JSON.parse(backOptionFilters)
  }
  return null
}

export const removeFilter = (key: any) => {
  localStorage.removeItem(key)
}

export const isAdmin = () => {
  const userType = localStorage.getItem('user_type') || ''

  return userType.toLowerCase() === 'sa'
}

export const isWarehouseManager = () => {
  const userType = localStorage.getItem('user_type') || ''

  return userType.toLowerCase() === 'wm'
}

export const checkUserType = (type: any) => {
  const userType = localStorage.getItem('user_type') || ''

  return typeof type === 'string'
    ? userType.toLowerCase() === type
    : type.includes(userType.toLowerCase())
}

export const checkRoleName = (role: any) => {
  const roleName = localStorage.getItem('roleName') || ''

  if (isEmpty(roleName)) return false

  return typeof role === 'string' ? roleName === role : role.includes(roleName)
}

export const removeFilterImpersonate = () => {
  if (!isEmpty(filters)) {
    Object.entries(filters).map((object: any) => {
      if (object.length > 0) {
        localStorage.removeItem(object[1])
      }
    })
  }
}

export const getLoginWarehouse = () => {
  let warehouseName = null
  let warehouseId = null

  if (
    JSON.parse(localStorage.getItem('w_id') || '') !== null ||
    JSON.parse(localStorage.getItem('w_id') || '') !== ''
  ) {
    warehouseName = localStorage.getItem('warehouse_name')
    warehouseId = JSON.parse(localStorage.getItem('w_id') || '')
  }

  const warehouse = {label: warehouseName, value: warehouseId}
  return warehouse
}

export const getFirstWarehouse = async (url: any) => {
  const response = await getRequest(url, true)
  let firstWarehouse = {}
  if (response && response.results) {
    if (response.results.length > 0) {
      firstWarehouse = {label: response.results[0].name, value: response.results[0].id}
    }
  }
  return firstWarehouse
}

export const contactNumber = (contactNo: any) => {
  return `${tenantConfiguration.baseCountryCode} ${contactNo}`
}

export const getQuarter = (currentQuarterNumber?: number, getShortMonth?: boolean) => {
  let monthFormat = 'MMMM'

  if (getShortMonth) {
    monthFormat = 'MMM'
  }

  let quarterDetails: any = {
    startMonth: moment().startOf('quarter'),
    endMonth: moment().endOf('quarter'),
    currentQuarter: moment().quarter(),
    concatMonths: `${moment().startOf('quarter').format(monthFormat)} - ${moment()
      .endOf('quarter')
      .format(monthFormat)}`,
  }

  if (currentQuarterNumber) {
    quarterDetails = {
      startMonth: moment().quarter(currentQuarterNumber).startOf('quarter').format(monthFormat),
      endMonth: moment().quarter(currentQuarterNumber).endOf('quarter').format(monthFormat),
      currentQuarter: moment().quarter(currentQuarterNumber),
      concatMonths: `${moment()
        .quarter(currentQuarterNumber)
        .startOf('quarter')
        .format(monthFormat)} - ${moment()
        .quarter(currentQuarterNumber)
        .endOf('quarter')
        .format(monthFormat)}`,
    }
  }

  let startDate = currentQuarterNumber
    ? moment().quarter(currentQuarterNumber).startOf('quarter')
    : moment().startOf('quarter')

  let endDate = currentQuarterNumber
    ? moment().quarter(currentQuarterNumber).endOf('quarter')
    : moment().endOf('quarter')

  let getQuarterMonths = []

  if (!endDate.isBefore(startDate)) {
    while (startDate.isBefore(endDate)) {
      getQuarterMonths.push(startDate.format(monthFormat))
      startDate.add(1, 'month')
    }
  }

  let concatDetails = {
    ...quarterDetails,
    quarterMonths: getQuarterMonths,
  }

  return concatDetails
}

export const customShortName = (firstName: any, lastName: any) => {
  const firstNameInitial = firstName[0].toUpperCase()
  const lastNameInitial = lastName[0].toUpperCase()
  return `${firstNameInitial}${lastNameInitial}`
}
const userType = localStorage.getItem('user_type') || ''
export const getWarehouseId = !['sa', 'ts'].includes(userType.toLowerCase())
  ? localStorage.getItem('w_id')
  : ''

export const numberFormatRatio = (value: any) => {
  if (value <= 1) {
    return value === 0 ? 0 : 1
  }

  const decimalPart = value - Math.floor(value)

  if (decimalPart === 0.5) {
    return value
  } else if (decimalPart < 0.5) {
    return Math.floor(value)
  } else {
    return Math.ceil(value)
  }
}

export const firstLetterCapital = (value: string) => {
  if (!isEmpty(value)) {
    return value.replace(/^./, value[0].toUpperCase())
  }

  return value
}
