import clsx from "clsx";
import $ from "jquery";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  bulkPriceCreateUpdate,
  ManageAllPriceList,
  ManagePriceList
} from "../../../_eaFruitsDms/apiFunctions/priceDiscount/priceDiscount";
import {
  apiBaseURL,
  categoryOptions,
  checkPermissions,
  dateFormat,
  defaultPermission,
  fetchAsyncDropdown,
  GLOBAL_DATE_FORMAT,
  KTSVG,
  measurementLabel,
  numberFormat,
  removeBlur,
  retrieveFilter,
  saveFilter
} from "../../../_eaFruitsDms/helpers";
import { categoryStyle } from "../../../_eaFruitsDms/helpers/components/categoryStyle";
import FormatMessage from "../../../_eaFruitsDms/helpers/components/FormateMessage";
import ValidationHandler from "../../../_eaFruitsDms/helpers/components/validationHandler";
import DatePickerCustom from "../../../_eaFruitsDms/helpers/datePicker";
import { getRequest } from "../../../_eaFruitsDms/https/apiCall";
import { EaAlertDanger, EaAlertSuccess } from "../../../_eaFruitsDms/layout/components/alert/eaAlert";
import EaButton from "../../../_eaFruitsDms/layout/components/button/eaButton";
import IsLoader from "../../../_eaFruitsDms/layout/components/loader/loader";
import NoRecords from "../../../_eaFruitsDms/layout/components/noRecords/noRecords";
import CustomPagination from "../../../_eaFruitsDms/layout/components/pagination/pagination";
import CustomSearchBar from "../../../_eaFruitsDms/layout/components/searchbar/searchbar";
import SkuThumbnail from "../../../_eaFruitsDms/layout/components/skuThumbnail/skuThumbnail";
import { PageTitle } from "../../../_eaFruitsDms/layout/core";
import { getToken, getW_Id } from "../../../_eaFruitsDms/localStorage/getItem/getLocalStorage";
import tenantConfiguration from "../../../TenantVariables";
import EATitle from "../../../_eaFruitsDms/layout/components/title/title";
import CustomPaginate from "../../../_eaFruitsDms/layout/components/pagination/customPaginate";
import ResetButton from "../../../_eaFruitsDms/layout/components/button/resetButton";
import useDebounce from "../../../_eaFruitsDms/helpers/components/useDebounce";
import { filters } from "../../../_eaFruitsDms/utils/FilterConstants";

type Props = {
  className: string
}

const ManagePrice: React.FC<Props> = ({ className }) => {
  const intl = useIntl()
  const priceHeaderTitle = {
    title: 'Manage Price',
    subTitle: 'Price & Discounts',
  }

  // Authorization token
  const header = {
    headers: {
      Authorization: `Bearer ${getToken}`,
    },
  }

  let managePriceFiltered : any = retrieveFilter(filters.wm_manage_price);
 
  const initialState = {
      filterBrand : managePriceFiltered?.filterBrand || {label : 'All Brands' , value : ''},
      filterCategory: managePriceFiltered?.filterCategory || {label : 'All Categories' , value : ''},
      filterGroups: managePriceFiltered?.filterGroups ||  {label : 'All Product Groups' , value : ''},
      futureDate: managePriceFiltered?.futureDate || false,
      selected: managePriceFiltered?.selected || '',
      search: managePriceFiltered?.search || '',
      currentOffSet: managePriceFiltered?.currentOffSet || '',
      buttonDisable: true
    }

  const [getList, setGetList] = useState<any>([]) // getdata
  const [getListModal, setGetListNodal] = useState<any>([]) // getdata
  const [count, setCount] = useState<string>("1");
  const [next, setNext] = useState('')
  const [prev, setPrev] = useState('')
  const [getLength, setLength] = useState('1')
  const [getLengthModal, setLengthModal] = useState('1')
  const [listLoadder, setListLoader] = useState<any>(false)
  const [timer, setTimer] = useState<any>(null)
  const [search, setSearch] = useState<any>(managePriceFiltered?.search || '')
  const [currentOffset, setCurrentOffset] = useState<any>(managePriceFiltered?.currentOffSet || '0');
  const [selected, setSelected] = useState<number>(managePriceFiltered?.selected || 0)
    const [filterBrand, setFilterBrand] = useState<any>(managePriceFiltered?.filterBrand || {
    label: 'All Brands',
    value: ''
});
const [filterCategory, setFilterCategory] = useState<any>(managePriceFiltered?.filterCategory || {
  label: 'All Categories',
  value: ''
});
const [filterGroups, setFilterGroups] = useState<any>(managePriceFiltered?.filterGroups || {
  label: 'All Product Groups',
  value: ''
});
  const [brandValue, setBrandValue] = useState<any>('')
  const [categoryValue, setCategoryValue] = useState<any>('')
  const [groupsValue, setGroupsValue] = useState<any>('')

  const [brandValueModal, setBrandValueModal] = useState<any>('')
  const [categoryValueModal, setCategoryValueModal] = useState<any>('')
  const [groupsValueModal, setGroupsValueModal] = useState<any>('')
  const [filterBrandModal, setFilterBrandModal] = useState<any>('')
  const [filterCategoryModal, setFilterCategoryModal] = useState<any>('')
  const [filterGroupsModal, setFilterGroupsModal] = useState<any>('')

  const [existingPrices, setExistingPrices] = useState<any>([])
  const [masterArray, setMasterArray] = useState<any>([])
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [dateShow, setDateShow] = useState(moment().format(GLOBAL_DATE_FORMAT))
  const [dateShowSingle, setDateShowSingle] = useState<any>({
    dateApi: moment(new Date()).format('YYYY-MM-DD'),
    dateView: new Date(),
    setDateShowInput: '',
  })
  const [dateShowInput, setDateShowInput] = useState(moment(new Date()).format('DD-MM-YYYY'))
  const [disableBtn, setDisableBtn] = useState(false)
  const [isModalLoading, setIsLoading] = useState('Submit')
  const [IsAlertSuccess, setIsAlertSuccess] = useState(false)
  const [isAlertFailed, setIsAlertFailed] = useState(false)
  const [messageHandler, setMessageHandler] = useState('successEdit')
  const [messageTitle, setMessageTitle] = useState('')
  const [brandOffset, setbrandInOffset] = useState<any>('')
  const [categoryOffset, setCategoryOffset] = useState<any>('')
  const [groupOffset, setGroupsOffset] = useState<any>('')
  const [limit, setLimit] = useState<number>(tenantConfiguration.limit)
  const [brandOption, setBrandOption] = useState<any>([])
  const [categoryOption, setCategoryOption] = useState<any>([])
  const [groupsOption, setGroupsOption] = useState<any>([])
  const [searchSelect, setSearchSelect] = useState('')
  const [priceValue, setPriceValue] = useState<any>({
    wholesalePriceSingle: '',
    wholesaleExpirySingle: '',
    retailPriceSingle: '',
    retailExpirySingle: '',
  })

  const [validation, setValidation] = useState({
    wholesalePriceSingle: '',
    wholesaleExpirySingle: '',
    retailPriceSingle: '',
    retailExpirySingle: '',
    date: '',
  })

  const [bulkValidationRetail, setBulkValidationRetail] = useState<any>([])
  const [bulkValidationWholesale, setBulkValidationWholesale] = useState<any>([])

  const [unit, setUnit] = useState('')
  const [futureDateBulk, setFutureDateBulk] = useState<any>(false)
  const [futureDate, setFutureDate] = useState<any>(managePriceFiltered?.futureDate || false)
  const [permission, setPermission] = useState(defaultPermission)
  const [filterStates, setFilterStates] = useState<any>(initialState);
  const [buttonDisable, setButtonDisable] = useState<boolean>(managePriceFiltered?.buttonDisable || false) 

  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    fetchPermission()
    // Develop BY: Kashyap
    // Description: get table list and dropdown list

    ManageAllPriceList('', '', '', futureDateBulk).then((res: any) => {
      setGetListNodal(res)

      if (res.length > 0) {
        let updatedValidation: any = [...bulkValidationWholesale]
        let updatedValidationRetail: any = [...bulkValidationRetail]

        res.map((item: any) => {
          updatedValidation.push({ product_id: item.id, validation: '', borderValidation: '' })
          updatedValidationRetail.push({ product_id: item.id, validation: '', borderValidation: '' })
        })

        setBulkValidationRetail(updatedValidationRetail)
        setBulkValidationWholesale(updatedValidation)
      }
    })
  }, [bulkValidationRetail.length == 0])

  useEffect(() => {
    setListLoader(true)
    ManagePriceList(limit, currentOffset, debounceSearch, filterBrand.value, filterCategory.value, filterGroups.value, futureDate).then((res: any) => {
      setGetList(res.data)

      if (res.data.length > 0) {
        let updatedPriceData: any = []

        res.data.map((data: any) => {
          if (data.product_sku_price.length > 0) {
            return updatedPriceData.push({
              id: data.product_sku_price[0].id,
              action_by: data.product_sku_price[0].action_by,
              effective_date: data.product_sku_price[0].effective_date,
              product_id: data.product_sku_price[0].product_id,
              retail_expiry_price: data.product_sku_price[0].retail_expiry_price,
              retail_price: data.product_sku_price[0].retail_price,
              warehouse_id: data.product_sku_price[0].warehouse_id,
              wholesale_expiry_price: data.product_sku_price[0].wholesale_expiry_price,
              wholesale_price: data.product_sku_price[0].wholesale_price,
            })
          }
        })
        setExistingPrices(updatedPriceData)
      }

      // setGetSize(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })

    let updatedState: any = { ...filterStates }
    updatedState['selected'] = selected
    updatedState['currentOffSet'] = currentOffset
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.wm_manage_price , filterStates)
    if(filterCategory.value == '' && filterBrand.value == '' && filterGroups.value == '' && search == '' && futureDate == false){
        setButtonDisable(false)
    }

  }, [currentOffset, filterBrand, filterCategory, filterGroups, debounceSearch, futureDate])

  useEffect(() => {
    setbrandInOffset('')
    setCategoryOffset('')
    setGroupsOffset('')
  }, [searchSelect])


  const fetchPermission = async () =>{
    let res = await checkPermissions("price_and_discount_price_management")
    setPermission(res)
   }
  
  const fetchBrand = async (search?: any) => {
    return await fetchAsyncDropdown(
      brandOffset,
      setbrandInOffset,
      brandOption,
      setBrandOption,
      `master/product/brand?`,
      search,
      setSearchSelect,
      '',
      true,
      'All Brands'
    )
  }
  
  const fetchCategory = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }

    let options: any = []

    const CATEGORY_API = `${apiBaseURL()}master/product/parentcategory/category?ordering=name&search=${search}&limit=${limit}&offset=${categoryOffset}`
    const response = await getRequest(CATEGORY_API, true)
    let hasMore: boolean = false

    if(response.results){
      if (response.next !== null) {
        const queryParams = new URLSearchParams(response.next)
        let newOffset: any = queryParams.get('offset')
        setCategoryOffset(newOffset)
        hasMore = true
      }
      if (categoryOption.length == 0) {
        options.push({
          label: 'All Category',
          value: '',
        })
      }
      if (response.results.length > 0) {
        response.results.map((option: any) => {
          options.push({
            label: option.name,
            value: option.id,
            ...categoryOptions,
          })
          if (option.child_category.length > 0) {
            option.child_category.map((child: any) => {
              options.push({
                label: child.name,
                value: child.id,
              })
            })
          }
        })
      }
      if (categoryOption.length > 0) {
        setCategoryOption(categoryOption.concat(options))
      } else {
        setCategoryOption(options)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchProductsGroups = async (search?: any) => {
    if (search != '') {
      setSearchSelect(search)
    }
    let options: any = []

    const GROUPS_API = `${apiBaseURL()}master/product/group?ordering=name&search=${search}&limit=${limit}&offset=${groupOffset}`
    const response = await getRequest(GROUPS_API, true)
    let hasMore: boolean = false

    if(response.results){
      if (response.next !== null) {
        const queryParams = new URLSearchParams(response.next)
        let newOffset: any = queryParams.get('offset')
        setGroupsOffset(newOffset)
        hasMore = true
      }
      if (groupsOption.length == 0) {
        options.push({
          label: 'All Product Groups',
          value: '',
        })
      }
      if (response.results.length > 0) {
        response.results.map((option: any) => {
          options.push({
            label: option.name,
            value: option.id,
          })
        })
      }
      if (groupsOption.length > 0) {
        setGroupsOption(groupsOption.concat(options))
      } else {
        setGroupsOption(options)
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  //   const getDropDownValues = () => {
  //     // get brand list
  //     let brandList: any = [{value: '', label: 'All Brands'}]
  //     let totalBrand: any = []
  //     axios
  //       .get(`${apiBaseURL()}master/product/brand?ordering=name`, header)
  //       .then((res) => {
  //         let data = res.data
  //         data.forEach((element: any) => {
  //           totalBrand.push(element)
  //           brandList.push({value: element.id, label: element.name})
  //         })
  //         setGetBrand(brandList)
  //       })

  //     // get category list
  //     let categoryList: any = [{value: '', label: 'All Categories'}]
  //     let totalCategory: any = []
  //     axios
  //       .get(`${apiBaseURL()}master/product/category?ordering=name`, header)
  //       .then((res) => {
  //         let data = res.data
  //         data.forEach((element: any) => {
  //           if (element.parent_category !== null) {
  //             totalCategory.push(element)
  //             categoryList.push({value: element.id, label: element.name})
  //           }
  //         })
  //         setGetProdcutCategory(categoryList)
  //       })

  //     // get groups list
  //     let groupList: any = [{value: '', label: 'All Groups'}]
  //     let totalGroup: any = []
  //     axios
  //       .get(`${apiBaseURL()}master/product/group?ordering=name`, header)
  //       .then((res) => {
  //         let data = res.data
  //         data.forEach((element: any) => {
  //           totalGroup.push(element)
  //           groupList.push({value: element.id, label: element.name})
  //         })
  //         setProductGrops(groupList)
  //       })
  //   }

  const clearDropDown = () => {
    setFilterBrandModal([{ value: '', label: 'All Brands' }])
    setFilterCategoryModal([{ value: '', label: 'All Categories' }])
    setFilterGroupsModal([{ value: '', label: 'All Groups' }])

    setFilterBrand([{ value: '', label: 'All Brands' }])
    setFilterCategory([{ value: '', label: 'All Categories' }])
    setFilterGroups([{ value: '', label: 'All Groups' }])

    setBrandValueModal('')
    setCategoryValueModal('')
    setGroupsValueModal('')
  }

  const searchList = (e: any) => {
    setFilterStates({ ...filterStates, ['search']: e.target.value })
    setButtonDisable(true)
    clearTimeout(timer)
    setSearch(e.target.value)
    // const newTimer = setTimeout(() => {
    //   ManagePriceList(
    //     '10',
    //     '0',
    //     e.target.value,
    //     brandValue,
    //     categoryValue,
    //     groupsValue,
    //     futureDate
    //   ).then((res) => {
    //     setGetList(res.data)
    //     if (res.data.length == 0) {
    //       setLength('2')
    //     } else {
    //       setLength('1')
    //     }
    //     setCount(res.count)
    //     setNext(res.next)
    //     setPrev(res.prev)
    //     setSelected(0)
    //   })
    // }, 500)
    // setTimer(newTimer)
  }

  const getBulkValue = (e: any, item: any, index?: any) => {
    setExistingPrices([])
    let bulkId = e.target.id
    let productId = item.id
    let checkedValue = item.product_sku_price.length
    const { name, value } = e.target
    let isValid: boolean = true

    let customMasterArr = masterArray

    const updatedBulkValidationWholesale: any = [...bulkValidationWholesale]
    const availableIndexWholesale: any = bulkValidationWholesale.findIndex((retail: any) => {
      return retail && retail.product_id == item.id
    })

    const availableIndexRetail = bulkValidationRetail.findIndex((retail: any) => {
      return retail && retail.product_id == item.id
    })

    const updatedBulkValidationRetail: any = [...bulkValidationRetail]

    try {
      const index = masterArray.findIndex((item: any) => {
        return item && item.id == bulkId
      })

      if (index !== -1) {
        customMasterArr[bulkId][name] = value

        if (name === 'wholesalePrice') {
          customMasterArr[bulkId]['wholesaleExpiry'] = value
          updatedBulkValidationWholesale[availableIndexWholesale].validation = ''
          updatedBulkValidationWholesale[availableIndexWholesale].borderValidation = ''
        }

        if (name === 'wholesaleExpiry' && value == '') {
          customMasterArr[bulkId]['wholesaleExpiry'] = 0
        }

        if (name === 'retailPrice') {
          customMasterArr[bulkId]['retailExpiry'] = value
          updatedBulkValidationRetail[availableIndexRetail].validation = ''
          updatedBulkValidationRetail[availableIndexRetail].borderValidation = ''
        }

        if (name === 'retailExpiry' && value == '') {
          customMasterArr[bulkId]['retailExpiry'] = 0
        }
      } else {
        if (customMasterArr[bulkId] == undefined) {
          customMasterArr[bulkId] = {
            id: item.id,
            checkedValue: checkedValue,
            productId: productId,
            [name]: value,
          }

          if (name === 'wholesalePrice') {
            customMasterArr[bulkId]['wholesaleExpiry'] = value
            updatedBulkValidationWholesale[availableIndexWholesale].validation = ''
            updatedBulkValidationWholesale[availableIndexWholesale].borderValidation = ''
          }
          if (name === 'wholesaleExpiry' && value == '') {
            customMasterArr[bulkId]['wholesaleExpiry'] = 0
          }
          if (name === 'retailPrice') {
            customMasterArr[bulkId]['retailExpiry'] = value
            updatedBulkValidationRetail[availableIndexRetail].validation = ''
            updatedBulkValidationRetail[availableIndexRetail].borderValidation = ''
          }
          if (name === 'retailExpiry' && value == '') {
            customMasterArr[bulkId]['retailExpiry'] = 0
          }
        } else {
          customMasterArr[bulkId][name] = value
          if (name === 'wholesalePrice') {
            customMasterArr[bulkId]['wholesaleExpiry'] = value
            updatedBulkValidationWholesale[availableIndexWholesale].validation = ''
            updatedBulkValidationWholesale[availableIndexWholesale].borderValidation = ''
          }
          if (name === 'wholesaleExpiry' && value == '') {
            customMasterArr[bulkId]['wholesaleExpiry'] = 0
          }
          if (name === 'retailPrice') {
            customMasterArr[bulkId]['retailExpiry'] = value
            updatedBulkValidationRetail[availableIndexRetail].validation = ''
            updatedBulkValidationRetail[availableIndexRetail].borderValidation = ''
          }
          if (name === 'retailExpiry' && value == '') {
            customMasterArr[bulkId]['retailExpiry'] = 0
          }
        }
      }
      setMasterArray(customMasterArr)
    } catch (error) { }
  }

  const clearFunction = () => {
    setMessageTitle('')
    setGroupsOffset('')
    setCategoryOffset('')
    setbrandInOffset('')
    setMasterArray([])
    setDateShow(moment().format(GLOBAL_DATE_FORMAT))
    setInitialSettings((pre: any) => ({ ...pre, startDate: moment() }))
    setDate(moment(new Date()).format('YYYY-MM-DD'))
    setDateShowInput(moment(new Date()).format('DD-MM-YYYY'))
    setBulkValidationRetail([])
    setBulkValidationWholesale([])

    // setFilterBrandModal([{ value: "", label: "All Brands" }])
    // setFilterCategoryModal([{ value: "", label: "All Categories" }])
    // setFilterGroupsModal([{ value: "", label: "All Groups" }])

    $('#tableId')
      .find('input[type=text]')
      .each(function () {
        var defaultVal = $(this).data('default')
        $(this).val(defaultVal)
      })
  }

  // bulk require filed Validation
  const bulkValidation = () => {
    let isValid: boolean = true

    masterArray.map((item: any, index: any) => {
      const availableIndexRetail = bulkValidationRetail.findIndex((retail: any) => {
        return retail && retail.product_id == item.id
      })

      const updatedBulkValidationRetail: any = [...bulkValidationRetail]

      const availableIndexWholesale = bulkValidationWholesale.findIndex((wholesale: any) => {
        return wholesale && wholesale.product_id == item.id
      })

      const updatedBulkValidationWholesale: any = [...bulkValidationWholesale]

      if (item.wholesalePrice && availableIndexRetail > -1) {
        if (!item.retailPrice) {
          isValid = false
          updatedBulkValidationRetail[availableIndexRetail].validation = 'is-invalid'
          updatedBulkValidationRetail[availableIndexRetail].borderValidation = 'is-invalid-grp'
        } else {
          updatedBulkValidationRetail[availableIndexRetail].validation = ''
          updatedBulkValidationRetail[availableIndexRetail].borderValidation = ''
        }
        setBulkValidationRetail(updatedBulkValidationRetail)
      }
      if (item.retailPrice && availableIndexWholesale > -1) {
        if (!item.wholesalePrice) {
          isValid = false
          updatedBulkValidationWholesale[availableIndexWholesale].validation = 'is-invalid'
          updatedBulkValidationWholesale[availableIndexWholesale].borderValidation =
            'is-invalid-grp'
        } else {
          updatedBulkValidationWholesale[availableIndexWholesale].validation = ''
          updatedBulkValidationWholesale[availableIndexWholesale].borderValidation = ''
        }
        setBulkValidationWholesale(updatedBulkValidationWholesale)
      }
    })
    return isValid
  }

  //bulkEdit
  const updateBulkPrice = () => {
    const isValid: any = bulkValidation()

    if (isValid) {
      setIsLoading('Please Wait...')
      let customMasterArr = masterArray
      let masterData: any = []

      if (existingPrices.length === 0) {
        masterArray.forEach((element: any) => {
          if (
            element.wholesalePrice !== '' &&
            element.wholesaleExpiry !== '' &&
            element.retailPrice !== '' &&
            element.retailExpiry !== ''
          ) {
            if (element.checkedValue === 0) {
              masterData.push({
                product_id: element.id,
                warehouse_id: getW_Id,
                effective_date: date,
                wholesale_price: element.wholesalePrice ? element.wholesalePrice : '',
                wholesale_expiry_price: element.wholesaleExpiry ? element.wholesaleExpiry : '0',
                retail_price: element.retailPrice ? element.retailPrice : '',
                retail_expiry_price: element.retailExpiry ? element.retailExpiry : '0',
                action_by: 'Eafruits',
              })
            } else {
              getListModal.find((item: any) => {
                if (item.product_sku_price.length !== 0) {
                  if (item.product_sku_price[0].id == element.id) {
                    masterData.push({
                      id: element.id,
                      product_id: element.productId,
                      warehouse_id: getW_Id,
                      effective_date: date,
                      wholesale_price: element.wholesalePrice
                        ? element.wholesalePrice
                        : item.product_sku_price[0].wholesale_price,
                      wholesale_expiry_price: element.wholesaleExpiry
                        ? element.wholesaleExpiry
                        : item.product_sku_price[0].wholesale_expiry_price,
                      retail_price: element.retailPrice
                        ? element.retailPrice
                        : item.product_sku_price[0].retail_price,
                      retail_expiry_price: element.retailExpiry
                        ? element.retailExpiry
                        : item.product_sku_price[0].retail_expiry_price,
                      action_by: 'Eafruits',
                    })
                  }
                }
              })
            }
          }
        })
      } else {
        masterData = [...existingPrices]

        masterData.map((data: any, index: number) => {
          masterData[index].effective_date = date
        })
      }

      bulkPriceCreateUpdate(masterData).then((res) => {
        // clearDropDown()
        setDisableBtn(false)
        setIsLoading('Submit')

        $('#tableId')
          .find('input[type=number]')
          .each(function () {
            var defaultVal = $(this).data('default')
            $(this).val(defaultVal)
          })

        if (res.success) {
          setIsAlertSuccess(true)
          setListLoader(true)
          // ManagePriceList( limit, '0', search, brandValue, categoryValue, groupsValue, futureDate).then((res) => {
            ManagePriceList( limit, currentOffset, search, filterBrand.value, filterCategory.value, filterGroups.value, futureDate).then((res) => {
            setGetList(res.data)
            // setGetSize(res.data)
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            if (res.data.length == 0) {
              setLength('2')
            } else {
              setLength('1')
            }
            setListLoader(false)
            setSelected(0)
          })
        }
        if (res.failed) {
          setIsAlertFailed(true)
        }
        $('#closeBulModal').trigger('click')
        setTimeout(() => {
          setIsAlertFailed(false)
          setIsAlertSuccess(false)
        }, 1500)
      })
    }
  }

  const filterSearchBrand = (val: any, key: any) => {
    let label = ''
    if (val.label === 'All Brands') {
      label = ''
    } else {
      label = val.value
    }

    if (key === 'MODAL') {
      setBrandValueModal(label)
      setFilterBrandModal(val)
      setListLoader(true)
      ManageAllPriceList(label, categoryValueModal, groupsValueModal, futureDateBulk).then(
        (res) => {
          setGetListNodal(res)
          if (res.length == 0) {
            setLengthModal('2')
          } else {
            setLengthModal('1')
          }
      setListLoader(false)
        }
      )
    }

    if (key === 'LIST') {
      setFilterBrand(val)
      setBrandValue(label)
      setListLoader(true)
      setFilterStates({ ...filterStates, ['filterBrand']: val })
      setButtonDisable(true)
      setCurrentOffset(0)
      setSelected(0)
      // ManagePriceList('10', '0', search, label, categoryValue, groupsValue, futureDate).then(
      //   (res) => {
      //     setGetList(res.data)
      //     setCount(res.count)
      //     setNext(res.next)
      //     setPrev(res.prev)
      //     setSelected(0)
      //     if (res.data.length == 0) {
      //       setLength('2')
      //     } else {
      //       setLength('1')
      //     }
      // setListLoader(false)
      //   }
      // )
    }
  }

  const filterSearchCategory = (val: any, key: any) => {
    let label = ''
    if (val.label === 'All Categories') {
      label = ''
    } else {
      label = val.value
    }

    if (key === 'MODAL') {
      setFilterCategoryModal(val)
      setCategoryValueModal(label)
      setListLoader(true)
      ManageAllPriceList(brandValueModal, label, groupsValueModal, futureDateBulk).then((res) => {
        setGetListNodal(res)
        if (res.length == 0) {
          setLengthModal('2')
        } else {
          setLengthModal('1')
        }
      setListLoader(false)
      })
    }

    if (key === 'LIST') {
      setFilterCategory(val)
      setCategoryValue(label)
      setListLoader(true)
      setCurrentOffset(0)
      setSelected(0)
      setFilterStates({ ...filterStates, ['filterCategory']: val })
      setButtonDisable(true)
      // ManagePriceList('10', '0', search, brandValue, label, groupsValue, futureDate).then((res) => {
      //   setGetList(res.data)
      //   setCount(res.count)
      //   setNext(res.next)
      //   setPrev(res.prev)
      //   setSelected(0)
      //   if (res.data.length == 0) {
      //     setLength('2')
      //   } else {
      //     setLength('1')
      //   }
      // setListLoader(false)
      // })
    }
  }

  const filterSearchGroups = (val: any, key: any) => {
    let label = ''
    if (val.label === 'All Groups') {
      label = ''
    } else {
      label = val.value
    }

    if (key === 'MODAL') {
      setFilterGroupsModal(val)
      setGroupsValueModal(label)
      setListLoader(true)
      ManageAllPriceList(brandValueModal, categoryValueModal, label, futureDateBulk).then((res) => {
        setGetListNodal(res)
        if (res.length == 0) {
          setLengthModal('2')
        } else {
          setLengthModal('1')
        }
      setListLoader(false)
      })
    }

    if (key === 'LIST') {
      setFilterGroups(val)
      setGroupsValue(label)
      setListLoader(true)
      setCurrentOffset(0)
      setSelected(0)
      setFilterStates({ ...filterStates, ['filterGroups']: val })
      setButtonDisable(true)
      // ManagePriceList('10', '0', search, brandValue, categoryValue, label, futureDate).then(
      //   (res) => {
      //     setGetList(res.data)
      //     setCount(res.count)
      //     setNext(res.next)
      //     setPrev(res.prev)
      //     setSelected(0)
      //     if (res.data.length == 0) {
      //       setLength('2')
      //     } else {
      //       setLength('1')
      //     }
      // setListLoader(false)
      //   }
      // )
    }
  }

  const onApply = () => {
    // $(`#datePickerClose`).blur()
    removeBlur()
  }

  const onCancel = () => {
    $(`#datePickerClose`).blur()
  }

  // success/failed message function
  const isAlertSHow = (val: any) => {
    if (val == 'success') {
      setIsAlertSuccess(false)
    }
    if (val == 'failed') {
      setIsAlertFailed(false)
    }
  }

  //single edit
  const editManagePrice = async () => {
    let isValid: boolean = true

    if (priceValue.wholesalePriceSingle === '') {
      isValid = false
      setValidation((preValue: any) => ({ ...preValue, wholesalePriceSingle: 'is-invalid' }))
    } else {
      setValidation((preValue: any) => ({ ...preValue, wholesalePriceSingle: '' }))
    }
    if (
      //   priceValue.wholesaleExpirySingle === '' ||
      priceValue.wholesaleExpirySingle > priceValue.wholesalePriceSingle
    ) {
      isValid = false
      setValidation((preValue: any) => ({ ...preValue, wholesaleExpirySingle: 'is-invalid' }))
    } else {
      setValidation((preValue: any) => ({ ...preValue, wholesaleExpirySingle: '' }))
    }
    if (priceValue.retailPriceSingle === '') {
      isValid = false
      setValidation((preValue: any) => ({ ...preValue, retailPriceSingle: 'is-invalid' }))
    } else {
      setValidation((preValue: any) => ({ ...preValue, retailPriceSingle: '' }))
    }
    if (
      //   priceValue.retailExpirySingle === '' ||
      priceValue.retailExpirySingle > priceValue.retailPriceSingle
    ) {
      isValid = false
      setValidation((preValue: any) => ({ ...preValue, retailExpirySingle: 'is-invalid' }))
    } else {
      setValidation((preValue: any) => ({ ...preValue, retailExpirySingle: '' }))
    }

    if (!isValid) {
      return false
    }

    if (
      priceValue.wholesalePriceSingle !== '' &&
      //   priceValue.wholesaleExpirySingle !== '' &&
      priceValue.retailPriceSingle !== ''
      //   priceValue.retailExpirySingle !== ''
    ) {
      setDisableBtn(true)
      setIsLoading('Please Wait...')
      const { id, product_id, action_by, warehouse_id } = priceValue.priceItem
      let masterData: any = []

      masterData.push({
        id: id,
        product_id: product_id === undefined ? priceValue.productId : product_id,
        warehouse_id: getW_Id,
        effective_date: dateShowSingle.dateApi,
        wholesale_price: priceValue.wholesalePriceSingle,
        wholesale_expiry_price: priceValue.wholesaleExpirySingle,
        retail_price: priceValue.retailPriceSingle,
        retail_expiry_price: priceValue.retailExpirySingle,
        action_by: action_by,
        show_future_price: futureDate,
      })

      bulkPriceCreateUpdate(masterData).then((res) => {
        // clearDropDown()
        setDisableBtn(false)
        setIsLoading('Submit')

        if (res.success) {
          setIsAlertSuccess(true)
          ManageAllPriceList('', '', '', futureDateBulk).then((res) => {
            setGetListNodal(res)
          })
          setListLoader(true)
          ManagePriceList( limit, currentOffset, search, filterBrand.value, filterCategory.value, filterGroups.value, futureDate).then((res) => {
          // ManagePriceList(limit, currentOffset, search, brandValue, categoryValue,  groupsValue, futureDate).then((res) => {
            setGetList(res.data)
            setCount(res.count)
            setNext(res.next)
            setPrev(res.prev)
            setListLoader(false)
          })
        }
        if (res.failed) {
          setIsAlertFailed(true)
        }
        $('#closeSingleModal').trigger('click')
        setTimeout(() => {
          setIsAlertFailed(false)
          setIsAlertSuccess(false)
        }, 1500)
      })
    }
  }

  const clearSingleModal = () => {
    setInitialSettings((pre: any) => ({ ...pre, startDate: moment() }))
    setValidation({
      retailExpirySingle: '',
      retailPriceSingle: '',
      wholesaleExpirySingle: '',
      wholesalePriceSingle: '',
      date: '',
    })
  }

  const handleBlur = (e: any) => {
    const { value, name } = e.target

    if (name === 'wholesaleExpirySingle') {
      if (value === '') {
        setPriceValue((prevState: any) => ({
          ...prevState,
          ['wholesaleExpirySingle']: priceValue.wholesalePriceSingle,
        }))
        setValidation({ ...validation, ['wholesaleExpirySingle']: '' })
      }
    }

    if (name === 'retailExpirySingle') {
      if (value === '') {
        setPriceValue((prevState: any) => ({
          ...prevState,
          ['retailExpirySingle']: priceValue.retailPriceSingle,
        }))
        setValidation({ ...validation, ['retailExpirySingle']: '' })
      }
    }
  }

  const singleBulkChange = (e: any) => {
    const { value, name } = e.target
    setPriceValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))

    if (name === 'wholesalePriceSingle') {
      setPriceValue((prevState: any) => ({
        ...prevState,
        ['wholesaleExpirySingle']: value,
      }))

      setValidation({ ...validation, ['wholesaleExpirySingle']: '' })
    }

    if (name === 'wholesaleExpirySingle') {
      if (parseInt(value) > parseInt(priceValue.wholesalePriceSingle)) {
        setValidation((preValue: any) => ({
          ...preValue,
          wholesaleExpirySingle: 'is-invalid',
        }))
      } else {
        setValidation((preValue: any) => ({
          ...preValue,
          wholesaleExpirySingle: '',
        }))
      }
    }

    if (name === 'retailPriceSingle') {
      setPriceValue((prevState: any) => ({
        ...prevState,
        ['retailExpirySingle']: value,
      }))

      setValidation({ ...validation, ['retailExpirySingle']: '' })
    }

    if (name === 'retailExpirySingle') {
      if (parseInt(value) > parseInt(priceValue.retailPriceSingle)) {
        setValidation((preValue: any) => ({
          ...preValue,
          retailExpirySingle: 'is-invalid',
        }))
      } else {
        setValidation((preValue: any) => ({
          ...preValue,
          retailExpirySingle: '',
        }))
      }
    }

    // (value).toLocaleString(undefined, { maximumFractionDigits: 2 })
  }

  const [initialSettings, setInitialSettings] = useState({
    startDate: moment(),
    alwaysShowCalendars: true,
    cancelClass: 'btn-secondary',
    singleDatePicker: true,
    locale: {
      format: GLOBAL_DATE_FORMAT,
    },
    drops: 'up',
    showDropdowns: true,
  })

  const CustomDatePicker = () => {
    return (
      <DatePickerCustom
        onCallback={(start: any, end: any, label: any) => {
          // removeBlur()
          let date = start._d
          let newDate = dateFormat(start._d)
          setDate(newDate.apiDate)
          setDateShow(newDate.displayDate)

          // setDate(dateApi)
          //             setDateShow(dateView)
          //             setDateShowInput(dateView)

          setInitialSettings((pre: any) => ({ ...pre, startDate: start._d }))
          onApply()
        }}
        initialSettings={initialSettings}
        dateValidation={'w-150px'}
        dateValue={dateShow}
      />
    )
  }

  const CustomDatePickerSingle = () => {
    return (
      <DatePickerCustom
        onCallback={(start: any, end: any, label: any) => {
          let date = start._d
          let newDate = dateFormat(start._d)
          setDateShowSingle({
            ['dateApi']: newDate.apiDate,
            ['dateView']: newDate.displayDate,
            ['setDateShowInput']: newDate.displayDate,
          })
          setInitialSettings((pre: any) => ({ ...pre, startDate: start._d }))
          // onApply()
        }}
        initialSettings={initialSettings}
        dateValidation={clsx('w-150px', validation.date)}
        dateValue={dateShowSingle.setDateShowInput}
      />
    )
  }

  const handleUnit = (item: any) => {
    let itemUnit = ''
    if (item.fix_size) {
      itemUnit = item.material.name
    } else {
      itemUnit = item.measurement_unit
    }
    setUnit(measurementLabel(itemUnit))
  }

  const handleFuturePrice = (e: any) => {

    if (e.target.checked) {
      setFutureDate(true)
      setFilterStates({ ...filterStates, ['futureDate']: true })
      setButtonDisable(true)
    } else {
      setFutureDate(false)
      setFilterStates({ ...filterStates, ['futureDate']: false })
    }

    setListLoader(true)
    ManagePriceList( limit, currentOffset, search, filterBrand.value, filterCategory.value, filterGroups.value, e.target.checked).then((res) => {
    // ManagePriceList(limit,'0', search, brandValue,  categoryValue, groupsValue, e.target.checked).then((res) => {
      setGetList(res.data)

      // setGetSize(res.data)
      setCount(res.count)
      setNext(res.next)
      setPrev(res.prev)
      if (res.data.length == 0) {
        setLength('2')
      } else {
        setLength('1')
      }
      setListLoader(false)
    })
  }

  const handleFuturePriceBulk = (e: any) => {
    // bulk
    if (e.target.checked) {
      setFutureDateBulk(true)
    } else {
      setFutureDateBulk(false)
    }

    ManageAllPriceList(
      brandValueModal,
      categoryValueModal,
      groupsValueModal,
      e.target.checked
    ).then((res) => {
      setGetListNodal(res)
      if (res.length == 0) {
        setLengthModal('2')
      } else {
        setLengthModal('1')
      }
    })
  }

  const valueFromMasterArray = (itemId: any, type: string) => {
    let value: any = ''

    if (masterArray.length > 0) {
      const index = masterArray.findIndex((master: any) => {
        return master && master.productId == itemId
      })

      if (index !== -1) {
        switch (type) {
          case 'wholesalePrice':
            value = masterArray[index] ? masterArray[index].wholesalePrice : ''
            break
          case 'wholesaleExpiry':
            value = masterArray[index] ? masterArray[index].wholesaleExpiry : ''
            break
          case 'retailPrice':
            value = masterArray[index] ? masterArray[index].retailPrice : ''
            break
          case 'retailExpiry':
            value = masterArray[index] ? masterArray[index].retailExpiry : ''
            break
        }
      }
    }

    return value
  }
  const handleChangeReset = () =>{
    setSearch('')
    setFilterBrand({label : 'All Brands' , value : ''})
    setFilterCategory({label : 'All Categories' , value : ''})
    setFilterGroups({label : 'All Product Groups' , value : ''})
    setCurrentOffset(0)
    setSelected(0)
    setFutureDate(false)
    let updatedState: any = { ...filterStates }
    updatedState['filterBrand'] = ''
    updatedState['filterCategory'] = ''
    updatedState['filterGroups'] = ''
    updatedState['futureDate'] = false
    updatedState['search'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    setFilterStates(updatedState)
    setButtonDisable(false)
}

  return (
    <>
      <EATitle title='Manage Price' />
      <div>
        <PageTitle breadcrumbs={[priceHeaderTitle]}>
          {intl.formatMessage({ id: 'Manage Price' })}
        </PageTitle>
        <EaAlertSuccess
          onClick={() => isAlertSHow('success')}
          show={IsAlertSuccess}
          // message="success"
          message={
            messageHandler === 'successEdit'
              ? FormatMessage('MANAGE.PRICE.UPDATE', messageTitle)
              : FormatMessage('MANAGE.PRICE.UPDATE.BULK')
          }
        />
        <EaAlertDanger
          onClick={() => isAlertSHow('failed')}
          show={isAlertFailed}
          message='failed'
        />

        <div className={`card ${className}`}>
        {listLoadder && <IsLoader />}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            <CustomSearchBar onChange={searchList} value={search} isDisable={listLoadder} />
            <div className='ms-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form
                className='card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                <div className='form-check form-check-sm form-check-custom form-check-solid my-1 me-4'>
                  <input
                    className='form-check-input widget-13-check me-2'
                    type='checkbox'
                    value={futureDate}
                    id='allrequestList'
                    checked={futureDate}
                    name='all'
                    onChange={handleFuturePrice}
                  />
                  <label className='fw-500' htmlFor='allrequestList'>
                    Show Future Price
                  </label>
                </div>
                <AsyncPaginate
                    
                  loadOptions={fetchBrand}
                  isSearchable
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  onChange={(val: any) => filterSearchBrand(val, 'LIST')}
                  isDisabled={listLoadder}
                  placeholder='All Brands'
                  value={filterBrand}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                    
                  loadOptions={fetchCategory}
                  isSearchable
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  styles={categoryStyle}
                  onChange={(val: any) => filterSearchCategory(val, 'LIST')}
                  isDisabled={listLoadder}
                  placeholder='All Categories'
                  value={filterCategory}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                    
                  loadOptions={fetchProductsGroups}
                  isSearchable
                  className='react-select-container my-1 me-3 w-170px'
                  classNamePrefix='react-select'
                  onChange={(val: any) => filterSearchGroups(val, 'LIST')}
                  isDisabled={listLoadder}
                  placeholder='All Product Groups'
                  value={filterGroups}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {permission.write &&
                 <div className="me-3"> 
                 <EaButton
                    onClick={() => {
                      setMessageHandler('successBulkEdit')
                      setGroupsOffset('')
                      setCategoryOffset('')
                      setbrandInOffset('')
                      setGroupsOption('')
                      setBrandOption('')
                      setCategoryOption('')
                      ManageAllPriceList(
                        brandValueModal,
                        categoryValueModal,
                        groupsValueModal,
                        futureDateBulk
                      ).then((res) => {
                        setGetListNodal(res)
                        if (res.length == 0) {
                          setLengthModal('2')
                        } else {
                          setLengthModal('1')
                        }
                      })
                    }}
                    isModal={true}
                    dataTarget='#ea_modal_edit_product'
                    btnName='Bulk Edit'
                  />
                 </div>
                 }
                <ResetButton onClick = {handleChangeReset} isDisable={buttonDisable}/>
                {/* </div> */}
              </form>
            </div>
          </div>
          <div className='card-body py-4'>
            <div className='table-responsive'>
            {getList.length === 0 ? (
                    <NoRecords />
                  ) : (
                    <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='text-muted fw-500'>
                          <th className='min-w-150px'>Product SKUs</th>
                          <th className='min-w-140px'>Effective From Date</th>
                          <th className='min-w-120px'>Wholesale Price</th>
                          <th className='min-w-120px'>Retail Price</th>
                          <th className='min-w-80px action-th py-2'>{permission.write && "Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getList.map((item: any) => (
                          <tr>
                            <SkuThumbnail skuName={item.name} src={item.product_thumbnail} />
                            <td>
                              {item.product_sku_price.length !== 0
                                ? moment(item.product_sku_price[0].effective_date).format(
                                  GLOBAL_DATE_FORMAT
                                )
                                : '-'}
                            </td>
                            <td>
                              {item.product_sku_price.length !== 0
                                ? `${tenantConfiguration.currencyCode}` +
                                ' ' +
                                // item.product_sku_price[0].wholesale_price.toLocaleString(
                                //   undefined,
                                //   {maximumFractionDigits: 2}
                                // )
                                `${numberFormat(item.product_sku_price[0].wholesale_price)
                                  .displayFormat
                                }`
                                : '-'}
                            </td>
                            <td>
                              {item.product_sku_price.length !== 0
                                ? `${tenantConfiguration.currencyCode}` +
                                ' ' +
                                // item.product_sku_price[0].retail_price.toLocaleString(undefined, {
                                //   maximumFractionDigits: 2,
                                // })
                                `${numberFormat(item.product_sku_price[0].retail_price)
                                  .displayFormat
                                }`
                                : '-'}
                            </td>
                            <td className='text-end'>
                              {permission.write &&
                                <a
                                  href='#'
                                  data-bs-toggle='modal'
                                  data-bs-target='#ea_modal_price_edit'
                                  onClick={() => {
                                    handleUnit(item)
                                    setMessageTitle(item.name)
                                    setMessageHandler('successEdit')
                                    let dateNew: any =
                                      item.product_sku_price.length !== 0 &&
                                      dateFormat(item.product_sku_price[0].effective_date)
                                    item.product_sku_price.length !== 0 &&
                                      setInitialSettings((pre: any) => ({
                                        ...pre,
                                        startDate: dateNew.displayDate,
                                      }))
                                    let value =
                                      item.product_sku_price.length !== 0
                                        ? item.product_sku_price[0]
                                        : ''
                                    setDateShowSingle({
                                      ['dateApi']:
                                        item.product_sku_price.length !== 0
                                          ? moment(item.product_sku_price[0].effective_date).format(
                                            'YYYY-MM-DD'
                                          )
                                          : moment(new Date()).format('YYYY-MM-DD'),
                                      ['dateView']:
                                        item.product_sku_price.length !== 0 ? new Date() : new Date(),
                                      ['setDateShowInput']:
                                        item.product_sku_price.length !== 0
                                          ? moment(item.product_sku_price[0].effective_date).format(
                                            GLOBAL_DATE_FORMAT
                                          )
                                          : 'Select Date',
                                    })

                                    setPriceValue({
                                      ['priceItem']: value,
                                      ['productId']: item.id,
                                      ['name']: item.name,
                                      ['wholesalePriceSingle']:
                                        item.product_sku_price.length !== 0
                                          ? item.product_sku_price[0].wholesale_price
                                          : '',
                                      ['wholesaleExpirySingle']:
                                        item.product_sku_price.length !== 0
                                          ? item.product_sku_price[0].wholesale_expiry_price
                                          : '',
                                      ['retailPriceSingle']:
                                        item.product_sku_price.length !== 0
                                          ? item.product_sku_price[0].retail_price
                                          : '',
                                      ['retailExpirySingle']:
                                        item.product_sku_price.length !== 0
                                          ? item.product_sku_price[0].retail_expiry_price
                                          : '',
                                      ['effectiveDate']:
                                        item.product_sku_price.length !== 0
                                          ? item.product_sku_price[0].effective_date
                                          : '',
                                    })
                                  }}
                                  className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm me-3'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='ic mr-0'
                                  />
                                </a>}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
            </div>
          </div>
        </div>
         <CustomPaginate
          data={getList}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          saveFilterName={filters.wm_manage_price}
          filterStates={filterStates}
          isDisabled={listLoadder}
        />

        {/* update modal */}
        <div
          className='modal fade'
          id='ea_modal_edit_product'
          aria-hidden='true'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
        >
          <div className='modal-dialog modal-dialog-centered mw-470px modal-fullscreen'>
            <div className='modal-content'>
              <div className='modal-header py-2'>
                {/*Bulk update*/}
                <h2 className='modal-title fw-bold'>Update Price</h2>
                <div className='ms-auto d-flex align-items-center'>
                  <div className='d-flex'>
                    <AsyncPaginate
                    
                      loadOptions={fetchBrand}
                      isSearchable
                      className='react-select-container my-1 me-3 w-120px'
                      classNamePrefix='react-select'
                      onChange={(val: any) => filterSearchBrand(val, 'MODAL')}
                      placeholder='All Brands'
                      value={filterBrandModal}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                    <AsyncPaginate
                    
                      loadOptions={fetchCategory}
                      isSearchable
                      className='react-select-container my-1 me-3 w-160px'
                      classNamePrefix='react-select'
                      styles={categoryStyle}
                      onChange={(val: any) => filterSearchCategory(val, 'MODAL')}
                      placeholder='All Categories'
                      value={filterCategoryModal}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                    <AsyncPaginate
                    
                      loadOptions={fetchProductsGroups}
                      isSearchable
                      className='react-select-container my-1 me-3 w-170px'
                      classNamePrefix='react-select'
                      onChange={(val: any) => filterSearchGroups(val, 'MODAL')}
                      placeholder='All Product Groups'
                      value={filterGroupsModal}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0c78a4',
                        },
                      })}
                    />
                  </div>
                  <div
                    onClick={clearFunction}
                    className='btn p-1 mh-24 btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
                  </div>
                </div>
              </div>
              <div className='modal-body py-3'>
                {getLengthModal !== '1' ? (
                  <div className='mt-5'>
                    <NoRecords />
                  </div>
                ) : (
                  <table
                    id='tableId'
                    className='table table-borderless gx-2 table-row-gray-100 align-middle gs-0 gy-3'
                  >
                    <thead>
                      <tr className='text-muted fw-500'>
                        <th className='min-w-250px' style={{ width: '42%' }}>
                          Product SKUs
                        </th>
                        <th className='min-w-150px w-auto'>Wholesale Price</th>
                        <th className='min-w-150px w-auto'>Near Expiry</th>
                        <th className='min-w-150px w-auto'>Retail Price</th>
                        <th className='min-w-150px w-auto'>Near Expiry</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getListModal.map((item: any, index: number) => {
                        let itemUnit = ''
                        if (item.fix_size) {
                          itemUnit = item.material.name
                        } else {
                          itemUnit = item.measurement_unit
                        }
                        return (
                          <tr>
                            <td>
                              <input
                                type='text'
                                className='form-control '
                                value={item.name}
                                disabled
                              />
                            </td>
                            <td>
                              <div
                                className={clsx(
                                  'input-group',
                                  bulkValidationWholesale[index] &&
                                  bulkValidationWholesale[index].borderValidation,
                                  '-grp'
                                )}
                              >
                                <span className='input-group-text bg-white border-end-0'>
                                  {tenantConfiguration.currencyCode}
                                </span>
                                <input
                                  type='number'
                                  name='wholesalePrice'
                                  onChange={(e) => getBulkValue(e, item, index)}
                                  id={
                                    item.product_sku_price.length === 0
                                      ? item.id
                                      : item.product_sku_price[0].id
                                  }
                                  className={clsx(
                                    'form-control border-start-0 ps-0 border-focus-light',
                                    bulkValidationWholesale[index] &&
                                    bulkValidationWholesale[index].validation
                                  )}
                                  value={
                                    masterArray.length > 0 &&
                                    valueFromMasterArray(item.id, 'wholesalePrice')
                                  }
                                  placeholder={
                                    item.product_sku_price.length !== 0
                                      ? item.product_sku_price[0].wholesale_price.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 2 }
                                      )
                                      : '0.00'
                                  }
                                />
                                <span className='input-group-text min-w-80px border-start ms-0'>
                                  /{measurementLabel(itemUnit)}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className='input-group'>
                                <span className='input-group-text bg-white border-end-0'>
                                  {tenantConfiguration.currencyCode}
                                </span>
                                <input
                                  type='number'
                                  name='wholesaleExpiry'
                                  onChange={(e) => getBulkValue(e, item)}
                                  id={
                                    item.product_sku_price.length === 0
                                      ? item.id
                                      : item.product_sku_price[0].id
                                  }
                                  className='form-control border-start-0 ps-0 border-focus-light'
                                  placeholder={
                                    item.product_sku_price.length !== 0
                                      ? item.product_sku_price[0].wholesale_expiry_price.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 2 }
                                      )
                                      : '0.00'
                                  }
                                  value={
                                    masterArray.length > 0 &&
                                    valueFromMasterArray(item.id, 'wholesaleExpiry')
                                  }
                                />
                                <span className='input-group-text min-w-80px border-start ms-0'>
                                  /{measurementLabel(itemUnit)}
                                </span>
                              </div>
                            </td>
                            <td>
                              {/* <div className='input-group'> */}
                              <div
                                className={clsx(
                                  'input-group',
                                  bulkValidationRetail[index] &&
                                  bulkValidationRetail[index].borderValidation,
                                  '-grp'
                                )}
                              >
                                <span className='input-group-text bg-white border-end-0'>
                                  {tenantConfiguration.currencyCode}
                                </span>
                                <input
                                  type='number'
                                  name='retailPrice'
                                  onChange={(e) => getBulkValue(e, item, index)}
                                  id={
                                    item.product_sku_price.length === 0
                                      ? item.id
                                      : item.product_sku_price[0].id
                                  }
                                  className={clsx(
                                    'form-control border-start-0 ps-0 border-focus-light',
                                    bulkValidationRetail[index] &&
                                    bulkValidationRetail[index].validation
                                  )}
                                  placeholder={
                                    item.product_sku_price.length !== 0
                                      ? item.product_sku_price[0].retail_price.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 2 }
                                      )
                                      : '0.00'
                                  }
                                  value={
                                    masterArray.length > 0 &&
                                    valueFromMasterArray(item.id, 'retailPrice')
                                  }
                                />
                                <span className='input-group-text min-w-80px border-start ms-0'>
                                  /{measurementLabel(itemUnit)}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className='input-group'>
                                <span className='input-group-text bg-white border-end-0'>
                                  {tenantConfiguration.currencyCode}
                                </span>
                                <input
                                  type='number'
                                  name='retailExpiry'
                                  value={
                                    masterArray.length > 0 &&
                                    valueFromMasterArray(item.id, 'retailExpiry')
                                  }
                                  onChange={(e) => getBulkValue(e, item)}
                                  id={
                                    item.product_sku_price.length === 0
                                      ? item.id
                                      : item.product_sku_price[0].id
                                  }
                                  className='form-control border-start-0 ps-0 border-focus-light'
                                  placeholder={
                                    item.product_sku_price.length !== 0
                                      ? item.product_sku_price[0].retail_expiry_price.toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 2 }
                                      )
                                      : '0.00'
                                  }
                                />
                                <span className='input-group-text min-w-80px border-start ms-0'>
                                  /{measurementLabel(itemUnit)}
                                </span>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <div className='modal-footer justify-content-end'>
                <span>Effective From Date</span>
                {/* <DatePicker
								 startDate={dateShow}
								 validation='w-150px'
								 onCancel={onCancel}
								 onCallback={(dateView: any, dateApi: any) => {
								 setDate(dateApi)
								 setDateShow(dateView)
								 setDateShowInput(dateView)
								 }}
								 id='datePicker'
								 onApply={onApply}
								 dateValue={dateShowInput}
								 /> */}
                <CustomDatePicker />
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  onClick={clearFunction}
                  id='closeBulModal'
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={updateBulkPrice}
                  disabled={disableBtn}
                >
                  {isModalLoading}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* single edit */}
        <div
          className='modal fade'
          id='ea_modal_price_edit'
          aria-hidden='true'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
        >
          <div className='modal-dialog modal-dialog-centered mw-470px modal-xl'>
            <div className='modal-content'>
              <div className='modal-header py-2'>
                {/*Single Price Update*/}
                <h2 className='modal-title fw-bold'>Update Price</h2>
                <div
                  className='btn p-1 mh-24 btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  onClick={clearSingleModal}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
                </div>
              </div>
              <div className='modal-body py-3'>
                <table className='table table-borderless gx-2 table-row-gray-100 align-middle gs-0 gy-3 mb-1'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='text-muted fw-500'>
                      <th className='min-w-250px' style={{ width: '32%' }}>
                        Product SKUs
                      </th>
                      <th className='min-w-150px w-auto'>Wholesale Price</th>
                      <th className='min-w-150px w-auto'>Near Expiry</th>
                      <th className='min-w-150px w-auto'>Retail Price</th>
                      <th className='min-w-150px w-auto'>Near Expiry</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    <tr>
                      <td>
                        {/* <input type="text" className="form-control " value='Onja Rice 1kg' disabled /> */}
                        <input
                          type='text'
                          className={clsx('form-control')}
                          disabled
                          value={priceValue.name}
                        />
                      </td>
                      <td>
                        <div
                          className={clsx(
                            'input-group',
                            validation.wholesalePriceSingle === 'is-invalid' ? 'is-invalid-grp' : ''
                          )}
                        >
                          <span className='input-group-text bg-white border-end-0'>
                            {tenantConfiguration.currencyCode}
                          </span>
                          <input
                            type='number'
                            id='datePickerClose'
                            onChange={(e) => {
                              singleBulkChange(e)
                              const validation = ValidationHandler(e.target.value, 'is-invalid')
                              setValidation((preValue: any) => ({
                                ...preValue,
                                wholesalePriceSingle: validation,
                              }))
                            }}
                            value={priceValue.wholesalePriceSingle}
                            placeholder='0.00'
                            name='wholesalePriceSingle'
                            className={clsx(
                              'form-control border-start-0 ps-0 border-focus-light',
                              validation.wholesalePriceSingle
                            )}
                          />
                          <span className='input-group-text min-w-80px border-start ms-0'>
                            /{unit}
                          </span>
                        </div>
                        {/* <div className="input-group">
											 <span className="input-group-text bg-white border-end-0">TZS</span>
											 <input type="text" className="form-control border-start-0 ps-0 border-focus-light" placeholder='0.00' />
											 </div> */}
                      </td>
                      <td>
                        {/* <div className="input-group">
											 <span className="input-group-text bg-white border-end-0">TZS</span>
											 <input type="text" className="form-control border-start-0 ps-0 border-focus-light" placeholder='0.00' />
											 </div> */}
                        <div
                          className={clsx(
                            'input-group',
                            validation.wholesaleExpirySingle === 'is-invalid'
                              ? 'is-invalid-grp'
                              : ''
                          )}
                        >
                          <span className='input-group-text bg-white border-end-0'>
                            {tenantConfiguration.currencyCode}
                          </span>
                          <input
                            type='number'
                            onChange={singleBulkChange}
                            onBlur={handleBlur}
                            // value={(priceValue.wholesaleExpirySingle).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            value={priceValue.wholesaleExpirySingle}
                            name='wholesaleExpirySingle'
                            placeholder='0.00'
                            className={clsx(
                              'form-control border-start-0 ps-0 border-focus-light',
                              ''
                              //   validation.wholesaleExpirySingle
                            )}
                          />
                          <span className='input-group-text min-w-80px border-start ms-0'>
                            /{unit}
                          </span>
                        </div>
                      </td>
                      <td>
                        {/* <div className="input-group">
											 <span className="input-group-text bg-white border-end-0">TZS</span>
											 <input type="text" className="form-control border-start-0 ps-0 border-focus-light" placeholder='0.00' />
											 </div> */}
                        <div
                          className={clsx(
                            'input-group',
                            validation.retailPriceSingle === 'is-invalid' ? 'is-invalid-grp' : ''
                          )}
                        >
                          <span className='input-group-text bg-white border-end-0'>
                            {tenantConfiguration.currencyCode}
                          </span>
                          <input
                            type='number'
                            onChange={(e) => {
                              singleBulkChange(e)
                              const validation = ValidationHandler(e.target.value, 'is-invalid')
                              setValidation((preValue: any) => ({
                                ...preValue,
                                retailPriceSingle: validation,
                              }))
                            }}
                            // value={(priceValue.retailPriceSingle).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            value={priceValue.retailPriceSingle}
                            name='retailPriceSingle'
                            placeholder='0.00'
                            className={clsx(
                              'form-control border-start-0 ps-0 border-focus-light',
                              validation.retailPriceSingle
                            )}
                          />
                          <span className='input-group-text min-w-80px border-start ms-0'>
                            /{unit}
                          </span>
                        </div>
                      </td>
                      <td>
                        {/* <div className="input-group">
											 <span className="input-group-text bg-white border-end-0">TZS</span>
											 <input type="text" className="form-control border-start-0 ps-0 border-focus-light" placeholder='0.00' />
											 </div> */}
                        <div
                          className={clsx(
                            'input-group',
                            validation.retailExpirySingle === 'is-invalid' ? 'is-invalid-grp' : ''
                          )}
                        >
                          <span className='input-group-text bg-white border-end-0'>
                            {tenantConfiguration.currencyCode}
                          </span>
                          <input
                            type='number'
                            onChange={singleBulkChange}
                            onBlur={handleBlur}
                            // value={(priceValue.retailExpirySingle).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            value={priceValue.retailExpirySingle}
                            placeholder='0.00'
                            name='retailExpirySingle'
                            className={clsx(
                              'form-control border-start-0 ps-0 border-focus-light',
                              ''
                              //   validation.retailExpirySingle
                            )}
                          />
                          <span className='input-group-text min-w-80px border-start ms-0'>
                            /{unit}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  {/* end::Table body */}
                </table>
              </div>
              <div className='modal-footer justify-content-end'>
                <span>Effective From Date</span>
                <CustomDatePickerSingle />
                <button
                  type='button'
                  onClick={clearSingleModal}
                  id='closeSingleModal'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                >
                  {'Cancel'}
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={editManagePrice}
                  disabled={disableBtn}
                >
                  {isModalLoading}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManagePrice
