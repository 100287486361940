import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {checkPermissions} from '../../../helpers'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import usePermission from '../../../helpers/components/usePermission'
import asideMenuHandler from '../../../helpers/components/asideMenuHandler'

export function WMAsideMenuMain() {
  const intl = useIntl()

  const [isLoading, setIsLoading] = useState(true)

  const [showDashboard, setDashboard] = useState(false)
  const [showteam, setShowTeam] = useState(false)
  const [showCustomer, setShowCustomer] = useState(false)
  const [showSuspCustomer, setShowSuspCustomer] = useState(false)
  const [showVerCustomer, setShowVerCustomer] = useState(false)
  const [showProduct, setShowProduct] = useState(false)
  const [showPriceManage, setShowPriceManage] = useState(false)
  const [showPriceCapping, setShowPriceCapping] = useState(false)
  const [showPriceDiscount, setShowPriceDiscount] = useState(false)
  const [showVehical, setShowVehical] = useState(false)
  const [showLowSock, setShowLowSock] = useState(false)
  const [showBank, setShowBank] = useState(false)
  const [showKPIGeneral, setShowKPIGeneral] = useState(false)
  const [showReportSku, setShowReportSku] = useState(false)
  const [showReportSal, setShowReportSal] = useState(false)
  const [showReportCI, setShowReportCI] = useState(false)
  const [showReportTR, setShowReportTR] = useState(false)
  const [showReportVR, setShowReportVR] = useState(false)
  const [showReportCR, setShowReportCR] = useState(false)
  const [showReportRCB, setShowReportRCB] = useState(false)
  const [showReportRCR, setShowReportRCR] = useState(false)
  const [showReportROD, setShowReportROD] = useState(false)
  const [showReportRLR, setShowReportRLR] = useState(false)
  const [showOrderMenu, setShowOrderMenu] = useState<boolean>(false)
  const [showSalesInvoiceMenu, setShowSalesInvoiceMenu] = useState<boolean>(false)
  const [showPaymentIssuesMenu, setShowPaymentIssuesMenu] = useState<boolean>(false)
  const [showCreditIssuesMenu, setShowCreditIssuesMenu] = useState<boolean>(false)
  const [showTargets, setShowTargets] = useState<boolean>(false)
  const [showCreditRequestCap, setCreditRequestCap] = useState<boolean>(false)
  const [showCreditRequest, setCreditRequest] = useState<boolean>(false)
  const [routeManagement, setRouteManagement] = useState<boolean>(false)
  const [routeJumpReport, setRouteJumpReport] = useState<boolean>(false)
  const showSalesInvoiceReport = usePermission('warehouse_sales_invoice_report', true, isLoading)
  const showGatePassReport = usePermission('gatepass_report', true, isLoading)
  const showLowStockReport = usePermission('low_stock_report', true, isLoading)
  const showCreditCollectionReport = usePermission('credit_collection_report', true, isLoading)
  const showUnsoldExpireReport = usePermission('unsold_expired_products_report', true, isLoading)
  const showCustomerReport = usePermission('customer_report', true, isLoading)
  const showProductWiseReport = usePermission('product_wise_sales_report', true, isLoading)
  const showProductFeedbackReport = usePermission('orders_feedback_report', true, isLoading)
  const showPayLaterTodayReport = usePermission('pay_later_today_report', true, isLoading)
  const showSalesQuantityControlReport = usePermission(
    'sales_quantity_control_report',
    true,
    isLoading
  )
  const showGatePassRejectionReport = usePermission('gate_pass_rejection_report', true, isLoading)
  const showOrderDiscountRejectionReport = usePermission(
    'order_discount_rejection_report',
    true,
    isLoading
  )
  const showCreditRequestRejectionReport = usePermission(
    'rejection-credit_request_report',
    true,
    isLoading
  )
  const showLoadOutRejectionReport = usePermission(
    'rejection-load-out_request_report',
    true,
    isLoading
  )
  const showCreditRequestReport = usePermission('credit_request_report', true, isLoading)
  const showIssueFromSgReport = usePermission('issues_from_sg_report', true, isLoading)
  const showVehicleBrakeDownReport = usePermission('vehicle_breakdown_report', true, isLoading)
  const showCustomerOrderQuantity = usePermission(
    'customer_order_quantity_request',
    true,
    isLoading
  )
  const showHorecaCustomer = usePermission('customer_horeca_contracts', true, isLoading)
  const showDiscountRequest = usePermission(
    'price_and_discount_discount_requests_management',
    true,
    isLoading
  )
  const showDormantCustomerReport = usePermission('dormant_customers_report', true, isLoading)
  const showNoOrderingCustomerReport = usePermission('customer_no_ordering_report', true, isLoading)
  const surveyForm = usePermission('mm_survey_forms', true, isLoading)
  const complaintsReport = usePermission('complaint_request_report', true, isLoading)
  const orderLimitRequestReport = usePermission('order_limit_request_report', true, isLoading)
  const specialOrderLimit = usePermission('special_order_limit', true, isLoading)
  const orderLimitAccessRequest = usePermission('order_limit_access_requests', true, isLoading)
  const wholesalerQuantitySettings = usePermission('wholesaler_quantity_settings', true, isLoading)
  const attendanceReport = usePermission('sr_attendance_report', true, isLoading)
  const complaintsRequest = usePermission('complaint_request', true, isLoading)

  useEffect(() => {
    fetchPermission().then()
  }, [])

  const fetchPermission = async () => {
    let res: any

    res = await checkPermissions('wh_dashboard')
    if (res && (res.read || res.write)) {
      setDashboard(true)
    }
    res = await checkPermissions('team_member')
    if (res && (res.read || res.write)) {
      setShowTeam(true)
    }
    res = await checkPermissions('customer')
    if (res && (res.read || res.write)) {
      setShowCustomer(true)
    }
    res = await checkPermissions('customer_verification_request')
    if (res && (res.read || res.write)) {
      setShowVerCustomer(true)
    }
    res = await checkPermissions('suspended_customer')
    if (res && (res.read || res.write)) {
      setShowSuspCustomer(true)
    }
    res = await checkPermissions('product_SKUs')
    if (res && (res.read || res.write)) {
      setShowProduct(true)
    }
    res = await checkPermissions('price_and_discount_price_management')
    if (res && (res.read || res.write)) {
      setShowPriceManage(true)
    }
    res = await checkPermissions('price_and_discount_discount_capping_management')
    if (res && (res.read || res.write)) {
      setShowPriceCapping(true)
    }
    res = await checkPermissions('price_and_discount_promotional_discount_management')
    if (res && (res.read || res.write)) {
      setShowPriceDiscount(true)
    }
    res = await checkPermissions('vehicle_management')
    if (res && (res.read || res.write)) {
      setShowVehical(true)
    }
    res = await checkPermissions('stock_qty_settings')
    if (res && (res.read || res.write)) {
      setShowLowSock(true)
    }
    res = await checkPermissions('payment_account')
    if (res && (res.read || res.write)) {
      setShowBank(true)
    }
    res = await checkPermissions('sales_reps_target')
    if (res && (res.read || res.write)) {
      setShowKPIGeneral(true)
    }
    res = await checkPermissions('product_SKUs_report')
    if (res && (res.read || res.write)) {
      setShowReportSku(true)
    }
    res = await checkPermissions('daily_sales_report')
    if (res && (res.read || res.write)) {
      setShowReportSal(true)
    }
    res = await checkPermissions('credit_issues_report')
    if (res && (res.read || res.write)) {
      setShowReportCI(true)
    }
    res = await checkPermissions('team_report')
    if (res && (res.read || res.write)) {
      setShowReportTR(true)
    }
    res = await checkPermissions('vehicle_report')
    if (res && (res.read || res.write)) {
      setShowReportVR(true)
    }
    res = await checkPermissions('comparison_report')
    if (res && (res.read || res.write)) {
      setShowReportCR(true)
    }
    res = await checkPermissions('rejection-customer_on_boarding_report')
    if (res && (res.read || res.write)) {
      setShowReportRCB(true)
    }
    res = await checkPermissions('rejection-credit_request_report')
    if (res && (res.read || res.write)) {
      setShowReportRCR(true)
    }
    res = await checkPermissions('rejection-order-discount')
    if (res && (res.read || res.write)) {
      setShowReportROD(true)
    }
    res = await checkPermissions('rejection-load-out_request_report')
    if (res && (res.read || res.write)) {
      setShowReportRLR(true)
    }

    res = await checkPermissions('orders')

    if (res && (res.read || res.write)) {
      setShowOrderMenu(true)
    }

    res = await checkPermissions('sales_invoice')

    if (res && (res.read || res.write)) {
      setShowSalesInvoiceMenu(true)
    }

    res = await checkPermissions('payment_issues')

    if (res && (res.read || res.write)) {
      setShowPaymentIssuesMenu(true)
    }

    res = await checkPermissions('credit_issues')

    if (res && (res.read || res.write)) {
      setShowCreditIssuesMenu(true)
    }

    res = await checkPermissions('targets')

    if (res && (res.read || res.write)) {
      setShowTargets(true)
    }

    res = await checkPermissions('customer_credit_requests_cap')

    if (res && (res.read || res.write)) {
      setCreditRequestCap(true)
    }

    res = await checkPermissions('customer_credit_request')

    if (res && (res.read || res.write)) {
      setCreditRequest(true)
    }

    res = await checkPermissions('route_management')

    if (res && (res.read || res.write)) {
      setRouteManagement(true)
    }

    res = await checkPermissions('wm_route_jump_report')

    if (res && (res.read || res.write)) {
      setRouteJumpReport(true)
    }

    setIsLoading(false)
  }

  return (
    <>
      {isLoading ? (
        <>
          <div className='d-flex justify-content-center p-5 mt-5'>
            <div className='spinner-border text-light' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
          <div className='text-light my-3 text-center'>Please Wait...</div>
        </>
      ) : (
        <>
          <AsideMenuItem
            to='/dashboard'
            icon='/media/icons/duotune/general/gen001.svg'
            title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            fontIcon='bi-app-indicator'
          />

          {showOrderMenu && (
            <AsideMenuItem
              to='/orders'
              icon='/media/icons/duotune/ecommerce/ecm002.svg'
              title='Orders'
            />
          )}

          {showSalesInvoiceMenu && (
            <AsideMenuItem
              to='/sales-invoices'
              icon='/media/icons/duotune/ecommerce/ecm008.svg'
              title='Sales Invoices'
            />
          )}
          {/*  */}
          {routeManagement && (
            <AsideMenuItemWithSub
              to='/route'
              title='Route Management'
              icon='/media/icons/duotune/graphs/gra010.svg'
            >
              <AsideMenuItem to='/route/all-routes' title='All Routes' hasBullet={true} />
              <AsideMenuItem to='/route/route-requests' title='Routes Requests' hasBullet={true} />
            </AsideMenuItemWithSub>
          )}

          {showCustomer ||
          showSuspCustomer ||
          showVerCustomer ||
          showCreditRequestCap ||
          showCustomerOrderQuantity.read ||
          specialOrderLimit.read ||
          specialOrderLimit.write ||
          orderLimitAccessRequest.read ||
          orderLimitAccessRequest.write ? (
            <AsideMenuItemWithSub
              to='/customers'
              title='Customers'
              icon='/media/icons/duotune/communication/com013.svg'
            >
              {showCustomer && (
                <AsideMenuItem to='/customers' title='All Customers' hasBullet={true} />
              )}
              {showVerCustomer && (
                <AsideMenuItem
                  to='/customers/verification-requests'
                  title='Verification Requests'
                  hasBullet={true}
                />
              )}
              {asideMenuHandler(
                showHorecaCustomer,
                <AsideMenuItem
                  to='/customers/horeca-contracts'
                  title='HORECA Customers'
                  hasBullet={true}
                />
              )}
              {showSuspCustomer && (
                <AsideMenuItem
                  to='/customers/suspended-customers'
                  title='Suspended Customers'
                  hasBullet={true}
                />
              )}
              {showCreditRequestCap && (
                <AsideMenuItem
                  to='/customers/credit-request-cap'
                  title='Credit Requests Cap'
                  hasBullet={true}
                />
              )}
              {showCreditRequest && (
                <AsideMenuItem
                  to='/customers/credit-requests'
                  title='Credit Requests'
                  hasBullet={true}
                />
              )}
              {asideMenuHandler(
                showCustomerOrderQuantity,
                <AsideMenuItem
                  to='/customers/order-quantity-requests'
                  title='Order Quantity Request'
                  hasBullet={true}
                />
              )}
              {asideMenuHandler(
                specialOrderLimit,
                <AsideMenuItem
                  to='/customers/special-order-limit'
                  title='Special Order Limits'
                  hasBullet={true}
                />
              )}
              {asideMenuHandler(
                orderLimitAccessRequest,
                <AsideMenuItem
                  to='/customers/order-limit-access-request'
                  title='Order Limit Access Requests'
                  hasBullet={true}
                />
              )}
            </AsideMenuItemWithSub>
          ) : null}

          {showPaymentIssuesMenu && (
            <AsideMenuItem
              to='/payment-issues'
              icon='/media/icons/duotune/finance/fin002.svg'
              title='Payment Issues'
            />
          )}

          {showCreditIssuesMenu && (
            <AsideMenuItem
              to='/credit-issues'
              icon='/media/icons/duotune/files/fil005.svg'
              title='Credit Issues'
            />
          )}

          {showTargets && (
            <AsideMenuItem
              to='/targets'
              icon='/media/icons/duotune/abstract/abs014.svg'
              title='Targets'
            />
          )}
          {showteam && (
            <AsideMenuItem
              to='/team-members'
              icon='/media/icons/duotune/communication/com014.svg'
              title='Team Members'
            />
          )}

          {asideMenuHandler(
            surveyForm,
            <AsideMenuItem
              to='/survey-forms'
              icon='/media/icons/duotune/files/fil024.svg'
              title='Survey Forms'
            />
          )}

          {asideMenuHandler(
            complaintsRequest,
            <AsideMenuItem
              to='/all-complaints'
              icon='/media/icons/duotune/communication/com013.svg'
              title='Complaints'
            />
          )}

          {showProduct && (
            <AsideMenuItemWithSub
              to='/products'
              icon='/media/icons/duotune/general/gen017.svg'
              title='Product SKUs'
            >
              <AsideMenuItem to='/products/current-stock' title='Current Stock' hasBullet={true} />
              <AsideMenuItem
                to='/products/product-catalog'
                title='Product Catalog'
                hasBullet={true}
              />
            </AsideMenuItemWithSub>
          )}

          {showPriceManage || showPriceCapping || showPriceDiscount ? (
            <AsideMenuItemWithSub
              to='/price'
              title='Price & Discount'
              icon='/media/icons/duotune/ecommerce/ecm011.svg'
            >
              {showPriceManage && (
                <AsideMenuItem to='/price/manage-price' title='Manage Price' hasBullet={true} />
              )}
              {asideMenuHandler(
                showDiscountRequest,
                <AsideMenuItem
                  to='/price/discount-request'
                  title='Discount Requests'
                  hasBullet={true}
                />
              )}
              {showPriceCapping && (
                <AsideMenuItem
                  to='/price/discount-capping'
                  title='Discount Capping'
                  hasBullet={true}
                />
              )}
              {showPriceDiscount && (
                <AsideMenuItem
                  to='/price/promotional-discount'
                  title='Promotional Discount'
                  hasBullet={true}
                />
              )}
            </AsideMenuItemWithSub>
          ) : null}

          {showVehical && (
            <AsideMenuItem
              to='/vehicle-management'
              icon='/media/icons/duotune/abstract/abs037.svg'
              title='Vehicle Overview'
            />
          )}

          {showLowSock && (
            <AsideMenuItem
              to='/low-stock'
              icon='/media/icons/duotune/graphs/gra011.svg'
              title='Low Stock Settings'
            />
          )}

          {asideMenuHandler(
            wholesalerQuantitySettings,
            <AsideMenuItem
              to='/wholesaler-quantity-settings'
              icon='/media/icons/duotune/graphs/gra011.svg'
              title='Wholesaler Quantity Settings'
            />
          )}

          {showBank && (
            <AsideMenuItemWithSub
              to='/payment-accounts'
              icon='/media/icons/duotune/finance/fin001.svg'
              title='Payment Accounts'
            >
              <AsideMenuItem
                to='/payment-accounts/bank-accounts'
                title='Bank Accounts'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/payment-accounts/mobile-wallet-accounts'
                title='Mobile Wallet Accounts'
                hasBullet={true}
              />
            </AsideMenuItemWithSub>
          )}
          {showKPIGeneral && (
            <AsideMenuItem
              to='/target-kpi-settings/general-kpi'
              icon='/media/icons/duotune/graphs/gra010.svg'
              title='Target KPIs Settings'
              fontIcon='bi-app-indicator'
            />
          )}

          <AsideMenuItemWithSub
            to='/reports'
            title='Reports'
            icon='/media/icons/duotune/graphs/gra006.svg'
          >
            <AsideMenuItem to='/reports/order-report' title='Pre Order Report' hasBullet={true} />
            {asideMenuHandler(
              showGatePassReport,
              <AsideMenuItem
                to='/reports/gatepass-report'
                title='Gatepass Report'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              showLowStockReport,
              <AsideMenuItem
                to='/reports/low-stock-report'
                title='Low-Stock Report'
                hasBullet={true}
              />
            )}
            <AsideMenuItem to='/reports/summary-report' hasBullet={true} title='Summary Report' />
            {asideMenuHandler(
              showSalesInvoiceReport,
              <AsideMenuItem
                to='/reports/sales-invoice-report'
                title='Sales Invoice Report'
                hasBullet={true}
              />
            )}

            {asideMenuHandler(
              showCreditCollectionReport,
              <AsideMenuItem
                to='/reports/credit-collection-report'
                title='Credit Collection Report'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              showDormantCustomerReport,
              <AsideMenuItem
                to='/reports/dormant-customer-report'
                title='Dormant Customer Report'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              showNoOrderingCustomerReport,
              <AsideMenuItem
                to='/reports/no-ordering-customer-report'
                title='Customer No-Ordering Report'
                hasBullet={true}
              />
            )}

            {asideMenuHandler(
              attendanceReport,
              <AsideMenuItem
                to='/reports/attendance-report'
                title='Attendance Report'
                hasBullet={true}
              />
            )}
            {routeJumpReport && (
              <AsideMenuItem
                to='/reports/route-jump-report'
                title='Route Jump Report'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              orderLimitRequestReport,
              <AsideMenuItem
                to='/reports/order-limit-request-report'
                title='Order Limit Request Report'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              showUnsoldExpireReport,
              <AsideMenuItem
                to='/reports/expired-products'
                title='Unsold Expired Products'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              showCustomerReport,
              <AsideMenuItem
                to='/reports/customer-report'
                title='Customer Report'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              showDormantCustomerReport,
              <AsideMenuItem
                to='/reports/dormant-customer-report'
                title='Dormant Customer Report'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              showNoOrderingCustomerReport,
              <AsideMenuItem
                to='/reports/no-ordering-customer-report'
                title='Customer No-Ordering Report'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              showProductWiseReport,
              <AsideMenuItem
                to='/reports/product-skus-report'
                title='Product wise Sales'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              showProductFeedbackReport,
              <AsideMenuItem
                to='/reports/order-feedback-report'
                title='Orders Feedback'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              showPayLaterTodayReport,
              <AsideMenuItem
                to='/reports/pay-later-today-report'
                title='Pay Later Today Report'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              showSalesQuantityControlReport,
              <AsideMenuItem
                to='/reports/order-quantity-requests'
                title='Sales Quantity Control Report'
                hasBullet={true}
              />
            )}
            <AsideMenuItem
              to='/reports/temperature-humidity-report'
              title='Temperature & Humidity Report Readings'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/reports/temperature-humidity-alert-report'
              title='Temperature & Humidity Alerts'
              hasBullet={true}
            />
            {(showLoadOutRejectionReport.read ||
              showOrderDiscountRejectionReport.read ||
              showCreditRequestRejectionReport.read ||
              showGatePassRejectionReport.read) && (
              <AsideMenuItemWithSub
                to='/reports/rejection-report'
                title='Rejection Reports'
                hasBullet={true}
              >
                {/*<AsideMenuItem*/}
                {/*  to='/reports/rejection-report/customer-on-boarding'*/}
                {/*  title='Customer On-Boarding'*/}
                {/*  hasBullet={true}*/}
                {/*/>*/}
                {asideMenuHandler(
                  showLoadOutRejectionReport,
                  <AsideMenuItem
                    to='/reports/rejection-report/load-out-requests'
                    title='Load-Out Requests'
                    hasBullet={true}
                  />
                )}
                {asideMenuHandler(
                  showOrderDiscountRejectionReport,
                  <AsideMenuItem
                    to='/reports/rejection-report/order-discount'
                    title='Order Discount'
                    hasBullet={true}
                  />
                )}
                {asideMenuHandler(
                  showCreditRequestRejectionReport,
                  <AsideMenuItem
                    to='/reports/rejection-report/credit-request'
                    title='Credit Request'
                    hasBullet={true}
                  />
                )}
                {asideMenuHandler(
                  showGatePassRejectionReport,
                  <AsideMenuItem
                    to='/reports/rejection-report/gate-pass-rejection'
                    title='Gate Pass Rejection'
                    hasBullet={true}
                  />
                )}
              </AsideMenuItemWithSub>
            )}
            {/* <AsideMenuItem to='/reports/sales-report' title='Daily Sales Report' hasBullet={true} />
        <AsideMenuItem
          to='/reports/credit-issues-report'
          title='Credit Issues Report'
          hasBullet={true}
        />
        <AsideMenuItem to='/reports/team-report' title='Team Report' hasBullet={true} />*/}
            <AsideMenuItem
              to='/reports/vehicle-usage-report'
              title='Vehicle Usage Report'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/reports/sr-daily-routes-report'
              title='SRs Daily Routes'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/reports/customer-order-limit-report'
              title='Customer Order Limit Report'
              hasBullet={true}
            />

            <AsideMenuItem
              to='/reports/banana-offloading-report'
              title='Banana Offloading Report'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/reports/sr-daily-routes-report'
              title='SRs Daily Routes'
              hasBullet={true}
            />
            {/*<AsideMenuItem to='/reports/comparison-report' title='Comparison Report' hasBullet={true} />
             */}
            {asideMenuHandler(
              complaintsReport,
              <AsideMenuItem
                to='/reports/complaint-report'
                title='Complaints Report'
                hasBullet={true}
              />
            )}
            {/*<AsideMenuItem to='/reports/sales-resp-target' title='Sales Reps. Target' hasBullet={true} />*/}
            {/* <AsideMenuItem to='/reports/returned-products' title='Returned Products' hasBullet={true} />
        <AsideMenuItem to='/reports/credit-usage-report' title='Credit Usage Reports' hasBullet={true} />
        <AsideMenuItem to='/reports/target-report' title='Target Report' hasBullet={true} />*/}
            {asideMenuHandler(
              showCreditRequestReport,
              <AsideMenuItem
                to='/reports/credit-request-report'
                title='Credit Request Reports'
                hasBullet={true}
              />
            )}
            {asideMenuHandler(
              showIssueFromSgReport,
              <AsideMenuItem to='/reports/issues-from-sg' title='Issues from SG' hasBullet={true} />
            )}
            {/*<AsideMenuItem to='/reports/credit-capping-update' title='Credit Capping Updates' hasBullet={true} /> */}
            {/*<AsideMenuItem*/}
            {/*  to='/reports/credit-issues-report'*/}
            {/*  title='Credit Issues Report'*/}
            {/*  hasBullet={true}*/}
            {/*/>*/}
            {asideMenuHandler(
              showVehicleBrakeDownReport,
              <AsideMenuItem
                to='/reports/vehicle-breakdown-report'
                title='Vehicle Breakdown Report'
                hasBullet={true}
              />
            )}
          </AsideMenuItemWithSub>
        </>
      )}
    </>
  )
}
