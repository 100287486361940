import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {AsyncPaginate} from 'react-select-async-paginate'
import {
  apiBaseURL,
  checkEmpty,
  dateFormat,
  displayAddedBy,
  fetchAsyncDropdown,
  fetchCustomerCategories,
  isEmpty,
  KTSVG,
  retrieveFilter,
  saveFilter,
  staticSearch,
  toAbsoluteUrl,
} from '../../../../_eaFruitsDms/helpers'
import {categoryStyle} from '../../../../_eaFruitsDms/helpers/components/categoryStyle'
import {getRequest} from '../../../../_eaFruitsDms/https/apiCall'
import {EaChartPie} from '../../../../_eaFruitsDms/layout/components/eaCharts/eaChart'
import IsLoader from '../../../../_eaFruitsDms/layout/components/loader/loader'
import NoRecords from '../../../../_eaFruitsDms/layout/components/noRecords/noRecords'
import {
  OrderingArrowDown,
  OrderingArrowUp,
} from '../../../../_eaFruitsDms/layout/components/orderingArrow/orderingArrow'
import CustomSearchBar from '../../../../_eaFruitsDms/layout/components/searchbar/searchbar'
import EATitle from '../../../../_eaFruitsDms/layout/components/title/title'
import {PageTitle} from '../../../../_eaFruitsDms/layout/core'
import moment from 'moment'
import {customerTypes, fetchUserTypes, userTypes} from '../../customers/WMcustomers/CustomerConst'
import CustomPaginate from '../../../../_eaFruitsDms/layout/components/pagination/customPaginate'
import tenantConfiguration from '../../../../TenantVariables'
import ResetButton from '../../../../_eaFruitsDms/layout/components/button/resetButton'
import useDebounce from '../../../../_eaFruitsDms/helpers/components/useDebounce'
import {filters} from '../../../../_eaFruitsDms/utils/FilterConstants'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type Props = {
  className: string
}

const CustomerReport: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const reportHeaderTitle = [
    {
      title: 'Reports',
    },
  ]
  let customersFiltered: any = retrieveFilter(filters.wm_customer_report)

  const initialPageState = {
    // search: customersFiltered?.search || '', limit: tenantConfiguration.limit, offset: '', list: [], next: '', count: '', previous: '', isLoading: true, currentOffset: customersFiltered?.currentOffSet || '', selected: customersFiltered?.selected || 0
    limit: tenantConfiguration.limit,
    offset: '',
    list: [],
    next: '',
    count: '',
    previous: '',
    isLoading: true,
    currentOffset: customersFiltered?.currentOffSet || '',
    selected: customersFiltered?.selected || 0,
  }

  const initialOrderKeys = {
    Id: true,
    Name: true,
    StreetName: true,
    Category: true,
    Type: true,
    CreditRange: true,
    CustomerTag: true,
    Warehouse: true,
  }
  const todayDate = dateFormat(moment())

  const initialDateFilter = {
    startDate: customersFiltered?.startDate || todayDate,
    endDate: customersFiltered?.endDate || todayDate,
  }

  const initialState = {
    warehouseId: customersFiltered?.warehouseId || {label: 'All Warehouses', value: ''},
    categoryId: customersFiltered?.categoryId || {label: 'All Categories', value: ''},
    typeId: customersFiltered?.typeId || {label: 'All Type', value: ''},
    userId: customersFiltered?.userId || {label: 'All Users', value: ''},
    startDate: customersFiltered?.startDate || todayDate,
    endDate: customersFiltered?.endDate || todayDate,
    userType: customersFiltered?.userType || '',
    selected: customersFiltered?.selected || '',
    search: customersFiltered?.search || '',
    currentOffSet: customersFiltered?.currentOffSet || '',
    buttonDisable: true,
  }

  const getUserType = localStorage.getItem('user_type') || ''
  const getW_Id: any = localStorage.getItem('w_id')
  const wId = getUserType.toLowerCase() !== 'sa' ? (isEmpty(getW_Id) ? '' : getW_Id) : ''

  const [totalRegistered, setTotalRegistered] = useState<number>(0)
  const [totalVerifiedRegistered, setTotalVerifiedRegistered] = useState<number>(0)
  const [pageState, setPageState] = useState(initialPageState)
  const [categories, setCategories] = useState<any>([])
  const [categoryId, setCategoryId] = useState<any>(
    customersFiltered?.categoryId || {
      label: 'All Categories',
      value: '',
    }
  )
  const [warehouseValue, setWarehouseValue] = useState<any>({label: 'All Warehouse', value: ''})
  const [warehouseCustomerValue, setWarehouseCustomerValue] = useState<any>(
    customersFiltered?.warehouseId || {
      label: 'All Warehouses',
      value: '',
    }
  )
  const [registerDate, setRegisterDate] = useState<any>(initialDateFilter)
  const [warehouseValueReport, setWarehouseValueReport] = useState<any>({
    label: 'All Warehouse',
    value: '',
  })
  const [offset, setOffset] = useState(0)
  const [customerWarehoustOffset, setCustomerWarehoustOffset] = useState(0)
  const [list, setList] = useState([])
  const [warehouseList, setWarehouseList] = useState([])
  const [dropDownSearch, setDropDownSearch] = useState('')
  const [type, setType] = useState(customersFiltered?.typeId || {label: 'All Types', value: ''})
  const [offsetTag, setOffsetTag] = useState(0)
  const [listTag, setListTag] = useState([])
  const [dropDownTagSearch, setDropDownTagSearch] = useState('')
  const [tag, setTag] = useState({label: 'All Tags', value: ''})
  const [offsetRange, setOffsetRange] = useState(0)
  const [listRange, setListRange] = useState([])
  const [dropDownRangeSearch, setDropDownRangeSearch] = useState('')
  const [range, setRange] = useState({label: 'All Credit Range', value: ''})
  const [warehouseWiseCustomersReport, setWarehouseWiseCustomersReport] = useState([])
  const [customerCategoriesReport, setCustomerCategoriesReport] = useState([])
  const [isReportLoading, setIsReportLoading] = useState(true)
  const [ordering, setOrdering] = useState('')
  const [orderColumnKey, setOrderColumnKey] = useState('')
  const [orderKey, setOrderKey] = useState(initialOrderKeys)
  const [userOffset, setUserOffset] = useState<any>('')
  const [userType, setUserType] = useState<any>(customersFiltered?.userType || '')
  const [userId, setUserId] = useState<any>(
    customersFiltered?.userId || {
      label: 'All Users',
      value: '',
    }
  )
  const [userReset, setUserReset] = useState<any>(moment())
  const [filterStates, setFilterStates] = useState<any>(initialState)
  const [buttonDisable, setButtonDisable] = useState<boolean>(
    customersFiltered?.buttonDisable || false
  )
  const [search, setSearch] = useState<any>(customersFiltered?.search || '')

  const debounceSearch = useDebounce(search, 500)

  const getList = async () => {
    // Develop BY: Kashyap
    // Description: get List Details
    setPageState({...pageState, isLoading: true})

    let url = `${apiBaseURL()}reports/customer?ordering=${ordering}&warehouse_id=${
      warehouseCustomerValue.value
    }&limit=${pageState.limit}&offset=${pageState.currentOffset}&search=${debounceSearch}&type=${
      type.value
    }&category_ids=${categoryId.value}&reg_by_type=${userType}&reg_by_id=${
      userId !== '' ? userId.value : ''
    }&reg_duration_after=${registerDate.startDate.apiDate}&reg_duration_before=${
      registerDate.endDate.apiDate
    }`

    const response = await getRequest(url, true)
    setPageState({
      ...pageState,
      list: response.results,
      next: response.next,
      count: response.count,
      previous: response.previous,
      isLoading: response ? false : true,
    })

    if (response && response.meta) {
      setTotalRegistered(
        isEmpty(response.meta.total_registered_customers)
          ? 0
          : response.meta.total_registered_customers
      )

      setTotalVerifiedRegistered(
        isEmpty(response.meta.total_registered_customers_with_contact_verified)
          ? 0
          : response.meta.total_registered_customers_with_contact_verified
      )
    }

    let updatedState: any = {...filterStates}
    updatedState['selected'] = pageState.selected
    updatedState['currentOffSet'] = pageState.currentOffset
    // updatedState['search'] = pageState.search
    updatedState['buttonDisable'] = buttonDisable

    setFilterStates(updatedState)
    saveFilter(filters.wm_customer_report, filterStates)
    if (
      search == '' &&
      warehouseCustomerValue.value == '' &&
      categoryId.value == '' &&
      userType == '' &&
      type.value == '' &&
      registerDate.endDate.apiDate === todayDate.apiDate &&
      registerDate.startDate.apiDate === todayDate.apiDate
    ) {
      setButtonDisable(false)
    }
  }

  const fetchCategories = async (search?: any) => {
    let options: any = []
    const categoriesResponse = await getRequest(
      `${apiBaseURL()}master/customer-category-parent/list/`,
      true
    )

    if (categories.length == 0) {
      options.push({
        label: 'All Categories',
        value: '',
      })
    }

    const categoryKeys = Object.keys(categoriesResponse)

    categoryKeys.forEach((value) => {
      let optionId: string = ''

      options.push({
        label: value,
        value: optionId,
        color: '#6d6e6f',
        bg: '#f7f7f7',
        transform: 'uppercase',
        boxSizing: 'borderBox',
        weight: '500',
        cursor: 'default',
        size: '.75rem !important',
      })

      categoriesResponse[value].map((option: any) => {
        if (optionId === '') {
          optionId += option.id
        } else {
          optionId += ',' + option.id
        }
        options.push({
          label: option.category,
          value: option.id,
        })
        return options
      })

      let index = options.findIndex((x: any) => x.label === value)
      options[index].value = optionId
    })

    if (categories.length > 0) {
      setCategories(categories.concat(options))
    } else {
      setCategories(options)
    }

    if (search !== '') {
      options = staticSearch(options, search)
    }

    return {
      options: options,
      hasMore: false,
    }
  }

  const fetchUsers = async (search?: any) => {
    //auth/users/team/list?offset=0&warehouse__id=6&type=sr
    const options: any = []
    let hasMore: boolean = false

    if (userType !== '') {
      if (search !== '') {
        setDropDownSearch(search)
      }

      let wId = ''

      if (userType !== 'ts') {
        wId = warehouseCustomerValue.value
      }

      const response: any = await getRequest(
        `${apiBaseURL()}auth/users/team/list?search=${dropDownSearch}&type=${userType}&limit=${
          pageState.limit
        }&offset=${userOffset}&ordering=first_name&warehouse__id=${wId}`,
        true
      )

      if (!response.results) {
        hasMore = false
      } else {
        if (response.next !== null) {
          const queryParams = new URLSearchParams(response.next)
          let newOffset: any = queryParams.get('offset')
          setUserOffset(newOffset)
          hasMore = true
        }

        if (options.length === 0) {
          options.push({
            label: 'All Users',
            value: '',
          })
        }

        if (response.results.length > 0) {
          response.results.map((option: any) => {
            options.push({
              label:
                option.first_name + ' ' + option.last_name + ' (' + option.type.toUpperCase() + ')',
              value: option.id,
            })
          })
        }
      }
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchWarehouse = async (search: any) => {
    return fetchAsyncDropdown(
      offset,
      setOffset,
      list,
      setList,
      `warehouse/list?parent_warehouse__id=${wId}&ordering=name`,
      search,
      setDropDownSearch,
      'name',
      true,
      'All Warehouse'
    )
  }

  const fetchListWarehouse = async (search: any) => {
    let options: any = []
    const warehouseResponse = await getRequest(
      `${apiBaseURL()}warehouse/list?is_parent=True&ordering=name&search=${search}&limit=${100}&offset=${customerWarehoustOffset}&parent_warehouse__id=${wId}`,
      true
    )

    let hasMore: boolean = false

    if (warehouseResponse.next !== null) {
      const queryParams = new URLSearchParams(warehouseResponse.next)
      let newOffset: any = queryParams.get('offset')
      setCustomerWarehoustOffset(newOffset)
      hasMore = true
    }

    if (warehouseList.length == 0) {
      options.push({
        label: 'All Warehouses',
        value: '',
      })
    }

    if (warehouseResponse.results.length > 0) {
      warehouseResponse.results.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        })
      })
    }

    if (warehouseList.length > 0) {
      setWarehouseList(warehouseList.concat(options))
    } else {
      setWarehouseList(options)
    }

    return {
      options: options,
      hasMore: hasMore,
    }
  }

  const fetchTags = (search: any) => {
    return fetchAsyncDropdown(
      offsetTag,
      setOffsetTag,
      listTag,
      setListTag,
      `master/customer-tags/list/?ordering=name`,
      search,
      setDropDownTagSearch,
      'tag_name',
      true,
      'All Tags'
    )
  }

  const fetchRange = (search: any) => {
    return fetchAsyncDropdown(
      offsetRange,
      setOffsetRange,
      listRange,
      setListRange,
      `master/credit-score/list/?ordering=name`,
      search,
      setDropDownRangeSearch,
      'credit_range_name',
      true,
      'All Credit Range'
    )
  }

  const goToOverview = (cId: any) => {
    localStorage.setItem('cId', cId)
    window.open('/customers/singlecustomer/overview', '_blank')
  }

  const fetchCustomerTypes = async (search?: any) => {
    let options = customerTypes

    if (search !== '') {
      options = staticSearch(options, search)
    }

    return {
      options,
      hasMore: false,
    }
  }

  const fetchReport = async () => {
    setIsReportLoading(true)

    // Develop BY: Kashyap
    // warehouse wise report
    let warehouseWisUrl = apiBaseURL() + `customers/wm/report`
    if (warehouseValueReport.value === '') {
      warehouseWisUrl += `?all_warehouse=true&warehouse_id=${
        getUserType.toLowerCase() !== 'sa' ? (isEmpty(getW_Id) ? '' : getW_Id) : ''
      }`
    } else {
      warehouseWisUrl += `?warehouse_id=${
        isEmpty(warehouseValueReport?.value ?? '') ? '' : warehouseValueReport.value
      }`
    }
    const warehouseWiseResponse = await getRequest(warehouseWisUrl, true)

    if (warehouseWiseResponse) {
      let warehouseResponse: any = []
      warehouseWiseResponse.results.length !== 0 &&
        warehouseWiseResponse.results.forEach((item: any) => {
          item.customer_count > 0 &&
            warehouseResponse.push({
              value: item.customer_count,
              name: item.name,
            })
        })
      setWarehouseWiseCustomersReport(warehouseResponse)
    }

    // Develop BY: Kashyap
    // customer categories report
    let customerUrl = apiBaseURL() + `customers/wm/report/categories`
    if (warehouseValueReport.value === '') {
      customerUrl += `?all_warehouse=true&warehouse_id=${
        getUserType.toLowerCase() !== 'sa' ? (isEmpty(getW_Id) ? '' : getW_Id) : ''
      }`
    } else {
      customerUrl += `?warehouse_id=${
        isEmpty(warehouseValueReport?.value ?? '') ? '' : warehouseValueReport.value
      }`
    }
    const customerieResponse = await getRequest(customerUrl, true)

    if (customerieResponse) {
      let customerResponse: any = []
      customerieResponse.results.length !== 0 &&
        customerieResponse.results.forEach((item: any) => {
          item.category_count > 0 &&
            customerResponse.push({
              value: item.category_count,
              name: item.category,
            })
        })
      setCustomerCategoriesReport(customerResponse)
    }
    setIsReportLoading(false)
  }

  useEffect(() => {
    setOffset(0)
    setOffsetTag(0)
    setOffsetRange(0)
    setUserOffset(0)
  }, [dropDownSearch, dropDownTagSearch, dropDownRangeSearch])

  useEffect(() => {
    getList()
  }, [
    pageState.currentOffset,
    debounceSearch,
    warehouseCustomerValue,
    type,
    categoryId,
    tag,
    range,
    ordering,
    userId,
    userType,
    registerDate,
  ])

  useEffect(() => {
    fetchReport()
  }, [warehouseValueReport])

  const warehouseWiseReport = {
    data: warehouseWiseCustomersReport,
    title: 'Warehouse Wise Customers',
    bottom: '10%',
  }

  const customerCategoriesReportObj = {
    data: customerCategoriesReport,
    title: 'Customer Categories',
    bottom: '10%',
  }

  const handleOrdering = (key: any) => {
    setOrderColumnKey(key)
    if (key === 'Id') {
      setOrderKey({...initialOrderKeys, Id: !orderKey.Id})
    }
    if (key === 'Name') {
      setOrderKey({...initialOrderKeys, Name: !orderKey.Name})
    }
    if (key === 'StreetName') {
      setOrderKey({...initialOrderKeys, StreetName: !orderKey.StreetName})
    }
    if (key === 'Category') {
      setOrderKey({...initialOrderKeys, Category: !orderKey.Category})
    }
    if (key === 'Type') {
      setOrderKey({...initialOrderKeys, Type: !orderKey.Type})
    }
    if (key === 'CreditRange') {
      setOrderKey({...initialOrderKeys, CreditRange: !orderKey.CreditRange})
    }
    if (key === 'CustomerTag') {
      setOrderKey({...initialOrderKeys, CustomerTag: !orderKey.CustomerTag})
    }
    if (key === 'Warehouse') {
      setOrderKey({...initialOrderKeys, Warehouse: !orderKey.Warehouse})
    }
  }

  useEffect(() => {
    if (orderColumnKey === 'Id') {
      if (orderKey.Id) {
        setOrdering('id')
      } else {
        setOrdering('-id')
      }
    }

    if (orderColumnKey === 'Name') {
      if (orderKey.Name) {
        setOrdering('business_name')
      } else {
        setOrdering('-business_name')
      }
    }

    if (orderColumnKey === 'Warehouse') {
      if (orderKey.Warehouse) {
        setOrdering('warehouse')
      } else {
        setOrdering('-warehouse')
      }
    }

    if (orderColumnKey === 'StreetName') {
      if (orderKey.StreetName) {
        setOrdering('street')
      } else {
        setOrdering('-street')
      }
    }

    if (orderColumnKey === 'Category') {
      if (orderKey.Category) {
        setOrdering('category')
      } else {
        setOrdering('-category')
      }
    }

    if (orderColumnKey === 'CreditRange') {
      if (orderKey.CreditRange) {
        setOrdering('credit_range')
      } else {
        setOrdering('-credit_range')
      }
    }

    if (orderColumnKey === 'Type') {
      if (orderKey.Type) {
        setOrdering('type')
      } else {
        setOrdering('-type')
      }
    }

    if (orderColumnKey === 'CustomerTag') {
      if (orderKey.CustomerTag) {
        setOrdering('customer_tag__tag_name')
      } else {
        setOrdering('-customer_tag__tag_name')
      }
    }
  }, [orderColumnKey, orderKey])

  const handleChangeReset = () => {
    setWarehouseCustomerValue({
      label: 'All Warehouses',
      value: getUserType.toLowerCase() !== 'sa' ? getW_Id : '',
    })
    setCategoryId({label: 'All Categories', value: ''})
    setUserId({label: 'All Users', value: ''})
    setType({label: 'All Types', value: ''})
    setUserType('')
    setSearch('')
    let updatedPageState: any = {...pageState}
    updatedPageState['selected'] = 0
    updatedPageState['currentOffset'] = 0
    // updatedPageState['search'] = ''
    setPageState(updatedPageState)

    let updatedDateState: any = {...registerDate}
    updatedDateState['startDate'] = todayDate
    updatedDateState['endDate'] = todayDate
    setRegisterDate(updatedDateState)

    let updatedState: any = {...filterStates}
    updatedState['warehouseId'] = ''
    updatedState['categoryId'] = ''
    updatedState['typeId'] = ''
    updatedState['userId'] = ''
    updatedState['userType'] = ''
    updatedState['search'] = ''
    updatedState['startDate'] = ''
    updatedState['endDate'] = ''
    updatedState['currentOffset'] = ''
    updatedState['selected'] = ''
    // setRegisterDate(initialDateFilter)
    setFilterStates(updatedState)
    setButtonDisable(false)
  }

  const CustomDatePickerComponent = () => {
    return (
      <>
        <DateRangePicker
          onCallback={(start, end, label) => {
            setButtonDisable(true)
            const callBakeDate = {
              startDate: dateFormat(start._d),
              endDate: dateFormat(end._d),
            }
            localStorage.setItem('start-date', callBakeDate.startDate.apiDate)
            localStorage.setItem('end-date', callBakeDate.endDate.apiDate)
            let updatedState: any = {...filterStates}
            updatedState['startDate'] = callBakeDate.startDate
            updatedState['endDate'] = callBakeDate.endDate
            setFilterStates(updatedState)
            setRegisterDate(callBakeDate)
          }}
          initialSettings={{
            startDate: moment(registerDate.startDate.apiDate).toDate(),
            endDate: moment(registerDate.endDate.apiDate).toDate(),
            autoUpdateInput: true,
            alwaysShowCalendars: true,
            cancelClass: 'btn-secondary',
            drops: 'auto',
            locale: {
              format: 'DD MMM YYYY',
            },
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
              ],
            },
          }}
        >
          <input
            type='text'
            className='form-control calendar my-1 me-3 w-auto'
            defaultValue={registerDate}
            disabled={isReportLoading}
          />
        </DateRangePicker>
      </>
    )
  }

  return (
    <>
      <EATitle title='Customer Report' />
      <PageTitle breadcrumbs={reportHeaderTitle}>
        {intl.formatMessage({id: 'Customer Report'})}
      </PageTitle>
      <div className={`card ${className} mb-5`}>
        {/* begin::Header */}
        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
          {/* begin::Title */}
          <h3 className='card-title align-items-start flex-column my-0'>
            <span className='card-label fw-bold font-17'>Customer Report</span>
          </h3>
          {/* end::Title */}
          {/* begin::Toolbar */}
          {/* <div className='card-toolbar d-flex g-3 my-0'> */}
          <form
            className='card-toolbar d-flex g-3 my-0'
            onSubmit={(e) => e.preventDefault()}
            autoComplete='off'
          >
            <AsyncPaginate
              loadOptions={fetchWarehouse}
              isSearchable
              placeholder='All Warehouses'
              className='react-select-container my-1 me-3 w-160px'
              classNamePrefix='react-select'
              name='select_team'
              onChange={(e: any) => {
                setWarehouseValueReport(e)
              }}
              value={warehouseValueReport}
              isDisabled={pageState.isLoading}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#0c78a4',
                },
              })}
            />
            {/* </div> */}
          </form>
          {/* end::Toolbar */}
        </div>
        <div className='card-body'>
          <div className='row'>
            {isReportLoading ? (
              <IsLoader />
            ) : (
              <>
                <div className='col-md-6'>
                  {' '}
                  <EaChartPie item={warehouseWiseReport} />{' '}
                </div>
                <div className='col-md-6'>
                  {' '}
                  <EaChartPie item={customerCategoriesReportObj} />{' '}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className='card'>
          {pageState.isLoading && <IsLoader />}
          <div className='card-header border-0 py-2 px-7 align-items-center'>
            {/* <CustomSearchBar newSearch={true} setResponse={setPageState} disabled={pageState.isLoading}/> */}
            <CustomSearchBar
              onChange={(e: any) => {
                setButtonDisable(true)
                setSearch(e.target.value)
                let updatedPageState: any = {...pageState}
                updatedPageState['selected'] = 0
                updatedPageState['currentOffset'] = 0
                // updatedPageState['search'] =e.target.value
                setPageState(updatedPageState)
                setFilterStates({...filterStates, ['search']: e.target.value})
              }}
              value={search}
              isDisable={pageState.isLoading}
            />
            <div className='ms-auto'>
              {/* <div className='card-toolbar d-flex my-0'> */}
              <form
                className='card-toolbar d-flex my-0'
                onSubmit={(e) => e.preventDefault()}
                autoComplete='off'
              >
                <CustomDatePickerComponent />
                <AsyncPaginate
                  loadOptions={fetchListWarehouse}
                  isSearchable
                  placeholder='All Warehouses'
                  className='react-select-container my-1 me-3 w-160px'
                  classNamePrefix='react-select'
                  name='select_team'
                  onChange={(e: any) => {
                    let updatedPageState: any = {...pageState}
                    updatedPageState['selected'] = 0
                    updatedPageState['currentOffset'] = 0
                    setPageState(updatedPageState)

                    setButtonDisable(true)
                    setWarehouseCustomerValue(e)
                    setUserReset(moment())
                    setUserId('')
                    setUserOffset('')
                    setFilterStates({...filterStates, ['warehouseId']: e})
                  }}
                  value={warehouseCustomerValue}
                  isDisabled={pageState.isLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchCategories}
                  isSearchable
                  className='react-select-container my-1 me-3 w-150px'
                  classNamePrefix='react-select'
                  placeholder='All Categories'
                  styles={categoryStyle}
                  name='categories'
                  value={categoryId}
                  // value={categories.filter((option: any) => option.value === categoryId)}
                  onChange={(e: any) => {
                    setCategoryId(e)
                    let updatedPageState: any = {...pageState}
                    updatedPageState['selected'] = 0
                    updatedPageState['currentOffset'] = 0
                    setPageState(updatedPageState)

                    setButtonDisable(true)
                    setFilterStates({...filterStates, ['categoryId']: e})
                  }}
                  isDisabled={pageState.isLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />

                <AsyncPaginate
                  loadOptions={fetchCustomerTypes}
                  isSearchable
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='All Types'
                  value={type}
                  onChange={(e: any) => {
                    let updatedPageState: any = {...pageState}
                    updatedPageState['selected'] = 0
                    updatedPageState['currentOffset'] = 0
                    setPageState(updatedPageState)

                    setButtonDisable(true)
                    setType(e)
                    setFilterStates({...filterStates, ['typeId']: e})
                  }}
                  isDisabled={pageState.isLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                <AsyncPaginate
                  loadOptions={fetchUserTypes}
                  className='react-select-container my-1 me-3 w-120px'
                  classNamePrefix='react-select'
                  placeholder='Added By ...'
                  onChange={(e: any) => {
                    let updatedPageState: any = {...pageState}
                    updatedPageState['selected'] = 0
                    updatedPageState['currentOffset'] = 0
                    setPageState(updatedPageState)

                    setButtonDisable(true)
                    setUserType(e.value)
                    setUserOffset('')
                    setUserId('')
                    setUserReset(moment())
                    setFilterStates({...filterStates, ['userType']: e.value})
                  }}
                  value={userTypes.find((option: any) => option.value === userType)}
                  isDisabled={pageState.isLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#0c78a4',
                    },
                  })}
                />
                {userType !== '' && (
                  <AsyncPaginate
                    key={userReset}
                    loadOptions={fetchUsers}
                    className='react-select-container my-1 me-3 w-200px'
                    classNamePrefix='react-select'
                    placeholder='All Users'
                    onChange={(e: any) => {
                      setUserId(e)

                      let updatedPageState: any = {...pageState}
                      updatedPageState['selected'] = 0
                      updatedPageState['currentOffset'] = 0
                      setPageState(updatedPageState)

                      setButtonDisable(true)
                      setFilterStates({...filterStates, ['userId']: e})
                    }}
                    value={userId || ''}
                    isDisabled={pageState.isLoading}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#0c78a4',
                      },
                    })}
                  />
                )}
                <ResetButton onClick={handleChangeReset} isDisable={buttonDisable} />
                {/* </div> */}
              </form>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='row text-center'>
              <div className='col-6 border-top border-bottom p-4'>
                <span className='fw-bold me-1'>{totalRegistered}</span>
                {/* <OverlayTrigger
                  delay={{hide: 450, show: 300}}
                  overlay={(props) => (
                    <Tooltip {...props} className='tooltip-dark'>
                      Total Registered Customers (considering the selected filters)
                    </Tooltip>
                  )}
                  placement='top'
                >
                  <span className='cursor-pointer me-3'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen045.svg'
                      className='ic text-primary me-0'
                    />
                  </span>
                </OverlayTrigger> */}
                <div>Total Registered Customers</div>
              </div>

              <div className='col-6 border-top border-bottom p-4'>
                <span className='fw-bold me-1'>{totalVerifiedRegistered}</span>
                <OverlayTrigger
                  delay={{hide: 450, show: 300}}
                  overlay={(props) => (
                    <Tooltip {...props} className='tooltip-dark'>
                      Total Registered Customers, with Verified Mobile Numbers (considering the
                      selected filters)
                    </Tooltip>
                  )}
                  placement='top'
                >
                  <span className='cursor-pointer me-3'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen045.svg'
                      className='ic text-primary me-0'
                    />
                  </span>
                </OverlayTrigger>
                <div>Total Customers with verified mobile number</div>
              </div>

              <div className='col-12'>
                {pageState.list.length === 0 ? (
                  <NoRecords />
                ) : (
                  <TableList
                    orderKey={orderKey}
                    list={pageState.list}
                    handleOrdering={handleOrdering}
                    goToOverview={goToOverview}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <CustomPaginate
          data={pageState.list}
          selected={pageState.selected}
          pageState={pageState}
          setPageState={setPageState}
          totalRecords={pageState.count}
          limit={pageState.limit}
          saveFilterName={filters.wm_customer_report}
          filterStates={filterStates}
          isDisabled={pageState.isLoading}
        />
      </div>
    </>
  )
}

export default CustomerReport

const TableList = (props: any) => {
  return (
    <div className='table-responsive mt-3'>
      <table className='table table-row-bordered table-hover table-row-gray-100 align-middle gs-0 gy-2'>
        <thead>
          <tr className='text-muted fw-500'>
            <th className='min-w-60px'>
              ID
              <span onClick={() => props.handleOrdering('Id')}>
                {props.orderKey.Id ? <OrderingArrowUp /> : <OrderingArrowDown />}
              </span>
            </th>
            <th className='min-w-150px'>
              Name
              <span onClick={() => props.handleOrdering('Name')}>
                {props.orderKey.Name ? <OrderingArrowUp /> : <OrderingArrowDown />}
              </span>
            </th>
            <th className='min-w-140px'>
              Category
              <span onClick={() => props.handleOrdering('Category')}>
                {props.orderKey.Category ? <OrderingArrowUp /> : <OrderingArrowDown />}
              </span>
            </th>
            <th className='min-w-120px'>
              Type
              <span onClick={() => props.handleOrdering('Type')}>
                {props.orderKey.Type ? <OrderingArrowUp /> : <OrderingArrowDown />}
              </span>
            </th>
            <th className='min-w-140px'>
              Warehouse Name
              <span onClick={() => props.handleOrdering('Warehouse')}>
                {props.orderKey.Warehouse ? <OrderingArrowUp /> : <OrderingArrowDown />}
              </span>
            </th>
            <th className='min-w-140px'>
              Credit Range
              <span onClick={() => props.handleOrdering('CreditRange')}>
                {props.orderKey.CreditRange ? <OrderingArrowUp /> : <OrderingArrowDown />}
              </span>
            </th>
            <th className='min-w-120px'>Credit Issue</th>
            <th className='min-w-120px'>Added By</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.list.length !== 0 &&
            props.list.map((item: any) => (
              <tr>
                <td>#{item.id}</td>
                <td>
                  <div className='d-flex align-items-center'>
                    <span
                      className='rounded-circle pro-icon me-2'
                      data-bs-toggle='tooltip'
                      title='Mark Larson'
                    >
                      {item.logo_thumbnail === null ? (
                        <img
                          src={toAbsoluteUrl('/media/avatars/300-5.jpg')}
                          alt=''
                          className='rounded-circle pro-icon'
                        />
                      ) : (
                        <img src={item.logo_thumbnail} alt='' className='rounded-circle pro-icon' />
                      )}
                    </span>
                    <div className='media-body'>
                      <p className='m-0 xs-font'>{item.business_name}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div className='text-muted font-13 mb-1 font-medium text-capitalize'>
                      {item.nature_of_business}
                    </div>
                    {item.category_name}
                  </div>
                </td>
                <td className='text-capitalize'>{item.type}</td>
                <td>{item.warehouse_name !== '' ? item.warehouse_name : '-'}</td>
                <td>{checkEmpty(item.credit_range, '-')}</td>
                <td>-</td>
                <td>{displayAddedBy(item.reg_by)}</td>
                <td onClick={() => props.goToOverview(item.id)}>
                  <span className='btn py-0 ps-1 pe-0 btn-hover-color-primary btn-sm'>
                    <KTSVG path='/media/icons/duotune/arrows/arr007.svg' />
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}
